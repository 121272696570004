/* eslint-disable no-unused-vars */
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import * as React from "react";
import {
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  FilterLiveSearch,
  FunctionField,
  List,
  Pagination,
  ShowButton,
  TextField,
  TopToolbar,
  useDataProvider,
  useRefresh,
} from "react-admin";
import { ColoredChipField } from "../../../helpers/colorChip";
import { StatusFilter, TypeFilter } from "../../filters/transferFilter";
import AuditButton from "../audit/audit.button";
import ProcessButtons from "./ProcessButtons";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useResizer from "../../../utils/useResizer";
import roundOff from "../../../utils/roundOff";

const ListActions = () => (
  <Box className="w-full flex justify-end md:block md:w-full">
    <TopToolbar className="md:flex gap-x-2 absolute items-center w-max right-0 min-h-0 md:min-h-auto flex md:flex-row">
      <CreateButton className="min-w-max  p-0" label="" />
      <ExportButton
        className="self-end min-w-max p-0 hidden md:block"
        label=""
      />
    </TopToolbar>{" "}
  </Box>
);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "12px",
    },
    "& .MuiToolbar-root": {
      flexDirection: "column-reverse",
      width: "100%",
    },
    "& .MuiPaper-root": {
      width: "350px",
      overflow: "auto",
    },
  },
}));

const FilterSidebar = (props) => {
  const classes = useStyles();
  const isSmall = useMediaQuery("(max-width:768px)");
  return (
    <div>
      {" "}
      <div className=" min-w-full  md:min-w-max block">
        <div className="flex m md:w-max md:block items-center flex-wrap">
          <FilterLiveSearch
            className={`w-[45%] md:w-auto ${isSmall && classes.root}`}
            source="_id"
            label="Search by ID"
          />{" "}
          &nbsp;&nbsp;&nbsp;
          <FilterLiveSearch
            className={`w-[45%] md:w-auto ${isSmall && classes.root}`}
            source="currencyShortName"
            label="Currency"
          />{" "}
          &nbsp;&nbsp;&nbsp;
          <FilterLiveSearch
            className={`w-[45%] md:w-auto ${isSmall && classes.root}`}
            source="network"
            label="Network"
          />{" "}
          <input
            type="text"
            className=" mr-3 ml-2 pl-2 py-2 mt-3  border border-black"
            placeholder="Search email"
            value={props.searchEmail}
            onChange={(e) => {
              props.setSearchEmail(e.target.value);
            }}
          />
          <br />
        </div>
        <StatusFilter source="status" />
        <div className="md:flex items-center justify-between w-full">
          <TypeFilter />
          <Button
            className="mb-5 mt-2"
            id="clearButton"
            variant="outlined"
            onClick={() => props.setFilters({})}
          >
            Clear fields
          </Button>
        </div>
      </div>
    </div>
  );
};

export const WithdrawList = (props) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { width, height } = useResizer();
  const useStyles = makeStyles((theme) => {
    return {
      root: {
        "& .MuiFormLabel-root": {
          fontSize: "12px",
        },
        "& .MuiToolbar-root": {
          flexDirection: "row",
          [theme.breakpoints.down("768")]: {
            flexDirection: "column-reverse",
          },
          width: "100%",
        },
        "& .MuiPaper-root": {
          width: "350px",
          overflow: "auto",
          height: `${height - 320}px`,
          [theme.breakpoints.down("768")]: {
            height: "auto",
          },
          position: "static",
        },
        "& .MuiTablePagination-root": {
          position: "absolute",
          zIndex: 10,
          bottom: 0,
          [theme.breakpoints.down("768")]: {
            bottom: "-50px",
          },
          right: 0,
          left: 0,
          display: "flex",
        },
      },
      head: {
        "& .MuiTableCell-head": {
          fontWeight: 700,
        },
      },
    };
  });
  const classes = useStyles();
  const isSmall = useMediaQuery("(max-width:768px)");
  const [searchEmail, setSearchEmail] = React.useState("");

  return (
    <List
      className={`${classes.root} ${classes.head} overflow-x-hidden`}
      {...props}
      actions={<ListActions />}
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[100, 150, 200, 250]} />}
      sort={{ field: "transferId", order: "DESC" }}
      filters={
        <FilterSidebar
          searchEmail={searchEmail}
          setSearchEmail={setSearchEmail}
        />
      }
      bulkActionButtons={false}
      filter={{ "user.email": searchEmail }}
    >
      <Datagrid rowClick={null}>
        <DateField source="createdAt" showTime />
        <FunctionField
          onClick={(e) => {
            e.stopPropagation();
          }}
          label="Email"
          render={(record) => {
            return (
              <a
                className="underline"
                style={{ color: "#000" }}
                href={"/#/users/" + record?.user?.id + "/show"}
              >
                {" "}
                {record?.user?.email}{" "}
              </a>
            );
          }}
        />
        <ColoredChipField source="transferStatus" />
        <TextField source="currencyShortName" label="Currency" />
        <TextField source="amount" />
        <TextField source="fee" />
        <FunctionField
          label="toSend"
          render={(record) => {
            let amountDecimal = record?.amount?.toString()?.split(".")[1]?.length;
            let feeDecimal = record?.fee?.toString()?.split(".")[1]?.length;
            let decimal=amountDecimal||feeDecimal
            return roundOff(record.amount - record.fee, decimal ? decimal : 0);
          }}
        />
        <TextField source="network" label="Network" />
        <TextField source="address" />
        <TextField source="addressTag" label="Tag" />
        <FunctionField
          label="Actions"
          render={(record) => {
            return record.transferStatus === "AWAITING_APPROVAL" ||
              record.transferStatus === "EMAIL_SENT" ? (
              <>
                <ProcessButtons id={record.id} record={record} />
                <AuditButton
                  userId={record.user.id}
                  coin={record.currencyShortName}
                />
              </>
            ) : null;
          }}
        />
        <ShowButton label="" />
        <TextField source="transferId" />
      </Datagrid>
    </List>
  );
};
