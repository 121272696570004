import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { COMMUNITY_URL } from "../../../config/env";
import Modal from "@material-ui/core/Modal";
import { useNotify } from "react-admin";
import UpdateCommunityListModal from "../../../components/Modal/UpdateCommunityListModal";
import { RowingSharp } from "@material-ui/icons";
export default function CommunityList() {
  const [page, setPage] = useState(1);
  const [communityList, setCommunityList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [updateData, setUpdateData] = useState();
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(false);
  const notify = useNotify();

  function getList() {
    let token = localStorage.getItem("token");
    fetch(`${COMMUNITY_URL}/getListCards?page=${page}&limit=${rowsPerPage}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => setCommunityList(res));
  }
  useEffect(() => {
    getList(page, rowsPerPage);
  }, [page, rowsPerPage]);
  const rowArray = [10, 20, 30];

  function deleteList(id) {
    let token = localStorage.getItem("token");
    setIsDeleteDisabled(true);

    fetch(`${COMMUNITY_URL}/deleteListCard?cardId=${id}`, {
      method: "DELETE",
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        getList(page, rowsPerPage);
        setIsDeleteDisabled(false);
        setOpen(false);
        notify(res.message, { type: "success" });
      })
      .catch((err) => {
        getList(page, rowsPerPage);
        setIsDeleteDisabled(false);
        setOpen(false);
        notify(err.message, { type: "error" });
      });
  }
  return (
    <>
      <TableContainer className="mt-10" component={Paper}>
        <div className="h-[calc(100vh-170px)]  block">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="sticky top-0 bg-white "> Sl No</TableCell>
                <TableCell className="sticky top-0 bg-white"> Id</TableCell>
                <TableCell className="sticky top-0 bg-white"> title</TableCell>
                <TableCell className="sticky top-0 bg-white">
                  {" "}
                  Category
                </TableCell>
                <TableCell className="sticky top-0 bg-white"> Items</TableCell>
                <TableCell className="sticky top-0 bg-white"> action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {communityList?.data?.length ? (
                communityList?.data?.map((item, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.card_title}</TableCell>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>
                        <a href={`/#/communityitem/?id=${item.id}`}>
                          <button>Show Items</button>
                        </a>
                      </TableCell>
                      <TableCell className="flex items-center gap-x-4">
                        <button
                          onClick={() => {
                            setDeleteId(item.id);
                            setOpen(true);
                          }}
                          className="text-rose-700 "
                        >
                          Delete
                        </button>
                        <button
                          onClick={() => {
                            // setDeleteId(item.id);
                            setUpdateData(item);
                            setOpenUpdate(true);
                          }}
                          className="text-[#0047AB] "
                        >
                          Update
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
        className="flex-col w-full justify-end md:flex-row  md:gap-6"
      >
        <Pagination
          style={{ paddingTop: "5px", paddingBottom: "5px" }}
          count={Math.ceil(communityList?.total / rowsPerPage)}
          page={page}
          onChange={(e, val) => {
            setPage(val);
          }}
        />
        <p>Rows per page</p>
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            gap: 4,
          }}
        >
          {rowArray.map((item, i) => {
            return (
              <div
                onClick={() => {
                  setRowsPerPage(item);

                  setPage(1);
                }}
                style={{
                  background: item == rowsPerPage ? "rgba(0, 0, 0, 0.08)" : "",
                  width: "max-content",
                  paddingRight: "5px",
                  paddingLeft: "5px",
                  borderRadius: "3px",
                }}
                key={i}
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          onClick={() => {
            setOpen(false);
          }}
          className="flex justify-center items-center h-screen"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="bg-[#FDFDFD] flex justify-center flex-col items-center w-[500px] h-[150px] "
          >
            <p className="font-2xl font-bold">
              Are you sure you want delete this community List?
            </p>
            <div className="w-full flex gap-x-3 mt-5 justify-center px-3">
              <button
                disabled={isDeleteDisabled}
                onClick={() => {
                  deleteList(deleteId);
                }}
                className={`${
                  isDeleteDisabled ? "opacity-60" : "opacity-100"
                } w-[30%] text-white py-2 rounded-md bg-[#3b8132] `}
              >
                {isDeleteDisabled ? "Loading.." : "Yes"}
              </button>
              <button
                onClick={() => {
                  setOpen(false);
                }}
                className="w-[30%] text-white py-2 rounded-md bg-rose-700 "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <UpdateCommunityListModal
        open={openUpdate}
        updateData={updateData}
        setOpen={setOpenUpdate}
        getList={getList}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </>
  );
}
