/* eslint-disable no-unused-vars */
import * as React from "react";
import { Edit, NumberInput, SimpleForm, TextInput } from "react-admin";

const ExternalOffsetEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <NumberInput source="offset" />
        </SimpleForm>
    </Edit>
);

export default ExternalOffsetEdit;
