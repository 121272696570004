/* eslint-disable no-unused-vars */
import Button from "@material-ui/core/Button";
import * as React from "react";
import { ArrayField, Datagrid, DateField, FunctionField, ReferenceField, RichTextField, Show, SimpleShowLayout, TextField, useDataProvider, useRefresh } from "react-admin";
const redirect = (basePath, id, data) => `/tier/taskReviews`;

const TaskReportShow = (props) => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const submit = (record, status) => {
        dataProvider
            .update("v1/admin/tier/taskReviews", { id: status, data: record })
            .then(() => {
                window.location.href = '/#/tier/taskReviews';
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return <Show {...props}>
        <SimpleShowLayout>
            <SimpleShowLayout>
                <TextField source="id" />
                <ReferenceField source="userId" reference="users"><TextField source="email" /></ReferenceField>
                <TextField source="taskType" />

                <TextField source="status" />

                <DateField source="createdAt" showTime />
                <DateField source="updatedAt" showTime />

                <ReferenceField source="taskId" reference="tier/tasks" label="Task Details" link="show"><RichTextField source="task.taskDetails" label="details" /></ReferenceField>

                <ReferenceField source="taskId" reference="tier/tasks" label="Task Terms" link="show"><RichTextField source="task.taskTerms" label="details" /></ReferenceField>
                <ReferenceField source="taskId" reference="tier/tasks" label="Diamonds" link="show"><RichTextField source="diamondReward" label="details" /></ReferenceField>

                <ReferenceField source="taskId" reference="tier/tasks" label="Zelta" link=" show"><RichTextField source="zeltaReward" label="details" /></ReferenceField>

                <hr />
                <h3>User Inputs</h3>


                <ArrayField source="userInputs">
                    <Datagrid>
                        <TextField source="fieldName" />
                        <TextField source="fieldType" />
                        <FunctionField label="Value" render={(record) => { return record.fieldType == "FILE" ? <img src={record.value} /> : record.value }} />
                    </Datagrid>
                </ArrayField>
                {/* <ReferenceField source="taskId" reference="tier/tasks" label="Task Name" link="show"><TextField source="taskName" style={{ fontSize: 16, fontWeight: "bold" }} /></ReferenceField>

                <ReferenceField source="taskId" reference="tier/tasks" label="" link=""><TextField source="task.adf1Name" style={{ fontSize: 12, fontWeight: "bold", position: "absolute" }} /></ReferenceField>
                <TextField source="userInputs.adf1" label="" />
                <ReferenceField source="taskId" reference="tier/tasks" label="" link=""><TextField source="task.adf2Name" style={{ fontSize: 12, fontWeight: "bold", position: "absolute" }} /></ReferenceField>
                <TextField source="userInputs.adf2" label="" />
                <ReferenceField source="taskId" reference="tier/tasks" label="" link=""><TextField source="task.adf3Name" style={{ fontSize: 12, fontWeight: "bold", position: "absolute" }} /></ReferenceField>
                <TextField source="userInputs.adf3" label="" /> */}

                <TextField source="note" />
                <hr />

                <FunctionField
                    label="Actions"
                    render={(record) => {
                        return record.status === "AWAIT_ADMIN_ACTION" ? (
                            <>
                                <Button
                                    style={{
                                        backgroundColor: "#25745d",
                                        color: "white",
                                        marginRight: "10px",
                                    }}
                                    variant="contained"
                                    onClick={() => {
                                        submit(record, "APPROVE");
                                    }}
                                >
                                    Approve
                                </Button>

                                <br />
                                <input placeholder="Rejection note" onChange={(e) => record.note = e.target.value} />
                                <br />
                                <Button
                                    style={{
                                        backgroundColor: "#b20000",
                                        color: "white",
                                        marginRight: "10px",
                                    }}
                                    variant="contained"
                                    onClick={() => {
                                        submit(record, "REJECT");
                                    }}
                                >
                                    Reject
                                </Button>

                            </>
                        ) : null;
                    }}
                />
            </SimpleShowLayout>
        </SimpleShowLayout>
    </Show >
};

export default TaskReportShow;
