/* eslint-disable no-unused-vars */
import {
    ArrayInput,
    BooleanInput,
    Edit,
    NumberInput, SelectInput, SimpleForm, SimpleFormIterator,
    TextInput
} from "react-admin";

export const CurrencyEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <BooleanInput source="active" />
            <TextInput source="currencyName" />
            <TextInput source="currencyShortName" />
            <NumberInput source="baseScale" />
            <TextInput source="tradeStatus" />

            <TextInput source="contract" />
            <TextInput source="activeIconUrl" />
            <TextInput source="inactiveIconUrl" />
            <NumberInput source="marketCap" />

            <ArrayInput source="tags">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="depositOptions">
                <SimpleFormIterator>
                    <TextInput source="network" label="Network" />
                    <TextInput source="tokenType" label="Token Type" />
                    <TextInput source="coinId" label="Coin Id" />
                    <NumberInput source="minDeposit" label="Min Deposit" />
                    <NumberInput source="minWithdraw" label="Min Withdraw" />
                    <NumberInput source="withdrawFee" label="Withdraw Fee" />
                    <SelectInput
                        source="withdrawStatus"
                        variant="outlined"
                        choices={[
                            { id: "ACTIVE", name: "ACTIVE" },
                            { id: "INACTIVE", name: "INACTIVE" },
                            { id: "PAUSED", name: "PAUSED" },
                        ]}
                    />
                    <SelectInput
                        source="depositStatus"
                        variant="outlined"
                        choices={[
                            { id: "ACTIVE", name: "ACTIVE" },
                            { id: "INACTIVE", name: "INACTIVE" },
                            { id: "PAUSED", name: "PAUSED" },
                        ]}
                    />

                    <BooleanInput source="tagOrMemo" />
                    <TextInput source="memoType" />
                    <NumberInput source="confirmations" label="Required Confirmation" />
                </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput source="inInnovationZone" />
        </SimpleForm>
    </Edit>
);
