import { Box } from "@material-ui/core";
import * as React from "react";
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
} from "react-admin";
import { ColoredChipField } from "../../../helpers/colorChip";
import { ExportButton, FilterLiveSearch, TopToolbar} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useResizer from "../../../utils/useResizer"


const ListActions = () => (
  <Box className="w-full">
    <TopToolbar className="hidden w-full items-end justify-end md:flex">
      <ExportButton />
    </TopToolbar>{" "}
  </Box>
);

const FilterSidebar = (props) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFormLabel-root": {
        fontSize: "12px",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column-reverse",
        width: "100%",
      },
      "& .MuiPaper-root": {
        width: "350px",
        overflow: "auto",
      },
    },
  }));
  const classes = useStyles();
  const isSmall = useMediaQuery("(max-width:768px)");
  return (
    <>
      <Box width="100%">
        <FilterLiveSearch
          className={`w-[45%] md:w-auto ${isSmall && classes.root}`}
          source="userEmail"
          label="Search email"
        />
        <span style={{ marginLeft: "20px" }}>
          <FilterLiveSearch
            className={`w-[45%] md:w-auto ${isSmall && classes.root}`}
            source="symbol"
            label="Search symbol"
          />{" "}
        </span>
      </Box>
    </>
  );
};
export const TradeList = (props) => {
  const {width,height}=useResizer()
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFormLabel-root": {
        fontSize: "12px",
      },
      "& .MuiToolbar-root": {
        flexDirection: "column-reverse",
        width: "100%",
      },
      "& .MuiPaper-root": {
        width: "350px",
        overflow: "auto",
        height:`${height-320}px`,
        [theme.breakpoints.down('768')]:{
          height:'auto'
        },
        position:'static'
      },
      "& .MuiTablePagination-root":{
        position:"absolute",
        zIndex:10,
        bottom:0,
        [theme.breakpoints.down('768')]:{
         bottom:'-50px'
        },
        right:0,
        left:0,
        display:'flex'
      }
    },
    head:{
      "& .MuiTableCell-head":{
        fontWeight:700
      }}
  }));
  const classes = useStyles();
  return (
    <>
      <h2 className="px-4">Trade Section</h2>
      <List
        bulkActionButtons={false}
        className={`${classes.root} ${classes.head} overflow-x-hidden`}
        {...props}
        sort={{ field: "updatedAt", order: "DESC" }}
        filters={<FilterSidebar />}
        actions={<ListActions />}
      >
        <Datagrid rowClick="edit">
          <DateField source="timestamp" showTime />
          <TextField source="makerEmail" />
          <TextField source="takerEmail" />
          <TextField source="symbol" />
          <FunctionField source="side" render={(record) => { return <ColoredChipField source="side" record={{ side: record.symbol.startsWith(record.feeCurrencyName) ? "BID" : "ASK" }} /> }} />
          <TextField source="price" />
          <TextField source="qty" />
          <TextField source="tradeId" />
          <TextField source="orderId" />
          <FunctionField
            source="fee"
            render={(record) => {
              return (
                record.fee.toFixed(8).toString() + " " + record.feeCurrencyName
              );
            }}
          />
        </Datagrid>
      </List>
    </>
  );
};
