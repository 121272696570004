/* eslint-disable no-unused-vars */
import { Datagrid, List, NumberField, TextField,FilterLiveSearch } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useResizer from "../../../utils/useResizer"
import useMediaQuery from "@mui/material/useMediaQuery";
import { TradeStatusFilter } from "../../filters/TradeStatusFilter";
import { Button } from "@material-ui/core";

const FilterSidebar = (props) => {

  const isSmall = useMediaQuery("(max-width:768px)");
  const useStyles = makeStyles((theme) => {
    return (
    {
    root: {
      "& .MuiFormLabel-root": {
        fontSize: "12px",
      },
      "& .MuiToolbar-root": {
        width: "100%",
      },
      "& .MuiPaper-root": {
        width: "350px",
        overflow: "auto",
      },
     
    },
   
  })});
  const classes = useStyles();
  return (
      <div>
          {" "}
          <div className=" min-w-full  md:min-w-max block">
              <div className="flex m md:w-max md:block items-center flex-wrap">
                  <FilterLiveSearch
                      className={`w-[45%] md:w-auto ${isSmall && classes.root}`}
                      source="symbol"
                      label="Symbol"
                  />{" "}

                  <br />
              </div>
              <TradeStatusFilter source="tradeStatus" />
              <Button
                  className="mb-5 mt-2"
                  id="clearButton"
                  variant="outlined"
                  onClick={() => props.setFilters({})}
              >
                  Clear fields
              </Button>
          </div>
      </div>
  );
};

export const SymbolList = (props) => {
    const {width,height}=useResizer()
  const useStyles = makeStyles((theme) => {
    return (
    {
    root: {
      "& .MuiFormLabel-root": {
        fontSize: "12px",
      },
      "& .MuiToolbar-root": {
        // flexDirection: "column-reverse",
        width: "100%",
      },
      "& .MuiPaper-root": {
        width: "350px",
        overflow: "auto",
        height:`${height-300}px`,
        [theme.breakpoints.down('768')]:{
          height:'auto'
        },
        position:'static'
      },
      "& .MuiTablePagination-root":{
        position:"absolute",
        zIndex:10,
        bottom:0,
        [theme.breakpoints.down('768')]:{
         bottom:'-50px'
        },
        right:0,
        left:0,
        display:'flex'
      }
    },
    head:{
      "& .MuiTableCell-head":{
        fontWeight:700
      }
    }
  })});
  const classes = useStyles();
   return (
    <List className={`${classes.root} ${classes.head} `} filters={<FilterSidebar />} bulkActionButtons={false} actions={null} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="symbolId" label="Id" />
            <TextField source="symbol" />
            <TextField source="type" />
            <NumberField source="baseCurrencyName" />
            <NumberField source="quoteCurrencyName" />
            <NumberField source="decimalPrice" />
            <NumberField source="decimalQty" />
            <NumberField source="decimalTotal" />
            <TextField source="tradeStatus" />
        </Datagrid>
    </List>
)}
