/* eslint-disable no-unused-vars */
import * as React from "react";
import { BooleanField, NumberField, Show, SimpleShowLayout, TextField } from "react-admin";

const TierConfigShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="tierName" />
            <TextField source="tierDescription" />
            <NumberField source="level" />
            <BooleanField source="kycComplete" />
            <NumberField source="makerFee" />
            <NumberField source="takerFee" />
            <NumberField source="referralCommissionPercent" />
            <NumberField source="vaultReferralCommissionPercent" />
            <NumberField source="diamondsRequired" />
            <NumberField source="zltRequired" />
        </SimpleShowLayout>
    </Show>
);

export default TierConfigShow;
