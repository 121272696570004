/* eslint-disable no-unused-vars */
import { ArrayInput, Edit, NumberInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput } from "react-admin";

export const SymbolEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <SelectInput
                source="tradeStatus"
                variant="outlined"
                choices={[
                    { id: "ACTIVE", name: "ACTIVE" },
                    { id: "INACTIVE", name: "INACTIVE" },
                    { id: "PAUSED", name: "PAUSED" },
                ]}
            />

            <SelectInput
                source="type"
                choices={[
                    { id: "CURRENCY_EXCHANGE_PAIR", name: "Exchange" },
                    { id: "FUTURES_CONTRACT", name: "Futures" },
                    { id: "OPTION", name: "Options" },
                ]}
                disabled
            />

            <TextInput source="symbol" disabled />
            <TextInput source="symbolId" disabled />
            <TextInput source="baseCurrencyName" disabled />
            <TextInput source="quoteCurrencyName" disabled />

            <NumberInput source="decimalQty"  />
            <NumberInput source="decimalPrice"  />
            <NumberInput source="decimalTotal"  />
            <NumberInput source="rank" disabled />
            <NumberInput source="rank" disabled />
            <NumberInput source="makerFee" disabled />
            <NumberInput source="takerFee" disabled />
            <NumberInput source="takerFeeDecimal" disabled />
            <NumberInput source="makerFeeDeciaml" disabled />

            <ArrayInput source="category">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>

            <ArrayInput source="supportedLiquidityProviders">
                <SimpleFormIterator>
                <SelectInput
                    choices={[
                        { id: "ZELTA", name: "ZELTA" },
                        { id: "BINANCE", name: "BINANCE" },
                        { id: "GATEIO", name: "GATEIO" },
                    ]}
            />
                </SimpleFormIterator>
            </ArrayInput>

            <SelectInput
                source="executionType"
                choices={[
                    { id: "SPOT", name: "SPOT" },
                    { id: "MARGIN", name: "MARGIN" }
                ]}
            />
            
        </SimpleForm>

    </Edit>
);
