import { EditGuesser } from "react-admin";
import TaskReportCreate from "./create";
import TaskReportList from "./list";
import TaskReportShow from "./show";
const resource = {
    list: TaskReportList,
    edit: EditGuesser,
    show: TaskReportShow,
    create: TaskReportCreate,
};

export default resource;
