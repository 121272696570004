/* eslint-disable no-unused-vars */
import * as React from "react";
import {
  ArrayInput,
  BooleanInput,
  DateTimeInput,
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

const RewardConfigEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" />

      <SelectInput
        source="bonusFor"
        variant="outlined"
        choices={[
          { id: "SIGNUP_AND_KYC", name: "SIGNUP_AND_KYC" },
          { id: "REFER_AND_EARN", name: "REFER_AND_EARN" },
          { id: "TRADE", name: "TRADE" },
        ]}
      />

      <TextInput source="bonusCode" />
      <TextInput source="recordType" />
      <ArrayInput source="directBonuses">
        <SimpleFormIterator>
          <TextInput source="baseCoinId" />
          <TextInput source="quoteCoinId" />
          <NumberInput source="value" />
          <NumberInput source="redemptionTradeValue" />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="referrerBonuses">
        <SimpleFormIterator>
          <TextInput source="baseCoinId" />
          <TextInput source="quoteCoinId" />
          <NumberInput source="value" />
          <NumberInput source="redemptionTradeValue" />
        </SimpleFormIterator>
      </ArrayInput>

      <TextInput source="tradeCoinId" />
      <TextInput source="influencerUserId" />
      <NumberInput source="redemptionCountLimit" />

      <BooleanInput source="active" />

      <DateTimeInput
        source="startDate"
        parse={(v) => {
          return v;
        }}
        format={(v) => {
          return v;
        }}
      />
      <DateTimeInput
        source="endDate"
        parse={(v) => {
          return v;
        }}
        format={(v) => {
          return v;
        }}
      />
    </SimpleForm>
  </Edit>
);

export default RewardConfigEdit;
