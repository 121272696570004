import VaultConfigCreate from "./vaultConfigCreate";
import VaultConfigEdit from "./vaultConfigEdit";
import VaultConfigList from "./vaultConfigList";
import VaultConfigShow from "./vaultConfigShow";

const resource = {
  list: VaultConfigList,
  edit: VaultConfigEdit,
  show: VaultConfigShow,
  create: VaultConfigCreate,
};

export default resource;
