/* eslint-disable no-unused-vars */
import * as React from "react";
import { useState } from "react";
import {
    BooleanField,
    Datagrid,
    Filter,
    List,
    NumberField,
    Pagination,
    SearchInput,
    TextField,
    useDataProvider,
    useRefresh
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useResizer from "../../../utils/useResizer"

const TierConfigFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="searchId" alwaysOn />
    </Filter>
);
const PostPagination = (props) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const TierConfigList = (props) => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const refresh = useRefresh();

    const {width,height}=useResizer()
  const useStyles = makeStyles((theme) => {
    return (
    {
    root: {
      "& .MuiFormLabel-root": {
        fontSize: "12px",
      },
      "& .MuiToolbar-root": {
        // flexDirection: "column-reverse",
        width: "100%",
      },
      "& .MuiPaper-root": {
        width: "350px",
        overflow: "auto",
        height:`${height-165}px`,
        [theme.breakpoints.down('768')]:{
          height:'auto'
        },
        position:'static'
      },
      "& .MuiTablePagination-root":{
        position:"absolute",
        zIndex:10,
        bottom:0,
        [theme.breakpoints.down('768')]:{
         bottom:'-50px'
        },
        right:0,
        left:0,
        display:'flex'
      }
    },
    head:{
      "& .MuiTableCell-head":{
        fontWeight:700
      }
    }
  })});
  const classes = useStyles();

    return (
        <div>
            <List
             className={`${classes.root} ${classes.head} `}
                bulkActionButtons={false}
                {...props}
                filters={<TierConfigFilter />}
                pagination={<PostPagination />}
            >
                <Datagrid rowClick="edit">
                    <TextField source="id" />
                    <TextField source="tierName" />
                    <TextField source="tierDescription" />
                    <NumberField source="level" />
                    <BooleanField source="kycComplete" />
                    <NumberField source="makerFee" />
                    <NumberField source="takerFee" />
                    <NumberField source="referralCommissionPercent" />
                    <NumberField source="vaultReferralCommissionPercent" />
                    <NumberField source="diamondsRequired" />
                    <NumberField source="zltRequired" />
                </Datagrid>
            </List>
        </div>
    );
};

export default TierConfigList;
