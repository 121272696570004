/* eslint-disable no-unused-vars */
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@mui/material/Pagination";
import * as React from "react";
import { useDataProvider } from "react-admin";

import {
    Create,
    DateTimeInput,
    NumberInput,
    SelectInput,
    SimpleForm,
    TextInput,
    useRefresh,
} from "react-admin";
import HistoryMenu from "../../../../components/Menu/history";
import { localeDate } from "../../../../helpers/localeDate";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}));
const redirect = (basePath, id, data) => `/users/${data.userId}`;

const CompensationCreate = (props) => {
    const [type, setType] = React.useState();

    return (
        <Create
            title="Create a Compensation Record"
            {...props}
            resource="orderbook/compensationOrder"
        >
            <SimpleForm redirect={redirect}>
                <TextInput source="symbol" variant="outlined" placeholder="Symbol" />
                <NumberInput source="price" variant="outlined" placeholder="Price" />
                <NumberInput
                    source="quantity"
                    variant="outlined"
                    placeholder="Quantity"
                />
                <SelectInput
                    source="status"
                    variant="outlined"
                    choices={[
                        { id: "FILLED", name: "FILLED" },
                        { id: "FAILED", name: "FAILED" },
                    ]}
                    defaultValue="FILLED"
                />

                <SelectInput
                    source="action"
                    variant="outlined"
                    choices={[
                        { id: "ASK", name: "ASK" },
                        { id: "BID", name: "BID" },
                    ]}
                    defaultValue="BID"
                />

                <SelectInput
                    source="orderType"
                    variant="outlined"
                    choices={[
                        { id: "GTC", name: "LIMIT" },
                        { id: "IOC", name: "MARKET" },
                    ]}
                    defaultValue="IOC"
                />

                <TextInput
                    source="note"
                    variant="outlined"
                    placeholder="Note"
                    defaultValue=""
                />
                <TextInput
                    source="userId"
                    variant="outlined"
                    placeholder="User Id"
                    defaultValue={props.userId}
                />
                <DateTimeInput
                    source="createdAt"
                    defaultValue={null}
                    parse={(v) => {
                        return v;
                    }}
                    format={(v) => {
                        return v;
                    }}
                />
            </SimpleForm>
        </Create>
    );
};

function CompensationCreateFormDialog(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const refresh = useRefresh();

    const handleClose = () => {
        setOpen(false);
        // refresh();
    };

    //   console.log(props);
    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                disabled={props.flagged}
            >
                Compensate
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title"> Compensate</DialogTitle>
                <DialogContent>
                    <CompensationCreate {...props} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const HistoricOrders = (props) => {
    const dataProvider = useDataProvider();
    const [statusFilterVal, setStatusFilterVal] = React.useState("All");
    const [coinFilterVal, setCoinFilterVal] = React.useState("All");
    const [currencies, setCurrencies] = React.useState();
    const [orders, setOrders] = React.useState();
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const rowArray = [10, 20, 30];
    const statusArray = ["All", "FILLED", "FAILED", "CANCELLED"];

    React.useEffect(() => {
        getHistoricOrders(
            statusFilterVal,
            coinFilterVal,
            selectedPage,
            rowsPerPage
        );
    }, [statusFilterVal, coinFilterVal, selectedPage, rowsPerPage]);

    React.useEffect(() => {
        getCurrencies();
    }, []);

    React.useEffect(() => {
        setSelectedPage(1);
    }, [statusFilterVal, coinFilterVal]);

    const getCurrencies = () => {
        dataProvider
            .getList("v1/admin/orderbook/currency", {
                filter: { userId: props.id },
                pagination: { page: 1, perPage: 500 },
                sort: { field: "currencyShortName", order: "DESC" },
            })
            .then(({ data }) => {
                setCurrencies([
                    { currencyShortName: "All", actionIconUrl: "" },
                    ...data,
                ]);
                // console.log(data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getHistoricOrders = (status, coin, selectedPage, rows) => {
        // console.log(selectedPage, "page");
        let statusPayload = status == "All" ? "FILLED,FAILED,CANCELLED" : status;
        let filterObj = dataProvider
            .getList("v1/admin/orderbook/orders", {
                filter: {
                    status: statusPayload,
                    userId: props.id,
                    symbol: coin == "All" ? "" : `${coin}USDT`,
                },
                pagination: { page: selectedPage, perPage: rows },
                sort: { field: "createdAt", order: "DESC" },
            })
            .then((res) => {
                // console.log(res);
                setOrders(res);
                props.setLoading(false);
            })
            .catch(() => {
                props.setLoading(false);
            });
    };

    return (
        <TableContainer className="overflow-hidden" component={Paper}>
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                {" "}
                <CompensationCreateFormDialog
                    basePath="/"
                    resource="orderbook/compensationOrder"
                    userId={props.id}
                />
                <p style={{ fontSize: "14px" }}>Status</p>
                <HistoryMenu
                    filter={"status"}
                    menuArray={statusArray}
                    setSelected={setStatusFilterVal}
                    filterVal={statusFilterVal}
                />
                <p style={{ fontSize: "14px" }}>Coin</p>
                <HistoryMenu
                    menuArray={currencies}
                    setSelected={setCoinFilterVal}
                    filterVal={coinFilterVal}
                />
                <Button
                    style={{ marginLeft: "10px" }}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        setCoinFilterVal("All");
                        setStatusFilterVal("All");
                    }}
                >
                    Clear Filters
                </Button>
            </div>
            <div className="h-[calc(100vh-220px)] block overflow-auto">
                <Table sx={{ minWidth: 1024 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="sticky top-0 bg-white ">
                                {" "}
                                Order Id
                            </TableCell>
                            <TableCell className="sticky top-0 bg-white "> Symbol</TableCell>
                            <TableCell className="sticky top-0 bg-white ">
                                {" "}
                                Orignal Qty
                            </TableCell>
                            <TableCell className="sticky top-0 bg-white ">
                                {" "}
                                Executed Qty
                            </TableCell>
                            <TableCell className="sticky top-0 bg-white ">
                                {" "}
                                Orignal Price
                            </TableCell>
                            <TableCell className="sticky top-0 bg-white ">
                                {" "}
                                Type or Side
                            </TableCell>
                            <TableCell className="sticky top-0 bg-white "> Status</TableCell>
                            <TableCell className="sticky top-0 bg-white "> Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders
                            ? orders?.data?.slice().map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell> {row.orderId}</TableCell>
                                    <TableCell> {row.symbol}</TableCell>
                                    <TableCell> {row.origQty}</TableCell>
                                    <TableCell> {row.execQty}</TableCell>
                                    <TableCell> {row.origPrice}</TableCell>
                                    <TableCell>
                                        {row.action?.toLowerCase() == "bid" ? "BUY" : "SELL"}
                                    </TableCell>
                                    <TableCell> {row.status}</TableCell>
                                    <TableCell>
                                        {" "}
                                        {row.updatedAt
                                            ? localeDate(row.updatedAt, "dateFormat")
                                            : "--"}
                                    </TableCell>
                                    {/* <TableCell>
                                  
                                </TableCell> */}
                                </TableRow>
                            ))
                            : "Loading"}
                    </TableBody>
                </Table>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                }}
                className="flex-col md:flex-row  md:gap-6"
            >
                <Pagination
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                    count={Math.ceil(orders?.total / rowsPerPage)}
                    page={selectedPage}
                    onChange={(e, val) => {
                        setSelectedPage(val);
                    }}
                />
                <p>Rows per page</p>
                <div
                    style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        gap: 4,
                    }}
                >
                    {rowArray.map((item, i) => {
                        return (
                            <div
                                onClick={() => {
                                    setRowsPerPage(item);

                                    setSelectedPage(1);
                                }}
                                style={{
                                    background: item == rowsPerPage ? "rgba(0, 0, 0, 0.08)" : "",
                                    width: "max-content",
                                    paddingRight: "5px",
                                    paddingLeft: "5px",
                                    borderRadius: "3px",
                                }}
                                key={i}
                            >
                                {item}
                            </div>
                        );
                    })}
                </div>
            </div>
        </TableContainer>
    );
};
