/* eslint-disable no-unused-vars */
import { makeStyles, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createTheme } from "@material-ui/core/styles";
import * as React from "react";
import { localeDate } from "../../../../helpers/localeDate";
import { KycSummary } from "./kyc";

const styles = makeStyles({
  gridRow: {
    justifyContent: "space-between",
    marginTop: 20,
  },
  title: {
    color: "#757575",
  },
  paper: {
    padding: "8px",
  },
  label: {
    letterSpacing: "0.15px",
    paddingBottom: "0px",
    fontWeight: 600,
  },
});

const handleChange = (e) => {
  let val = e.target.value;
};

const theme = createTheme({
  palette: {
    type: "light", // Switching the dark mode on is a single property value change.
  },
});

const UserSummary = (props) => {
  const classes = styles();
  const { user } = props;
  return (
    <div container className={`${classes.paper} px-4`}>
      <Typography gutterBottom={true} variant="h6" className={classes.label}>
        Personal Data
      </Typography>
      <Grid item={true}>
        <Typography style={{ color: "#757575", fontSize: "12px" }}>
          Last activity: {localeDate(user?.lastLogin, "dateFormat")}
        </Typography>
      </Grid>
      <br />
      <Grid container={true} className={classes.gridRow}>
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className={classes.title}>
            Id
          </Typography>
          <Typography
            className="w-full m-0 break-words md:break-normal "
            gutterBottom={true}
          >
            {user?.id}
          </Typography>
        </Grid>
        <Grid item={true} xs={4}>
          <Typography gutterBottom={true} className={classes.title}>
            Created at
          </Typography>
          <Typography gutterBottom={true}>
            {localeDate(user?.createdAt, "dateFormat") || "-"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} className={classes.gridRow}>
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className={classes.title}>
            First name
          </Typography>
          <Typography gutterBottom={true}>
            {user?.firstName ? user.firstName : "-"}
          </Typography>
        </Grid>
        <Grid item={true} xs={4}>
          <Typography gutterBottom={true} className={classes.title}>
            Last name
          </Typography>
          <Typography gutterBottom={true}>
            {user?.lastName ? user.lastName : "-"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container={true} className={classes.gridRow}>
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className={classes.title}>
            Email
          </Typography>
          <Typography
            className="break-words md:break-normal"
            gutterBottom={true}
          >
            {user?.email}
          </Typography>
        </Grid>
        <Grid item={true} xs={4}>
          <Typography gutterBottom={true} className={classes.title}>
            ReferredBy
          </Typography>
          <Typography gutterBottom={true}>
            <a href="#">{user?.referredBy ? user.referredBy : ""}</a>
          </Typography>
        </Grid>
      </Grid>

      <Grid container={true} className={classes.gridRow}>
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className={classes.title}>
            Role
          </Typography>
          <Typography gutterBottom={true}>{user?.role}</Typography>
        </Grid>
        <Grid item={true} xs={4}>
          <Typography gutterBottom={true} className={classes.title}>
            Day of Birth
          </Typography>
          <Typography gutterBottom={true}>
            {user?.dob ? localeDate(user.dob, "date") : "-"}
          </Typography>
        </Grid>
      </Grid>

      <Grid container={true} className={classes.gridRow}>
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className={classes.title}>
            Referaal Code
          </Typography>
          <Typography gutterBottom={true}>{user?.referralCode}</Typography>
        </Grid>
        <Grid item={true} xs={4}>
          <Typography gutterBottom={true} className={classes.title}>
            Uid
          </Typography>
          <Typography gutterBottom={true}>{user?.uid}</Typography>
        </Grid>
      </Grid>

      <Grid container={true} className={classes.gridRow}>
        <Grid item={true} xs={6}>
          <Typography gutterBottom={true} className={classes.title}>
            Last Password Update
          </Typography>
          <Typography gutterBottom={true}>
            {user?.passwordUpdatedOn
              ? localeDate(user?.passwordUpdatedOn, "dateFormat")
              : "-"}
          </Typography>
        </Grid>
        <Grid item={true} xs={4}>
          <Typography gutterBottom={true} className={classes.title}>
            Withdrawal Freeze?
          </Typography>
          <Typography gutterBottom={true}>
            {new Date().getTime() / 1000 - 24 * 3600 <
            user?.passwordUpdatedOn / 1000
              ? "YES - Upto " +
                new Date(
                  user?.passwordUpdatedOn + 24 * 3600 * 1000
                ).toISOString()
              : "NO"}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export const UserShowDetails = (props) =>{
  console.log(props)
  return (
  <Grid container colums={{ xs: 1, sm: 2, md: 2 }}>
    <Grid item>
      <Paper
        className="w-full md:w-[500px]"
        style={{ padding: "2%", margin: "10px" }}
      >
        <UserSummary user={props?.userDetails} />
      </Paper>
    </Grid>
    <Grid item>
      <Paper
        className="w-full md:w-[500px]"
        style={{ padding: "2%", margin: "10px" }}
      >
        <KycSummary user={props?.userDetails} />
      </Paper>
    </Grid>
  </Grid>
  )};
