import { EditGuesser, ShowGuesser } from "react-admin";
import { AggTransferList } from "./list";

const resource = {
    list: AggTransferList,
    show: ShowGuesser,
    edit: EditGuesser
};

export default resource;
