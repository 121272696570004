/* eslint-disable no-unused-vars */
import { green, red, yellow } from "@material-ui/core/colors";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { Fragment } from "react";
import {
    ArrayField,
    BooleanField,
    ChipField,
    Datagrid,
    FunctionField,
    List,
    NumberField,
    Pagination,
    SingleFieldList,
    TextField,
    Filter,
    SearchInput
} from "react-admin";
import {
    DepositButton,
    DisableButton,
    EnableButton,
    TradeButton,
    WithdrawButton
} from "./helpers/BulkActionButtons";
import { makeStyles } from "@material-ui/core/styles";
import useResizer from "../../../utils/useResizer"
const PostBulkActionButtons = (props) => (
    <Fragment>
        <EnableButton {...props} />
        <DisableButton {...props} />
        <TradeButton {...props} />
        <WithdrawButton {...props} />
        <DepositButton {...props} />
    </Fragment>
);

const CurrencyFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="searchByRecord" alwaysOn />
    </Filter>
);
const PostPagination = (props) => (
    <Pagination rowsPerPageOptions={[50, 100, 200]} {...props} />
);

export const CurrencyList = (props) => {
    const {width,height}=useResizer()
    const useStyles = makeStyles((theme) => {
      return (
      {
      root: {
        "& .MuiFormLabel-root": {
          fontSize: "12px",
        },
        "& .MuiToolbar-root": {
          // flexDirection: "column-reverse",
          width: "100%",
        },
        "& .MuiPaper-root": {
          width: "350px",
          overflow: "auto",
          height:`${height-165}px`,
          [theme.breakpoints.down('768')]:{
            height:'auto'
          },
          position:'static'
        },
        "& .MuiTablePagination-root":{
          position:"absolute",
          zIndex:10,
          bottom:0,
          [theme.breakpoints.down('768')]:{
           bottom:'-50px'
          },
          right:0,
          left:0,
          display:'flex'
        }
      },
      head:{
        "& .MuiTableCell-head":{
          fontWeight:700
        }
      }
    })});
    const classes = useStyles();
    return(
    <>
        <List
        className={`${classes.root} ${classes.head}`}
            filters={<CurrencyFilter />}
            {...props}
            pagination={<PostPagination />}
            bulkActionButtons={<PostBulkActionButtons />}
            perPage={100}
            filterDefaultValues={{ searchByRecord: "" }}
        >
            <Datagrid rowClick="show">

                <BooleanField source="active" />
                <TextField source="currencyName" label="Name" />
                <TextField source="currencyShortName" label="Currency" />

                <BooleanField source="hasVault" />

                <FunctionField

                    label="Trade"
                    render={(record) => {
                        return record.tradeStatus === "ACTIVE" ? (
                            <FiberManualRecordIcon style={{ color: green[300] }} />
                        ) : record.tradeStatus === "PAUSED" ? (
                            <FiberManualRecordIcon style={{ color: yellow[800] }} />
                        ) : (
                            <FiberManualRecordIcon style={{ color: red[800] }} />
                        );
                    }}
                />

                <BooleanField source="tagOrMemo" label="Tag" />

                <FunctionField

                    label="Active Icon"
                    render={(record) => {
                        return (
                            <img
                                src={record.activeIconUrl}
                                width={20}
                                alt={record.activeIconUrl}
                            />
                        );
                    }}
                />

                <NumberField source="marketCap" />
                <ArrayField source="depositOptions">
                    <SingleFieldList>
                        <ChipField source="network" />
                    </SingleFieldList>
                </ArrayField>
                <BooleanField source="inInnovationZone" />
            </Datagrid>
        </List>
    </>
)};
