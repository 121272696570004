import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useResizer from "../../../utils/useResizer"

import {
    Datagrid, DateField, List, TextField
} from "react-admin";
export const TotalBalanceList = props => {
    const {width,height}=useResizer()
  const useStyles = makeStyles((theme) => {
    return (
    {
    root: {
      "& .MuiFormLabel-root": {
        fontSize: "12px",
      },
      "& .MuiToolbar-root": {
        // flexDirection: "column-reverse",
        width: "100%",
      },
      "& .MuiPaper-root": {
        width: "350px",
        overflow: "auto",
        height:`${height-165}px`,
        [theme.breakpoints.down('768')]:{
          height:'auto'
        },
        position:'static'
      },
      "& .MuiTablePagination-root":{
        position:"absolute",
        zIndex:10,
        bottom:0,
        [theme.breakpoints.down('768')]:{
         bottom:'-50px'
        },
        right:0,
        left:0,
        display:'flex'
      }
    },
    head:{
      "& .MuiTableCell-head":{
        fontWeight:700
      }
    }
  })});
  const classes = useStyles();
    return(
    < List className={`${classes.root} ${classes.head}`} {...props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={500} >
        <Datagrid rowClick={null} >
            {/* <TextField source="id" />÷ */}
            <TextField source="currencyShortName" />
            <TextField source="totalBalance" />
            <TextField source="totalVaultBalance" />
            <TextField source="totalInterestInOrder" />
            <TextField source="totalInterestMatured" />
            <TextField source="totalInOrderBalance" />
        </Datagrid>
    </List >
)}