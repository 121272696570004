/* eslint-disable no-unused-vars */
import * as React from "react";
import { useState } from "react";
import {
  Datagrid,
  Filter,
  List,
  NumberField,
  Pagination,
  SearchInput,
  TextField,
  useDataProvider,
  useRefresh,
} from "react-admin";
const FeeConfigFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="searchId" alwaysOn />
  </Filter>
);
const PostPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const FeeConfigList = (props) => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const refresh = useRefresh();

  return (
    <div>
      <List
        bulkActionButtons={false}
        {...props}
        filters={<FeeConfigFilter />}
        pagination={<PostPagination />}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="tier" />
          <NumberField source="baseMakerFee" />
          <NumberField source="baseTakerFee" />
          <TextField source="feeTierList" />
        </Datagrid>
      </List>
    </div>
  );
};

export default FeeConfigList;
