import { BooleanInput, Edit, NumberInput, SimpleForm, TextInput } from "react-admin";


export const TransferConfigEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput source="network" disabled />
            <TextInput source="tokenType" disabled />
            <TextInput source="parentCoinId" />
            <TextInput source="coinId" disabled />
            <TextInput source="transferSide" disabled />
            <NumberInput source="minAmount" />
            <NumberInput source="fee" />
            <TextInput source="status" />
            <BooleanInput source="tagOrMemo" />
            <TextInput source="memoType" />
            <TextInput source="contract" />
            <NumberInput source="confirmations" />
        </SimpleForm>
    </Edit>
);