import {
    DateField,
    Show,
    SimpleShowLayout,
    TextField
} from "react-admin";
export const AggOrderShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="externalOrderId" />
            <TextField source="userId" />
            <TextField source="userEmail" />
            <TextField source="symbol" />
            <TextField source="orderRequest" />
            <TextField source="userType" />
            <TextField source="feeTier" />
            <TextField source="baseCurrencyName" />
            <TextField source="quoteCurrencyName" />
            <TextField source="clientOrderId" />
            <TextField source="origPrice" />
            <TextField source="avgPrice" />
            <TextField source="originalQty" />
            <DateField source="executedQty" />
            <TextField source="exchange" />
            <DateField source="fee" />
            <TextField source="status" />
            <TextField source="type" />
            <TextField source="action" />
            <TextField source="stopPrice" />
            <TextField source="stopLimitPrice" />
            <TextField source="icebergQty" />
            <TextField source="averageUsdValue" />
            <DateField source="createdAt" showTime />
            <DateField source="updatedAt" showTime />
            <TextField source="note" />
            <TextField source="internalNote" />
            <TextField source="userTrades" />
        </SimpleShowLayout>
    </Show>
);