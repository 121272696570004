import { Button, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}));

export const PostMarkDetails = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const dataProvider = useDataProvider();

    const [suppression, setSuppressions] = useState([]);
    const [bounces, setBounces] = useState([]);
    const [stats, setStats] = useState({});

    useEffect(() => {

        dataProvider
            .getOne("v1/admin/postmark/deliveryStats", { id: "/", filter: { userId: props.id }, pagination: { page: 1, perPage: 100 }, sort: { field: "loginTime", order: "DESC" } })
            .then(({ data }) => {
                setStats(data);
            })
            .catch(() => {

            });


        dataProvider
            .getOne("v1/admin/postmark/bounces", { id: "/", filter: { userId: props.id }, pagination: { page: 1, perPage: 100 }, sort: { field: "loginTime", order: "DESC" } })
            .then(({ data }) => {
                console.log(data);
                setBounces(data);
            })
            .catch(() => {

            });


        dataProvider
            .getOne("v1/admin/postmark/suppression", { id: "/", filter: { userId: props.id }, pagination: { page: 1, perPage: 100 }, sort: { field: "loginTime", order: "DESC" } })
            .then(({ data }) => {
                setSuppressions(data);
            })
            .catch(() => {

            });


    }, []);

    const removeSuppression = (email) => {
        return dataProvider.delete("v1/admin/postmark/suppression", { id: "?email=" + email }).then(() => {
            console.log("ok")
        }).catch((e) => {
            console.log(e);
        });
    }
    return (
        <Container className={classes.root}>
            <h1>Postmark Details</h1>
            <Grid container spacing={2}>
                {stats?.bounces?.map((item) => (
                    <Grid item >
                        <h3>{item.name}</h3>
                        <p>{item.count}</p>
                    </ Grid>
                ))}
            </Grid>

            <h2>Bounces  ({bounces?.bounces?.bounces?.length})</h2>
            <table>
                <tr>

                    <th>Email</th>
                    <th>Bounce Type</th>
                    <th>Subject</th>
                    <th>Bounced At</th>
                </tr>
                {bounces?.bounces?.bounces?.map((item) => (
                    <tr>
                        <td>{item.email} </td>
                        <td>{item.type}</td>
                        <td>{item.subject}</td>
                        <td>{new Date(item.bouncedAt).toISOString()}</td>
                    </tr>
                ))}
            </table>

            <h2>Suppression Emails ({suppression?.suppressions?.suppressions?.length})</h2>
            <table>
                <tr>
                    <th>Email</th>
                    <th>Bounce Type</th>
                    <th>Created At</th>
                    <th>Action</th>
                </tr>
                {suppression?.suppressions?.suppressions?.map((item) => (
                    <tr>
                        <td>{item.emailAddress}</td>
                        <td>{item.suppressionReason}</td>
                        <td>{new Date(item.createdAt).toISOString()}</td>
                        <td><Button variant="contained" color="secondary" onClick={() => removeSuppression(item.emailAddress)}>Remove</Button> </td>
                    </tr>
                ))}
            </table>
        </Container>
    );

}