/* eslint-disable no-unused-vars */
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}));
function spacecamel(s) {
    return s.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase();
}
export const TrackShow = (props) => {
    const { trackDetails } = props;
    console.log(trackDetails);
    return (
        <TableContainer className="overflow-hidden" component={Paper}>
            <div className="h-[calc(100vh-170px)] block overflow-auto">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">


                <TableRow>
                    <TableCell style={{ verticalAlign: 'top' }} >

                        <TableHead>
                            <h2> Stats </h2>
                            <TableRow>
                                {trackDetails ? Object.keys(trackDetails).map((key) => (
                                    !["nftStatuses", "currentTier", "id"].includes(key) ? <TableCell key={`tablevalue-${key}`}><b>{spacecamel(key)}</b></TableCell> : ""
                                )) : "Loading"}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {trackDetails ? Object.keys(trackDetails).map((key) => (
                                    !["nftStatuses", "currentTier", "id"].includes(key) ?
                                        <TableCell key={`tablevalue-${trackDetails[key]}`}>{trackDetails[key]}</TableCell>
                                        : ""
                                )) : "Loading"}
                            </TableRow>
                        </TableBody>
                        <h2> Tier Info </h2>

                        <TableBody>
                            {trackDetails?.currentTier ? Object.keys(trackDetails.currentTier).map((key) => (

                                !["nftStatuses", "currentTier", "id"].includes(key) ?
                                    <TableRow>
                                        <TableCell key={`tablevalue-${key}`}><b>{spacecamel(key)}</b></TableCell>
                                        <TableCell key={`tablevalue-${trackDetails[key]}`}>{trackDetails.currentTier[key]}</TableCell>  </TableRow>
                                    : ""
                            )) : "Loading"}
                        </TableBody></TableCell>
                    <TableCell vertical-align='top'> <TableHead>
                        <TableRow>
                            <TableCell ><b>Level</b></TableCell>
                            <TableCell ><b>Status</b></TableCell>
                            <TableCell ><b>Nft Id</b></TableCell>
                        </TableRow>
                    </TableHead>
                        <TableBody>

                            {trackDetails?.nftStatuses ? trackDetails?.nftStatuses.map((e) => (
                                <TableRow>
                                    <TableCell>{e.level}</TableCell>
                                    <TableCell>{e.status}</TableCell>
                                    <TableCell>{e.nftId}</TableCell>
                                </TableRow>
                            )) : "Loading"}

                        </TableBody>
                    </TableCell>
                </TableRow>





            </Table>
            </div>
        </TableContainer>
    );
};
