/* eslint-disable no-unused-vars */
import { ArrayInput, Create, NumberInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput } from "react-admin";

export const SymbolCreate = (props) => (
    <Create {...props}>
        <SimpleForm>

            <SelectInput
                source="type"
                choices={[
                    { id: "CURRENCY_EXCHANGE_PAIR", name: "Exchange" },
                    { id: "FUTURES_CONTRACT", name: "Futures" },
                    { id: "OPTION", name: "Options" },
                ]}
            />

            <TextInput source="baseCurrencyName" />
            <TextInput source="quoteCurrencyName" />

            <NumberInput source="decimalQty" />
            <NumberInput source="decimalPrice" />
            <NumberInput source="decimalTotal" />
            <NumberInput source="makerFee" />
            <NumberInput source="takerFee" />

            <ArrayInput source="category">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>

            <ArrayInput source="supportedLiquidityProviders">
                <SimpleFormIterator>
                <SelectInput
                    choices={[
                        { id: "ZELTA", name: "ZELTA" },
                        { id: "BINANCE", name: "BINANCE" },
                        { id: "GATEIO", name: "GATEIO" },
                    ]}
            />
                </SimpleFormIterator>
            </ArrayInput>

        </SimpleForm>

    </Create>
);
