/* eslint-disable no-unused-vars */
import RichTextInput from "ra-input-rich-text";
import * as React from "react";
import { Create, SimpleForm } from "react-admin";

export const SiteUpdate = (props) => (

    <div>
        <h3> Site update notification</h3>

        <Create {...props}>
            <SimpleForm >
                <RichTextInput
                    source="message"
                />

            </SimpleForm>
        </Create>
    </div>
);

