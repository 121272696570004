/* eslint-disable no-unused-vars */
import RichTextInput from "ra-input-rich-text";
import * as React from "react";
import { Create, NumberInput, RichTextField, SimpleForm, TextInput } from "react-admin";

export const NotitificationCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <RichTextInput source="title" />
            <RichTextInput source="body" />
            <TextInput source="cta" />
            <TextInput source="redirectionUrl" />
        </SimpleForm>
    </Create>
);
