import { Datagrid, List, NumberField, TextField } from "react-admin";
import { ColoredChipField } from "../../../helpers/colorChip";
export const AggMarketList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="symbol" />
            <ColoredChipField source="exchange" />
            <TextField source="baseCurrency" />
            <TextField source="quoteCurrency" />
            <NumberField source="decimalPrice" />
            <NumberField source="decimalQty" />
            <NumberField source="decimalTotal" />
            <ColoredChipField source="status" />
        </Datagrid>
    </List>
);