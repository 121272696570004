/* eslint-disable no-unused-vars */
import * as React from "react";
import { Create, NumberInput, SimpleForm, TextInput } from "react-admin";

export const DepositCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="currencyShortName" />
            <NumberInput source="amount" />
            <TextInput source="userId" />
            <TextInput source="txnId" />
            <TextInput source="note" />
            <TextInput source="internalNote" />


            {/* <SelectInput
                source="adjustmentType"
                variant="outlined"
                choices={[
                    { id: "CREDIT", name: "CREDIT" },
                    { id: "DEBIT", name: "DEBIT" },
                    { id: "FREE", name: "FREE" },
                    { id: "REVERT", name: "REVERT" },
                ]}
            /> */}

        </SimpleForm>
    </Create>
);
