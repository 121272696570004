/* eslint-disable no-unused-vars */
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
import { localeDate } from "../../../../helpers/localeDate";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export const Commissions = (props) => {
  const { commissions } = props;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Commission Type</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>CurrencyShortName</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>DateTime</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {commissions
            ? commissions.slice().map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                {/* <TableCell>{row.txnId.includes("vault") ? "Vault" : "Trade"}</TableCell> */}
                <TableCell>{row.note}</TableCell>
                <TableCell>{row.amount}</TableCell>
                <TableCell>{row.currencyShortName}</TableCell>
                <TableCell>{row.transferStatus}</TableCell>
                <TableCell>
                  {row.updatedAt
                    ? localeDate(row.updatedAt, "dateFormat")
                    : "-"}
                </TableCell>
              </TableRow>
            ))
            : "Loading"}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
