
import {
    Datagrid,
    DateField,
    List, NumberField, TextField
} from "react-admin";
import { ColoredChipField } from "../../../helpers/colorChip";

export const AggTransferList = props => (
    <List {...props} sort={{ field: "updatedAt", order: "DESC" }}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <NumberField source="amount" />
            <NumberField source="fee" />
            <ColoredChipField source="transferType" />
            <TextField source="currencyShortName" />
            <ColoredChipField source="fromExchange" />
            <ColoredChipField source="toExchange" />
            <TextField source="userId" />
            <ColoredChipField source="status" />
            <TextField source="txId" />
            <TextField source="address" />
            <TextField source="addressTagOrMemo" />
            <TextField source="networkRouteId" />
            <NumberField source="confirmation" />
            <DateField source="createdAt" showTime />

            <DateField source="updatedAt" showTime />
        </Datagrid>
    </List>
);