import Button from "@material-ui/core/Button";
import * as React from "react";
import { useDataProvider, useRefresh } from "react-admin";

const ProcessButtons = (props) => {
    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = React.useState("");
    const dataProvider = useDataProvider();
    const handleOpen = () => setOpen(true);
    const refresh = useRefresh();
    const submit = (record) => {
        dataProvider
            .update("v1/admin/incidents", { id: record.id, data: record })
            .then(({ data }) => {
                refresh();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return <>

        <Button
            style={{
                backgroundColor: "#25745d",
                color: "white",
                marginRight: "10px",
            }}
            variant="contained"
            onClick={() => {
                submit({ id: props.id, updateAction: "RESOLVE" })
            }}
        >
            RETRY
        </Button>
        <br /> <br />
        <Button
            style={{
                backgroundColor: "red",
                color: "white",
                marginRight: "10px",
            }}
            variant="contained"
            onClick={() => {
                handleOpen()
                submit({ id: props.id, updateAction: "IGNORE" })
            }}
        >
            IGNORE
        </Button>
    </>
}


export default ProcessButtons;