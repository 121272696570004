import { DepositCreate } from "./create";
import { DepositList } from "./list";
import { DepositShow } from "./show";
const resource = {
  create: DepositCreate,
  list: DepositList,
  show: DepositShow
};

export default resource;
