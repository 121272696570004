import React from "react";
import { Layout } from "react-admin";
import TreeMenu from "../utils/ra-tree";

export default function MainLayout(props) {
  return (
    <Layout
      {...props}
      menu={TreeMenu}
    />
  );
}
