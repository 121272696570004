/* eslint-disable no-unused-vars */
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export const BalanceShow = (props) => {
  const { balanceDetails, userId } = props;
  const [searchCoins, setSearchCoins] = React.useState("");
  console.log(balanceDetails);
  return (
    <TableContainer className="overflow-hidden" component={Paper}>
      <input
        type="text"
        style={{
          width: "200px",
          height: "25px",
          marginLeft: "5px",
          marginTop: "5px",
        }}
        placeholder="Search by coin"
        onChange={(e) => {
          setSearchCoins(e.target.value);
        }}
      />
      <div className="h-[calc(100vh-170px)] block overflow-auto">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="sticky top-0 bg-white "> Sl No</TableCell>
              <TableCell className="sticky top-0 bg-white ">
                {" "}
                Currency
              </TableCell>
              <TableCell className="sticky top-0 bg-white "> Balance</TableCell>
              <TableCell className="sticky top-0 bg-white ">
                In Order Balance
              </TableCell>
              <TableCell className="sticky top-0 bg-white ">
                Locked Balance
              </TableCell>
              <TableCell className="sticky top-0 bg-white "> Address</TableCell>
              <TableCell className="sticky top-0 bg-white ">
                {" "}
                Address Tag
              </TableCell>
              {/* <TableCell> Action</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {balanceDetails
              ? balanceDetails.data
                  .slice()
                  .filter((item, i) =>
                    item.currencyShortName
                      ?.toLowerCase()
                      ?.includes(searchCoins?.toLowerCase())
                  )
                  .sort(
                    (a, b) =>
                      Math.max(b.balance , b.inOrderBalance , b.lockedBalance) -
                      Math.max(a.balance , a.inOrderBalance , a.lockedBalance)
                  )
                  .map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell> {index + 1}</TableCell>
                      <TableCell> {row.currencyShortName}</TableCell>
                      <TableCell> {row.balance}</TableCell>
                      <TableCell> {row.inOrderBalance}</TableCell>
                      <TableCell> {row.lockedBalance}</TableCell>
                      <TableCell> {row.address}</TableCell>
                      <TableCell> {row.addressTag}</TableCell>

                      {/* <TableCell>
                                    <Button
                                        style={{
                                            backgroundColor: "#25745d",
                                            color: "white",
                                            marginRight: "10px",
                                        }}
                                        variant="contained"
                                        onClick={() => {
                                            row.status = "verified";
                                            //   submit(row);
                                        }}
                                    >
                                        Edit
                                    </Button>

                                    <AuditButton
                                        userId={userId}
                                        coin={row.currencyShortName}
                                    />
                                </TableCell> */}
                    </TableRow>
                  ))
              : "Loading"}
          </TableBody>
        </Table>
      </div>
    </TableContainer>
  );
};
