/* eslint-disable no-unused-vars */
import * as React from "react";
import { Edit, NumberInput, SimpleForm, TextInput } from "react-admin";

const VaultConfigEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="coinId" />
      <NumberInput source="minAmount" />
      <NumberInput source="maxAmount" />
      <NumberInput source="decimalVault" />
    </SimpleForm>
  </Edit>
);

export default VaultConfigEdit;
