import { BooleanField, Datagrid, DateField, FunctionField, List, TextField } from "react-admin";
import { ColoredChipField } from "../../../helpers/colorChip";
import ProcessButtons from "./ProcessButtons";
export const IncidentList = props => (
    <List {...props} sort={{field:'createdAt',order:"DESC"}}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="cause" />
            <BooleanField source="retry" />
            <ColoredChipField source="reportStatus" />
            <ColoredChipField source="reportModule" />
            <FunctionField source="args" render={(record) => { return <div><pre>{JSON.stringify(JSON.parse(record.args), null, 2)}</pre></div> }} />
            <DateField source="createdAt" showTime />
            <FunctionField
                label="Actions"
                render={(record) => {
                    return (record.reportStatus === "UNRESOLVED") ? (
                        <>
                            <ProcessButtons id={record.id} record={record} />
                        </>
                    ) : null;
                }}
            />
        </Datagrid>
    </List>
);