/* eslint-disable no-unused-vars */
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}));

export const Referrals = (props) => {
    const { userDetails } = props;

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell> Referral Code</TableCell>
                        <TableCell> Email</TableCell>
                        <TableCell> Uid</TableCell>
                        <TableCell> Firstname </TableCell>
                        <TableCell> Lastname</TableCell>
                        <TableCell> Kyc Verified</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {userDetails
                        ? userDetails.referrals.slice().map((row, index) => (
                            <TableRow key={row.id}>

                                <TableCell> {row.id}</TableCell>
                                <TableCell> {row.referredBy}</TableCell>
                                <TableCell> {row.email}</TableCell>
                                <TableCell> {row.uid}</TableCell>
                                <TableCell> {row.firstName ? row.firstName : "-"}</TableCell>
                                <TableCell> {row.lastName ? row.lastName : "-"}</TableCell>
                                <TableCell> {row.kycVerified ? "YES" : "NO"}</TableCell>
                            </TableRow>
                        ))
                        : "Loading"}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
