import { Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import {
    BooleanField,
    Datagrid,
    DateField,
    FunctionField,
    List,
    TextField,
    useDataProvider
} from "react-admin";
import AuditTrailMenu from "../../../components/AuditTrailMenu";
import { ColoredChipField } from "../../../helpers/colorChip";
import { localeDate } from "../../../helpers/localeDate";
import useResizer from "../../../utils/useResizer";
export const AdminAuditTrialList = (props) => {
    const [currencies, setCurrencies] = React.useState({});
    const dataProvider = useDataProvider();
    const [isOpen, setIsOpen] = React.useState(false);
    const [drawerData, setDrawerData] = React.useState();
    const [filterVal, setFilterVal] = React.useState("UPDATE_BALANCE");
    const [searchUid, setSearchUid] = React.useState('')
    const isSmall = useMediaQuery("(max-width:768px)");
    const menuArray = [
        "UPDATE_LEVEL",
        "UPDATE_FEE",
        "UPDATE_BALANCE",
        "UPDATE_DIAMOND_POINTS",
    ];
    React.useEffect(() => {
        dataProvider
            .getList("v1/admin/orderbook/currency", {
                filter: { userId: props.id },
                pagination: { page: 1, perPage: 500 },
                sort: { field: "currencyShortName", order: "DESC" },
            })
            .then(({ data }) => {
                data.forEach((element) => {
                    currencies[element.currencyId] = element.currencyShortName;
                    setCurrencies({ ...currencies });
                });
                // console.log(currencies);
            })
            .catch(() => { });
    }, []);
    const { width, height } = useResizer();
    const useStyles = makeStyles((theme) => {
        return {
            root: {
                "& .MuiFormLabel-root": {
                    fontSize: "12px",
                },
                "& .MuiToolbar-root": {
                    // flexDirection: "column-reverse",
                    width: "80%",
                    position: "relative",
                    marginRight: 0,
                    right: 0,
                },
                "& .MuiPaper-root": {
                    width: "350px",
                    overflow: "auto",
                    height: `${height - 220}px`,
                    [theme.breakpoints.down("768")]: {
                        height: "auto",
                    },
                    position: "static",
                },
                "& .MuiTablePagination-root": {
                    position: "absolute",
                    zIndex: 10,
                    bottom: 0,
                    [theme.breakpoints.down("768")]: {
                        bottom: "-50px",
                    },
                    right: 0,
                    [theme.breakpoints.down("768")]: {
                        left: 0,
                        justifyContent: "center",
                    },
                    width: "60%",
                    display: "flex",
                    justifyContent: "end",
                },
            },
            head: {
                "& .MuiTableCell-head": {
                    fontWeight: 700,
                },
            },
        };
    });
    const classes = useStyles();
    console.log(filterVal);
    return (
        <>
            <div className="pt-4 pl-4 gap-x-3 flex items-center">
                <p className="text-sm font-normal">Command Type</p>
                <AuditTrailMenu
                    menuArray={menuArray}
                    setSelected={setFilterVal}
                    filterVal={filterVal}
                />
                <input type="text" value={searchUid} onChange={(e) => { setSearchUid(e.target.value) }} className="py-1 pl-2 rounded-md border border-black" placeholder="Search by uid" />
            </div>
            <List
                perPage={25}
                filter={{ commandType: filterVal, uid: searchUid }}
                className={`${classes.root} ${classes.head}`}
                bulkActionButtons={false}
                {...props}
                sort={{ field: "timestamp", order: "DESC" }}
            >
                <Datagrid
                    rowClick={(id, resource, record) => {
                        if (isSmall) {
                            setIsOpen(true);
                            setDrawerData(record);
                        }
                    }}
                >
                    <DateField source="timestamp" showTime />
                    <TextField source="commandType" />
                    {filterVal == "UPDATE_BALANCE" && (
                        <ColoredChipField source="adjustmentType" />
                    )}
                    {filterVal == "UPDATE_BALANCE" && (
                        <FunctionField
                            source="currencyId"
                            render={(record) => {
                                return currencies[record.currencyId];
                            }}
                        />
                    )}
                    {filterVal == "UPDATE_BALANCE" && (
                        <TextField label={"Balance"} source="balanceSnapshot.balance" />
                    )}
                    {filterVal == "UPDATE_BALANCE" && (
                        <TextField
                            label={"lockedBalance"}
                            source="balanceSnapshot.lockedBalance"
                        />
                    )}
                    <TextField source="email" />

                    {filterVal == "UPDATE_BALANCE" && (
                        <TextField
                            label={"inorderBalance"}
                            source="balanceSnapshot.inOrderBalance"
                        />
                    )}
                    {filterVal == "UPDATE_BALANCE" && <TextField source="amount" />}
                    {filterVal == "UPDATE_LEVEL" && <TextField source="level" />}
                    {<TextField source="note" />}
                    <TextField source="dp" label="Diamond Points" />
                    {filterVal === "UPDATE_FEE" && <TextField source="makerFee" />}
                    {filterVal === "UPDATE_FEE" && <TextField source="takerFee" />}

                    <BooleanField source="kycVerified" />
                    <TextField source="uid" />
                </Datagrid>
            </List>
            <Drawer
                anchor={"bottom"}
                onClose={() => {
                    setIsOpen(false);
                }}
                open={isOpen}
            >
                <div className="p-3 flex flex-col gap-y-3">
                    <div
                        onClick={() => {
                            setIsOpen(false);
                        }}
                        className="w-full flex justify-center "
                    >
                        <div className="bg-[#ccc] w-[30px] mt-0 h-[30px] flex justify-center items-center rounded-[50%]">
                            X
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Timestamp:</p>
                        <p className=" font-semibold">
                            {localeDate(drawerData?.timestamp, "dateFormat") || ""}
                        </p>
                    </div>

                    <div className="flex justify-between items-center">
                        <p>command type:</p>
                        <p className="font-semibold">{drawerData?.commandType || ""}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Adjustment type:</p>
                        <p className="font-semibold">{drawerData?.adjustmentType || ""}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Currency:</p>
                        <p className="font-semibold">
                            {currencies[drawerData?.currencyId] || ""}
                        </p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Amount:</p>
                        <p className="font-semibold">{drawerData?.amount || ""}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Level:</p>
                        <p className="font-semibold">{drawerData?.level || 0}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Note:</p>
                        <p className=" w-1/2 break-words font-semibold">
                            {drawerData?.note || ""}
                        </p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Diamond points:</p>
                        <p className="font-semibold">{drawerData?.dP || ""}</p>
                    </div>

                    <div className="flex justify-between items-center">
                        <p>Maker fee:</p>
                        <p className="font-semibold">{drawerData?.makerFee || 0}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Taker fee:</p>
                        <p className="font-semibold">{drawerData?.takerFee || 0}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>KYC verified:</p>
                        <p className="font-semibold">
                            {drawerData?.kycVerified ? "Yes" : "No"}
                        </p>
                    </div>

                    <div className="flex justify-between items-center">
                        <p>Uid:</p>
                        <p className="font-semibold">{drawerData?.uid || ""}</p>
                    </div>
                </div>
            </Drawer>
        </>
    );
};
