// in ./ResetViewsButton.js
import { Visibility, VisibilityOff } from "@material-ui/icons";
import CancelScheduleSendIcon from "@material-ui/icons/CancelScheduleSend";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import * as React from "react";
import { BulkUpdateButton } from "react-admin";
const views = { views: 0 };

export const EnableButton = (props) => (
  <BulkUpdateButton
    {...props}
    label="Enable Selected"
    data={views}
    icon={<Visibility />}
  />
);

export const DisableButton = (props) => (
  <BulkUpdateButton
    {...props}
    label="Disable Selected"
    data={views}
    icon={<HighlightOffIcon />}
  />
);

export const WithdrawButton = (props) => (
  <BulkUpdateButton
    {...props}
    label="Update Withdraw"
    data={views}
    icon={<VisibilityOff />}
  />
);
export const DepositButton = (props) => (
  <BulkUpdateButton
    {...props}
    label="Update Deposit"
    data={views}
    icon={<CancelScheduleSendIcon />}
  />
);
export const TradeButton = (props) => (
  <BulkUpdateButton
    {...props}
    label="Update Trade"
    data={views}
    icon={<ImportExportIcon />}
  />
);
