import Centrifuge from "centrifuge";
const channelToSubscription = new Map();
var centrifuge = new Centrifuge(
    "wss://dev.ws.zelta.io/connection/websocket"
);

centrifuge.setToken(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM3MjIiLCJleHAiOjE2ODMxODk5NDksImlhdCI6MTY4MjU4NTE0OX0.9oE0q6dpuidjxtFw39pJDSP2fGx8aaPVRexav71s3p0"
);

centrifuge.connect();

function getNextDailyBarTime(barTime) {
    const date = new Date(barTime * 1000);
    date.setDate(date.getDate() + 1);
    return date.getTime() / 1000;
}

export function subscribeOnStream(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscribeUID,
    onResetCacheNeededCallback,
    lastDailyBar
) {
    //   const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
    const channelString = `${symbolInfo.name}@ticker`;

    const handler = {
        id: subscribeUID,
        callback: onRealtimeCallback,
    };
    let subscriptionItem = channelToSubscription.get(channelString);
    if (subscriptionItem) {
        // already subscribed to the channel, use the existing subscription
        subscriptionItem.handlers.push(handler);
        return;
    }

    subscriptionItem = {
        subscribeUID,
        resolution,
        lastDailyBar,
        handlers: [handler],
    };

    channelToSubscription.set(channelString, subscriptionItem);

    console.log(
        "[subscribeBars]: Subscribe to streaming. Channel:",
        channelString
    );

    centrifuge.subscribe(channelString, function (message) {
        const tradePrice = parseFloat(message.data.price);
        const tradeTime = Date.now();
        const subscriptionItem = channelToSubscription.get(channelString);

        if (subscriptionItem === undefined) {
            return;
        }

        const lastDailyBar = subscriptionItem.lastDailyBar;
        const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);

        let bar;
        if (tradeTime >= nextDailyBarTime) {
            bar = {
                time: nextDailyBarTime,
                open: tradePrice,
                high: tradePrice,
                low: tradePrice,
                close: tradePrice,
            };
            console.log("[socket] Generate new bar", bar);
        } else {
            bar = {
                ...lastDailyBar,
                high: Math.max(lastDailyBar.high, tradePrice),
                low: Math.min(lastDailyBar.low, tradePrice),
                close: tradePrice,
            };
            console.log("[socket] Update the latest bar by price", tradePrice);
        }

        subscriptionItem.lastDailyBar = bar;

        // send data to every subscriber of that symbol
        subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
    });
    //   socket.emit("SubAdd", { subs: [channelString] });
}

export function unsubscribeFromStream(subscriberUID) {
    // find a subscription with id === subscriberUID
    for (const channelString of channelToSubscription.keys()) {
        const subscriptionItem = channelToSubscription.get(channelString);
        const handlerIndex = subscriptionItem.handlers.findIndex(
            (handler) => handler.id === subscriberUID
        );

        if (handlerIndex !== -1) {
            // remove from handlers
            subscriptionItem.handlers.splice(handlerIndex, 1);

            if (subscriptionItem.handlers.length === 0) {
                // unsubscribe from the channel, if it was the last handler
                console.log(
                    "[unsubscribeBars]: Unsubscribe from streaming. Channel:",
                    channelString
                );
                channelToSubscription.delete(channelString);
                break;
            }
        }
    }
}