import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
import moment from "moment-timezone";
import {
  Create,
  DateTimeInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useRefresh,useCreate
} from "react-admin";
import { localeDate } from "../../../../helpers/localeDate";

const redirect = (basePath, id, data) => `/users/${data.userId}`;

const IncidentCreate = (props) => {
    const [type, setType] = React.useState();
    const [create] = useCreate();
    const postSave = (data) => {
        
    };
    return (
      <Create onSuccess={()=>{props.getIncidents(); props.handleClose(); }} resource="userIncidents" basePath="/" >
        <SimpleForm  onSubmit={props.handleClose}>
          <TextInput
            source="incidentDescription"
            variant="outlined"
            placeholder="Note"
            defaultValue=""
          />
          <TextInput
            source="incidentPriority"
            variant="outlined"
            placeholder="Note"
            defaultValue=""
          />
        <SelectInput
                source="reportStatus"
                variant="outlined"
                choices={[
                    { id: "IGNORED", name: "IGNORED" },
                    { id: "RESOLVED", name: "RESOLVED" },
                    { id: "UNRESOLVED", name: "UNRESOLVED" },
                ]}
            />
          <TextInput
            source="userId"
            variant="outlined"
            placeholder="User Id"
            defaultValue={props.userId}
          />
          <DateTimeInput
            source="incidentDateTime"
            defaultValue={new Date().getTime()}
            parse={(v) => {
              console.log(v);
              return (new Date(v).getTime());
            }}
            format={(v) => {
              return moment(v).format('YYYY-MM-DD HH:mm:ss');;
            }}
          />
        </SimpleForm>
      </Create>
    );
  };
  
  function IncidentCreateFormDialog(props) {
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const refresh = useRefresh();
  
    const handleClose = () => {
      setOpen(false);
      // refresh();
    };
   
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClickOpen}
          disabled={props.flagged}
        >
          Report Incident
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title"> Report Incident</DialogTitle>
          <DialogContent>
            <IncidentCreate handleClose={handleClose} {...props} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }


export const IncidentReports = (props) => {
    const { incidents } = props;
    return (
        <TableContainer component={Paper}>
          <IncidentCreateFormDialog
            basePath="/"
            resource="userIncidents"
            userId={props.id}
            getIncidents={props.getIncidents}
          />
          <Table sx={{ minWidth: 1024 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell> Incident  Description</TableCell>
                <TableCell> Incident Priority</TableCell>
                <TableCell> Report By Admin</TableCell>
                <TableCell> Report Statuts</TableCell>
                <TableCell> Incident Time</TableCell>
                <TableCell> Updated Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {incidents
                ? incidents.slice().map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell> {row.incidentDescription}</TableCell>
                      <TableCell> {row.incidentPriority}</TableCell>
                      <TableCell>{row.reportByAdminId}</TableCell>
                      <TableCell> {row.reportStatus}</TableCell>
                      <TableCell>
                        {" "}
                        {row?.createdAt ? localeDate(row.createdAt,"dateFormat") : "-"}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {row?.updatedAt ? localeDate(row.updatedAt,"dateFormat") : "-"}
                      </TableCell>
                    </TableRow>
                  ))
                : "Loading"}
            </TableBody>
          </Table>
        </TableContainer>
      );
}