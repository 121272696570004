import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
/* eslint-disable no-unused-vars */
import * as React from "react";
import {
    Create, SelectInput,
    SimpleForm, TextInput, usePermissions,
    useRefresh
} from "react-admin";
const redirect = (basePath, id, data) => `transferConfig`;
const UpdateAll = (props) => (
    <Create
        title="Update all transfer configs"
        {...props}
        resource="funds/updateAllTransferConfig"
    >
        <SimpleForm redirect={redirect}>

            <TextInput source="network" />
            <SelectInput
                source="transferSide"
                variant="outlined"
                choices={[
                    { id: "DEPOSIT", name: "DEPOSIT" },
                    { id: "WITHDRAW", name: "WITHDRAW" },
                ]}
            />

            <SelectInput
                source="status"
                variant="outlined"
                defaultValue="ACTIVE"
                choices={[
                    { id: "ACTIVE", name: "ACTIVE" },
                    { id: "PAUSED", name: "PAUSED" },
                    { id: "INACTIVE", name: "INACTIVE" },
                ]}
            />
        </SimpleForm>
    </Create>
);

export default function UpdateAllConfigFormDialog(props) {
    const { permissions } = usePermissions();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        refresh();
    };
    const refresh = useRefresh();

    console.log(props);
    return (
        <div>

            <div>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpen}
                    disabled={props.flagged}
                >
                    Enable / Disable Transfer
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title"> Update All</DialogTitle>
                    <DialogContent>
                        <UpdateAll {...props} handleSubmit={refresh} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </div>
    );
}
