/* eslint-disable no-unused-vars */
import * as React from "react";
import { useState } from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  Filter,
  List,
  Pagination,
  SearchInput,
  TextField,
  useDataProvider,
  useRefresh,
} from "react-admin";

const PromoCodesFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="searchId" alwaysOn />
  </Filter>
);
const PostPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const RewardConfigList = (props) => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const refresh = useRefresh();

  return (
    <div>
      <List
        bulkActionButtons={false}
        {...props}
        filters={<PromoCodesFilter />}
        pagination={<PostPagination />}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="bonusFor" />
          <TextField source="bonusCode" />

          <TextField source="recordType" />
          <ArrayField source="directBonuses">
            <Datagrid>
              <ChipField source="baseCoinId" />
              <ChipField source="quoteCoinId" />
              <ChipField source="value" />
              <ChipField source="redemptionTradeValue" />
            </Datagrid>
          </ArrayField>

          <ArrayField source="referrerBonuses">
            <Datagrid>
              <ChipField source="baseCoinId" />
              <ChipField source="quoteCoinId" />
              <ChipField source="value" />
              <ChipField source="redemptionTradeValue" />
            </Datagrid>
          </ArrayField>

          <TextField source="tradeCoinId" />

          <TextField source="active" />

          <DateField source="startDate" showTime width="100%" />
          <DateField source="endDate" showTime width="100%" />
        </Datagrid>
      </List>
    </div>
  );
};

export default RewardConfigList;
