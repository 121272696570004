import {
  DateField,
  Edit,
  NumberField,
  BooleanField,
  Show,
  SimpleShowLayout,
  TextField,
  DeleteButton,
} from "react-admin";

export const DepositShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout className="md:flex flex-col overflow-auto  flex-wrap gap-10 h-screen">
      <DateField showTime source="createdAt" />
      <TextField source="currencyShortName" />
      <NumberField source="amount" />
      <TextField source="user.email" />
      <TextField source="address" />
      <TextField source="addressTag" />
      <TextField source="transferType" />
      <TextField source="transferStatus" />
      <BooleanField source="fromDifferentNetwork" />
      <TextField source="id" />

      <NumberField source="transferId" />

      <TextField source="fee" />
      <TextField source="feeCurrency" />
      <TextField source="note" />
      <TextField source="internalNote" />

      <TextField source="txnId" className="block max-w-[300px] md:max-w-[400px] break-words" />
      <TextField source="rawData" className="block max-w-[300px] md:max-w-[400px] break-words" />
      <TextField source="gateway" />
      <TextField source="network" />
      <TextField source="tokenType" />

      <NumberField source="confirmation" />
      <NumberField source="requiredConfirmation" />

      <DateField showTime source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);
