import Grid from "@material-ui/core/Grid";
/* eslint-disable no-unused-vars */
import * as React from "react";
import { FilterList, FilterListItem } from "react-admin";

export const StatusFilter = () => (
  <div>
  <FilterList label="Status" source="status">
    <Grid container spacing={1}>
      <Grid item>
        <FilterListItem label="In vault" value={{ status: "IN_VAULT" }} />
      </Grid>
      <Grid item>
        <FilterListItem label="Cancelled" value={{ status: "CANCELLED" }} />
      </Grid>
      <Grid item>
        <FilterListItem label="Matured" value={{ status: "MATURED" }} />
      </Grid>
    </Grid>
  </FilterList>
  </div>
);

export const TenureFilter = () => (
  <div>
  <FilterList label="Tenure" source="window">
    <Grid container spacing={1}>
      <Grid item>
        <FilterListItem label="One week" value={{ window: "ONE_WEEK" }} />
      </Grid>
      <Grid item>
        <FilterListItem label="One month" value={{ window: "ONE_MONTH" }} />
      </Grid>
      <Grid item>
        <FilterListItem label="Three months" value={{ window: "THREE_MONTHS" }} />
      </Grid>
      <Grid item>
        <FilterListItem label="Six months" value={{ window: "SIX_MONTHS" }} />
      </Grid>
      <Grid item>
        <FilterListItem label="One year" value={{ window: "ONE_YEAR" }} />
      </Grid>
      <Grid item>
        <FilterListItem label="Flexible" value={{ window: "FLEXIBLE" }} />
      </Grid>
    </Grid>
  </FilterList>
  </div>
);
