/* eslint-disable no-unused-vars */
import * as React from "react";
import { Redirect } from "react-router-dom";

const AdminMailConfigList = (props) => (
  <Redirect to="adminMailConfig/1" />
  // <List {...props}>
  //     <Datagrid rowClick="edit">
  //         <TextField source="id" />
  //         <BooleanField source="wazirxPriceOverride"/>
  //         <BooleanField source="coindcxPriceOverride"/>
  //         <BooleanField source="zebPriceOverride"/>
  //         <BooleanField source="bitbnsPriceOverride"/>
  //         <BooleanField source="isManual"/>
  //         <NumberField source="offsetInrSell"/>
  //         <NumberField source="offsetInrBuy"/>
  //         <NumberField source="offsetBinancePercentageBuy"/>
  //         <NumberField source="offsetBinancePercentageSell"/>
  //         <NumberField source="usdtInrBuy"/>
  //         <NumberField source="usdtInrSell"/>
  //         <EditButton basePath="/pricingConfig" />
  //     </Datagrid>
  // </List>
);
export default AdminMailConfigList;
