import { EditGuesser, ShowGuesser } from "react-admin";
import { WithdrawCreate } from "./create";
import { WithdrawList } from "./list";
import { WithdrawShow } from "./show";
const resource = {
  list: WithdrawList,
  create: WithdrawCreate,  
  show: WithdrawShow,
  edit: EditGuesser
};

export default resource;
