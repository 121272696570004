/* eslint-disable no-unused-vars */
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@mui/material/Pagination";
import * as React from "react";
import { useDataProvider } from "react-admin";
import { localeDate } from "../../../../helpers/localeDate";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}));

export const UserActions = (props) => {
    const dataProvider = useDataProvider();

    const [userActions, setUserActions] = React.useState();
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const rowArray = [25, 50, 100];

    React.useEffect(() => {
        getHistoricOrders(
            selectedPage,
            rowsPerPage
        );
    }, [selectedPage, rowsPerPage]);

    React.useEffect(() => {
        setSelectedPage(1);
    }, []);

    const getHistoricOrders = (selectedPage, rows) => {
        dataProvider
            .getList("v1/admin/userActivity/" + props.id, {
                pagination: { page: selectedPage, perPage: rows },
                sort: { field: "createdAt", order: "DESC" },
            })
            .then((res) => {
                setUserActions(res);
                props.setLoading(false);
            })
            .catch(() => {
                props.setLoading(false);
            });
    };

    return (
        <TableContainer component={Paper}>
            <div className="h-[calc(100vh-170px)] block overflow-auto">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead  >
                        <TableRow>
                            <TableCell className="sticky top-0 bg-white "> Sl No</TableCell>
                            <TableCell className="sticky top-0 bg-white"> Time</TableCell>
                            <TableCell className="sticky top-0 bg-white" > Module</TableCell>
                            <TableCell className="sticky top-0 bg-white"> Action</TableCell>
                            <TableCell className="sticky top-0 bg-white"> User Request</TableCell>
                            <TableCell className="sticky top-0 bg-white"> Response</TableCell>

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {userActions
                            ? userActions.data.slice().map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell> {index + 1}</TableCell>
                                    <TableCell>
                                        {" "}
                                        {row.dateTime
                                            ? localeDate(row.dateTime, "dateFormat")
                                            : "-"}
                                    </TableCell>
                                    <TableCell> {row.module}</TableCell>
                                    <TableCell> {row.action}</TableCell>
                                    <TableCell> {row.requestPayload ? JSON.stringify(row.requestPayload) : row.requestBody}</TableCell>
                                    <TableCell> {row.responseStatus}</TableCell>

                                </TableRow>
                            ))
                            : "Loading"}
                    </TableBody>
                </Table>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                }}
                className="flex-col md:flex-row  md:gap-6"
            >
                <Pagination
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                    count={Math.ceil(userActions?.total / rowsPerPage)}
                    page={selectedPage}
                    onChange={(e, val) => {
                        setSelectedPage(val);
                    }}
                />
                <p>Rows per page</p>
                <div
                    style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        gap: 4,
                    }}
                >
                    {rowArray.map((item, i) => {
                        return (
                            <div
                                onClick={() => {
                                    setRowsPerPage(item);
                                    setSelectedPage(1);
                                }}
                                style={{
                                    background: item == rowsPerPage ? "rgba(0, 0, 0, 0.08)" : "",
                                    width: "max-content",
                                    paddingRight: "5px",
                                    paddingLeft: "5px",
                                    borderRadius: "3px",
                                }}
                                key={i}
                            >
                                {item}
                            </div>
                        );
                    })}
                </div>
            </div>
        </TableContainer>
    );
};
