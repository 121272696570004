/* eslint-disable no-unused-vars */
import { Box } from "@material-ui/core";
import * as React from "react";
import { BooleanField, CreateButton, Datagrid, DateField, ExportButton, FilterLiveSearch, List, NumberField, Pagination, TextField, TopToolbar } from "react-admin";
import UpdateAllConfigFormDialog from "./configUpdate";
import { makeStyles } from "@material-ui/core/styles";
import useResizer from "../../../utils/useResizer"
const ListActions = () => (
    <Box >
        <TopToolbar>
            <CreateButton />
            <ExportButton />
        </TopToolbar>{" "}
    </Box>
);

const FilterSidebar = (props) => (
    <div className="md:flex  ">
        <div style={{ width: 300 }}>
            {" "}
            <FilterLiveSearch source="coinId" label="Search currency" />{" "}
        </div>

        <div style={{ width: 300, marginRight: 20 }}>
            {" "}
            <FilterLiveSearch source="network" label="Search network" />{" "}
        </div>

    </div>

);
const TransferconfigList = props => {
    const {width,height}=useResizer()
    const useStyles = makeStyles((theme) => {
      return (
      {
      root: {
        "& .MuiFormLabel-root": {
          fontSize: "12px",
        },
        "& .MuiToolbar-root": {
          // flexDirection: "column-reverse",
          width: "100%",
        },
        "& .MuiPaper-root": {
          width: "350px",
          overflow: "auto",
          height:`${height-200}px`,
          [theme.breakpoints.down('768')]:{
            height:'auto'
          },
          position:'static'
        },
        "& .MuiTablePagination-root":{
          position:"absolute",
          zIndex:10,
          bottom:0,
          [theme.breakpoints.down('768')]:{
           bottom:'-50px'
          },
          right:0,
          left:0,
          display:'flex'
        }
      },
      head:{
        "& .MuiTableCell-head":{
          fontWeight:700
        }
      }
    })});
    const classes = useStyles();
    return(

    <>
        <UpdateAllConfigFormDialog basePath="/" resource="updateAllCoinConfig" />
        <List className={`${classes.root} ${classes.head}`} {...props} perPage={100} actions={<ListActions />} filters={<FilterSidebar />} bulkActionButtons={false} pagination={<Pagination rowsPerPageOptions={[100, 150, 200, 250]} />}>
            <Datagrid rowClick="edit">
                <TextField source="coinId" />
                <TextField source="tokenType" />
                <TextField source="network" />

                <TextField source="parentCoinId" />

                <TextField source="transferSide" />
                <TextField source="minAmount" />
                <TextField source="fee" />
                <TextField source="status" />
                <BooleanField source="tagOrMemo" />
                <DateField source="memoType" />
                <DateField source="contract" />
                <NumberField source="confirmations" />
            </Datagrid>
        </List>
    </>

)};

export default TransferconfigList;