/* eslint-disable no-unused-vars */
import { makeStyles, Switch, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { createTheme } from "@material-ui/core/styles";
import * as React from "react";
import { useDataProvider, usePermissions } from "react-admin";
import { localeDate } from "../../../../helpers/localeDate";
import { icons } from "./kyc-icons";

import { Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


const theme = createTheme({
    palette: {
        type: "light", // Switching the dark mode on is a single property value change.
    },
});

const kycStyles = makeStyles({
    paper: {
        padding: "20px 24px 12px 0px",
    },
    formControl: {
        marginBottom: theme.spacing.unit * 3,
    },
    gridRow: {
        justifyContent: "space-between",
        paddingRight: theme.spacing.unit * 3,
    },
    gridColumn: {
        display: "flex",
        flexDirection: "column",
    },
    gridRowWithDivider: {
        borderColor: "#e8e8e8",
        border: 0,
        borderLeft: 1,
        borderStyle: "solid",
        justifyContent: "space-between",
        marginTop: theme.spacing.unit * 1.5,
        marginBottom: theme.spacing.unit * 1.5,
        height: "15px",
    },
    title: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 3,
        letterSpacing: "0.15px",
        fontWeight: 600,
    },
    info: {
        opacity: 0.72,
        letterSpacing: "0.25px",
        marginBottom: theme.spacing.unit * 2,
    },
    level: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },
});

export const KycSummary = (props) => {
    const { loading, permissions } = usePermissions();

    const classes = kycStyles();
    const dataProvider = useDataProvider();
    // const { user } = props;
    const [user, setUser] = React.useState(props.user);

    const [userRole, setUserRole] = React.useState(user.role);

    const [userStatus, setUserStatus] = React.useState(user.status);

    const checkLabel = (key) => {
        if (user) {
            if (key == "Vip") {
                return user['userType'] == "VIP";
            } else {
                return user[key + "Verified"] ? user[key + "Verified"] === true : false;
            };
        }

    }

    const verifyEmail = (id, verified) => {
        dataProvider
            .create("v1/admin/emailVerify/" + id + '?verified=' + verified, { data: {} })
            .then((data) => {
                setUser(data.data);
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
            });


    };

    const updateStatus = (id, status) => {
        dataProvider
            .update("v1/admin/updateStatus", { id: id + '/' + status, data: {} })
            .then((data) => {
                setUser(data.data);
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
            });
    };


    const submit = (id, role) => {
        dataProvider
            .update("v1/admin/userType", { id: id + '/' + role, data: {} })
            .then((data) => {

                setUser(data.data)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const submit2fa = (id, status) => {
        dataProvider
            .update("user2fa", { id: id + '/' + status, data: {} })
            .then((data) => {
                setUser(data.data)
            })
            .catch((error) => {
                console.log(error);
            });

        window.location.reload();
    };

    const submitRole = (data) => {
        console.log(data);
        dataProvider
            .update("v1/admin/userRole", { id: user.id + '/' + userRole, data: {} })
            .then((data) => {
                setUser(data.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getRow = (key, index) => {
        const classes = kycStyles();
        const status = checkLabel(key.toLowerCase()) ? "verified" : "verification";

        return <Grid item={true} className={classes.level} key={key}>
            <Grid
                item={true}
                className={classes.gridColumn}
                style={{ alignItems: "flex-start" }}
                xs={11}
            >
                <Typography variant="subtitle1" style={{ letterSpacing: "0.44px" }}>
                    {key} {status}
                </Typography>
                <Typography variant="body2" className={classes.info}>
                    {status === "verified"
                        ? `Level ${index + 1} completed at ${localeDate(
                            user.updatedAt,
                            "shortDate"
                        )}`
                        : `Confirm the ${key}`}
                </Typography>
            </Grid>
            <Grid item={true} className={classes.gridColumn}>
                <Switch
                    checked={status === "verified"}
                    onChange={e => key === 'Email' ? verifyEmail(user.id, status !== 'verified') : null}
                    color="primary"
                />
            </Grid>
        </Grid>

    };

    return (
        <div className={classes.paper}>
            {user ?
                <Grid container={true}>
                    <Grid item={true} xs={12} className={classes.gridRow}>
                        <Typography variant="h6" className={classes.title}>
                            KYC verification
                        </Typography>
                    </Grid>

                    <Grid
                        item={true}
                        xs={1}
                        className={classes.gridColumn}
                        style={{ alignItems: "center", marginTop: 10 }}
                    >
                        {checkLabel("email") ? icons("success") : icons("email")}
                        {/* <div className={classes.gridRowWithDivider} />
                        {checkLabel("phone") ? icons("success") : icons("phone")} */}
                        <div className={classes.gridRowWithDivider} />
                        {checkLabel("kyc") ? icons("success") : icons("document")}
                    </Grid>
                    <Grid
                        item={true}
                        container={true}
                        xs={11}
                        className={classes.gridColumn}
                    >
                        {["Email", "Kyc"].map((item, index) =>
                            getRow(item, index)
                        )}
                    </Grid>
                </Grid> : "Loading"}

            <Grid container={true}>
                <Grid item={true} xs={12} className={classes.gridRow}>
                    <Typography variant="h6" className={classes.title}>
                        Type Update
                    </Typography>
                </Grid>


                <Grid
                    item={true}
                    container={true}
                    xs={11}
                    className={classes.gridColumn}
                >

                    <Grid item={true} className={classes.level} key="userType">
                        <Grid
                            item={true}
                            xs={1}
                            className={classes.gridColumn}
                            style={{ alignItems: "center", marginTop: 10 }}
                        >
                            {user?.userType === "VIP" ? icons("success") : icons("email")}
                        </Grid>

                        <Grid
                            item={true}
                            className={classes.gridColumn}
                            style={{ alignItems: "flex-start" }}
                            xs={11}
                        >
                            <Typography variant="subtitle1" style={{ letterSpacing: "0.44px" }}>
                                Is VIP?
                            </Typography>
                            <Typography variant="body2" className={classes.info}>
                                {user?.userType == "VIP"
                                    ? `Is a vip user`
                                    : `Regular User`}
                            </Typography>
                        </Grid>

                        <Grid item={true} className={classes.gridColumn}>
                            <Switch
                                checked={user?.userType === "VIP"}
                                onChange={e => { submit(user?.id, user?.userType == "VIP" ? "REGULAR" : "VIP") }}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>

            <Grid container={true}>
                <Grid item={true} xs={12} className={classes.gridRow}>
                    <Typography variant="h6" className={classes.title}>
                        Two Factor Update
                    </Typography>
                </Grid>


                <Grid
                    item={true}
                    container={true}
                    xs={11}
                    className={classes.gridColumn}
                >

                    <Grid item={true} className={classes.level} key="userType">
                        <Grid
                            item={true}
                            xs={1}
                            className={classes.gridColumn}
                            style={{ alignItems: "center", marginTop: 10 }}
                        >
                            {user?.twoFAEnabled ? icons("success") : icons("email")}
                        </Grid>

                        <Grid
                            item={true}
                            className={classes.gridColumn}
                            style={{ alignItems: "flex-start" }}
                            xs={11}
                        >
                            <Typography variant="subtitle1" style={{ letterSpacing: "0.44px" }}>
                                Two Factor
                            </Typography>
                            <Typography variant="body2" className={classes.info}>
                                {user?.twoFAEnabled
                                    ? `Two factor enabled`
                                    : `Two factor disabled`}
                            </Typography>
                        </Grid>

                        <Grid item={true} className={classes.gridColumn}>
                            <Switch
                                checked={user?.twoFAEnabled}
                                onChange={e => { submit2fa(user?.id, "DISABLE") }}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>

            {loading ? "Loading" : permissions.includes("SUPERADMIN") ?
                <Grid container={true}>
                    <Grid item={true} xs={12} className={classes.gridRow}>
                        <Typography variant="h6" className={classes.title}>
                            User Role
                        </Typography>
                    </Grid>

                    <Grid
                        item={true}
                        container={true}
                        xs={11}
                        className={classes.gridColumn}
                    >

                        <Grid item={true} className={classes.level} key="userRole">
                            <Grid
                                item={true}
                                xs={1}
                                className={classes.gridColumn}
                                style={{ alignItems: "center", marginTop: 10 }}
                            >
                                {icons("email")}
                            </Grid>

                            <Grid
                                item={true}
                                className={classes.gridColumn}
                                style={{ alignItems: "flex-start" }}
                                xs={11}
                            >
                                <Typography variant="subtitle1" style={{ letterSpacing: "0.44px" }}>
                                    User Role
                                </Typography>
                                <Typography variant="body2" className={classes.info}>
                                    {user.role}
                                </Typography>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">User Role</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={userRole}
                                        label="Age"
                                        onChange={(e) => setUserRole(e.target.value)}
                                    >
                                        <MenuItem value="USER">User</MenuItem>
                                        <MenuItem value="MARKETING">Marketing</MenuItem>
                                        <MenuItem value="STAFF">Staff</MenuItem>
                                        <MenuItem value="EXECUTIVE">Executive</MenuItem>
                                        <MenuItem value="ADMIN">Admin</MenuItem>
                                        <MenuItem value="SUPERADMIN">Superadmin</MenuItem>
                                    </Select>
                                </FormControl>
                                <br></br>
                                <Button variant="contained" onClick={submitRole}> Update User Role </Button>

                            </Grid>


                        </Grid>
                    </Grid>


                </Grid>
                : null}

            {loading ? "Loading" : permissions.includes("SUPERADMIN") ?
                <Grid container={true}>
                    <Grid item={true} xs={12} className={classes.gridRow}>
                        <Typography variant="h6" className={classes.title}>
                            User Status
                        </Typography>
                    </Grid>

                    <Grid
                        item={true}
                        container={true}
                        xs={11}
                        className={classes.gridColumn}
                    >

                        <Grid item={true} className={classes.level} key="userRole">
                            <Grid
                                item={true}
                                xs={1}
                                className={classes.gridColumn}
                                style={{ alignItems: "center", marginTop: 10 }}
                            >
                                {icons("email")}
                            </Grid>

                            <Grid
                                item={true}
                                className={classes.gridColumn}
                                style={{ alignItems: "flex-start" }}
                                xs={11}
                            >
                                <Typography variant="subtitle1" style={{ letterSpacing: "0.44px" }}>
                                    User Status
                                </Typography>
                                <Typography variant="body2" className={classes.info}>
                                    {user.status}
                                </Typography>

                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">User Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={userStatus}
                                        label="Age"
                                        onChange={(e) => setUserStatus(e.target.value)}
                                    >
                                        <MenuItem value="UNVERIFIED">UNVERIFIED</MenuItem>
                                        <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                                        <MenuItem value="BLOCKED">BLOCKED</MenuItem>
                                        <MenuItem value="BANNED">BANNED</MenuItem>

                                    </Select>
                                </FormControl>
                                <br></br>
                                <Button variant="contained" onClick={e => { updateStatus(user.id, userStatus) }}> Update User status </Button>

                            </Grid>
                        </Grid>
                    </Grid>


                </Grid>
                : null}
        </div>
    );
};
