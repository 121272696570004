/* eslint-disable no-unused-vars */
import RichTextInput from "ra-input-rich-text";
import * as React from "react";
import { Create, NumberInput, RichTextField, SimpleForm, TextInput } from "react-admin";

export const AffiliateCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="userId" />
            <TextInput source="affiliateCode" />
            <NumberInput source="referralCommissionPercent" />
            <TextInput source="vaultReferralCommissionPercent" />
        </SimpleForm>
    </Create>
);
