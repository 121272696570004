
import {
    ChipField
} from "react-admin";

import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

const useStyles = makeStyles({
    buy: { backgroundColor: "green", borderRadius: "5px", color: "white", fontWeight: "bold" },
    sell: { backgroundColor: "red", borderRadius: "5px", color: "white", fontWeight: "bold" },
    pending: { backgroundColor: "yellow", borderRadius: "5px", color: "black", fontWeight: "bold" },
    filled: { backgroundColor: "green", borderRadius: "5px", color: "white", fontWeight: "bold" },
    completed: { backgroundColor: "green", borderRadius: "5px", color: "white", fontWeight: "bold" },
    cancelled: { backgroundColor: "red", borderRadius: "5px", color: "white", fontWeight: "bold" },
    failed: { backgroundColor: "red", borderRadius: "5px", color: "white", fontWeight: "bold" },
    gateio: { backgroundImage: `url('../../../public/blue_prod.png')`, backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", backgroundPosition: "left", }
});


export const ColoredChipField = (props) => {
    const classes = useStyles();

    const isBuy = (v) => v?.toUpperCase() === "BID" || v?.toUpperCase() == "CREDIT" || v?.toUpperCase() == "REVERT" || v?.toUpperCase() == "ACTIVE";
    const isSell = (v) => v?.toUpperCase() === "ASK" || v?.toUpperCase() == "DEBIT" || v?.toUpperCase() == "FREE" || v?.toUpperCase() == "UNRESOLVED" || v?.toUpperCase() == "INACTIVE";
    const isPending = (v) => v?.toUpperCase() === "OPEN" || v?.toUpperCase() == "LOCK" || v?.toUpperCase() == "PAUSED";
    const isCancelled = (v) => v?.toUpperCase() === "CANCELLED";
    const isCompleted = (v) => v?.toUpperCase() === "COMPLETED";
    const isFilled = (v) => v?.toUpperCase() === "FILLED";
    const isFailed = (v) => v?.toUpperCase() === "FAILED" || v?.toUpperCase() === "REJECTED";
    const getUrl = (exchange) => {
        if (exchange === "GATEIO") {
            return "https://uat.zelta.io/gateio.svg"
        } else if (exchange === "BINANCE") {
            return "https://uat.zelta.io/binanceicon.svg"
        } else if (exchange === "KUCOIN") {
            return "https://uat.zelta.io/kucoin.svg"
        } else if (exchange === "BITGET") {
        } else if (exchange === "ZELTA") {
            return 'https://uat.zelta.io/zeltaaggr.svg'
        }
    }
    return (
        <ChipField
            className={classnames({
                [classes.buy]: isBuy(props.record[props.source]),
                [classes.sell]: isSell(props.record[props.source]),
                [classes.pending]: isPending(props.record[props.source]),
                [classes.completed]: isCompleted(props.record[props.source]),
                [classes.cancelled]: isCancelled(props.record[props.source]),
                [classes.filled]: isFilled(props.record[props.source]),
                [classes.failed]: isFailed(props.record[props.source]),
            })}
            avatar={getUrl(props.record[props.source]) ? <Avatar alt={props.record[props.source]} src={getUrl(props.record[props.source])} /> : null}
            {...props}
        />
    );
};
