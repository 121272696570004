import { Box, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/* eslint-disable no-unused-vars */
import * as React from "react";
import {
    FilterList,
    FilterListItem,
    SelectInput,
    TextInput,
    useListContext,
} from "react-admin";
import { Form } from "react-final-form";

const TransactionFilter = (props) => {
    return props.context === "button" ? null : (
        <TransactionFilterForm {...props} />
    );
};

const TransactionFilterForm = ({ open }) => {
    const { displayedFilters, filterValues, setFilters, showFilter } =
        useListContext();

    const onSubmit = (values) => {
        if (Object.keys(values).length > 0) {
            setFilters(values);
        }
    };

    const resetFilter = () => {
        setFilters({ showFilter: "main" }, []);
    };

    return (
        <div>
            <Form onSubmit={onSubmit} initialValues={filterValues}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box mt={8} />
                        <Box display="flex" alignItems="flex-end" mb={1}>
                            <Box component="span" mr={2} mb={-2}>
                                {/* Commentable filter */}
                            </Box>

                            <Box component="span" mr={2} mb={-2}>
                                {/* Commentable filter */}
                                <SelectInput
                                    source="status"
                                    variant="outlined"
                                    allowEmpty={true}
                                    choices={[
                                        { id: "FILLED", name: "FILLED" },
                                        { id: "FAILED", name: "FAILED" },
                                        { id: "EXPIRED", name: "EXPIRED" },
                                        { id: "IN_PROGRESS", name: "IN_PROGRESS" },
                                        { id: "CANCELLED", name: "CANCELLED" },
                                        { id: "PENDING", name: "PENDING" },
                                    ]}
                                />
                            </Box>

                            <Box component="span" mr={2} mb={-2}>
                                <TextInput
                                    variant="outlined"
                                    source="userId"
                                    label="Search by User ID"
                                />
                            </Box>
                            <Box component="span" mr={2} mb={-2}>
                                <TextInput
                                    variant="outlined"
                                    source="_id"
                                    label="Search by id"
                                />
                            </Box>

                            {/* <Box component="span" mr={2} mb={-2}>
                                <DateInput
                                    variant="outlined"
                                    source="startDate"
                                    label="Start Date"
                                />
                            </Box>
                            <Box component="span" mr={2} mb={-2}>
                                <DateInput
                                    variant="outlined"
                                    source="endDate"
                                    label="End Date"
                                />
                            </Box> */}

                            <Box component="span" mr={2} mb={1.5}>
                                <Button variant="outlined" color="secondary" type="submit">
                                    Filter
                                </Button>
                            </Box>
                            <Box component="span" mb={1.5}>
                                <Button variant="outlined" onClick={resetFilter}>
                                    Reset
                                </Button>
                            </Box>
                        </Box>
                    </form>
                )}
            </Form>
        </div>
    );
};

export const StatusFilter = () => (
    <FilterList label="Status" source="status">
        <Grid container spacing={1}>
            <Grid item>
                <FilterListItem
                    label="To Be Placed"
                    value={{ status: "TO_BE_PLACED" }}
                />
            </Grid>
            <Grid item>
                <FilterListItem label="Open" value={{ status: "OPEN" }} />
            </Grid>
            <Grid item>
                <FilterListItem
                    label="Partially Filled"
                    value={{ status: "PARTIALLY_FILLED" }}
                />
            </Grid>
            <Grid item>
                <FilterListItem label="Filled" value={{ status: "FILLED" }} />
            </Grid>
            <Grid item>
                <FilterListItem label="Failed" value={{ status: "FAILED" }} />
            </Grid>
            <Grid item>
                <FilterListItem label="Cancelled" value={{ status: "CANCELLED" }} />
            </Grid>
        </Grid>
    </FilterList>
);

export const MarketFilter = () => (
    <div>
        <FilterList label="Market Type" source="type">
            {" "}
            <Grid container spacing={1}>
                <Grid item>
                    <FilterListItem label="Market" value={{ type: "GTC" }} />
                </Grid>
                <Grid item>
                    <FilterListItem label="Limit" value={{ type: "IOC" }} />
                </Grid>
            </Grid>
        </FilterList>
    </div>
);

export const AggMarketFilter = () => (
    <div>
        <FilterList label="Market Type" source="type">
            {" "}
            <Grid container spacing={1}>
                <Grid item>
                    <FilterListItem label="Market" value={{ type: "MARKET" }} />
                </Grid>
                <Grid item>
                    <FilterListItem label="Limit" value={{ type: "LIMIT" }} />
                </Grid>
            </Grid>
        </FilterList>
    </div>
);


export const ActionFilter = () => (
    <div>
        <FilterList label="Action" source="action">
            <Grid container spacing={1}>
                <Grid item>
                    <FilterListItem label="BID" value={{ action: "BID" }} />
                </Grid>
                <Grid item>
                    <FilterListItem label="ASK" value={{ action: "ASK" }} />
                </Grid>
            </Grid>
        </FilterList>
    </div>
);

export default TransactionFilter;
