import { SymbolCreate } from "./create";
import { SymbolEdit } from "./edit";
import { SymbolList } from "./list";
const resource = {
    create: SymbolCreate,
    list: SymbolList,
    edit: SymbolEdit
};

export default resource;
