import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import * as React from "react";
import AuditPopup from "./audit.popup";

const AuditButton = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        style={{
          backgroundColor: "#333333",
          color: "white",
          marginRight: "10px",
        }}
        variant="contained"
        onClick={handleOpen}
      >
        Audit
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AuditPopup userId={props.userId} coin={props.coin} />
      </Modal>
    </>
  );
};

export default AuditButton;
