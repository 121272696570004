import TaskConfigCreate from "./create";
import TaskConfigEdit from "./edit";
import TaskConfigList from "./list";
import TaskConfigShow from "./show";

const resource = {
    list: TaskConfigList,
    edit: TaskConfigEdit,
    show: TaskConfigShow,
    create: TaskConfigCreate,
};

export default resource;
