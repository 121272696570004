export const themeMain = {
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#fafafa" },

    primary: {
      light: "#424242",
      main: "#424242",
      dark: "#424242",
      contrastText: "#FD5868",
    },

    secondary: {
      light: "rgba(0, 0, 0, 1)",
      contrastText: "#FFFFFF",
      main: "#0047AB",
      dark: "#0047AB",
    },

    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#FD5868",
    },

    text: {
      primary: "rgba(0, 0, 0, 1)",
      secondary: "rgba(0, 0, 0, 1)",
      disabled: "rgba(0, 0, 0, 1)",
      hint: "rgba(255,255, 255, 0.38)",
    },

    overrides: {
      //...
      RaMenuItemLink: {
        active: {
          color: "rgba(37, 116, 93, 1)",
          fontWeight: 700,
        },
      },
    },
  },
};
