/* eslint-disable no-unused-vars */
import * as React from "react";
import { Create, NumberInput, SimpleForm, TextInput } from "react-admin";

const VaultConfigCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="coinId" />
      <NumberInput source="minAmount" />
      <NumberInput source="maxAmount" />
      <NumberInput source="decimalVault" />
    </SimpleForm>
  </Create>
);

export default VaultConfigCreate;
