/* eslint-disable no-unused-vars */
import * as React from "react";
import { useState } from "react";
import {
  Datagrid,
  Filter,
  List,
  NumberField,
  Pagination,
  SearchInput,
  TextField,
  useDataProvider,
  useRefresh,
} from "react-admin";
import useResizer from "../../../utils/useResizer";
import { makeStyles } from "@material-ui/core/styles";

const VaultConfigFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="searchId" alwaysOn />
  </Filter>
);
const PostPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const VaultConfigList = (props) => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const refresh = useRefresh();
  const { width, height } = useResizer();
  const useStyles = makeStyles((theme) => {
    return {
      matured: {
        backgroundColor: "green",
        borderRadius: "5px",
        color: "white",
      },
      cancelled: {
        backgroundColor: "red",
        borderRadius: "5px",
        color: "white",
      },
      in_vault: {
        backgroundColor: "blue",
        borderRadius: "5px",
        color: "white",
      },
      root: {
        "& .MuiFormLabel-root": {
          fontSize: "12px",
        },
        "& .MuiToolbar-root": {

          width: "100%",
        },
        "& .MuiPaper-root": {
          width: "350px",
          overflow: "auto",
          height: `${height -165}px`,
          [theme.breakpoints.down("768")]: {
            height: "auto",
          },
          position: "static",
        },
        "& .MuiTablePagination-root": {
          position: "absolute",
          zIndex: 10,
          bottom: 0,
          [theme.breakpoints.down("768")]: {
            bottom: "-50px",
          },
          right: 0,
          left: 0,
          display: "flex",
        },
      },
      head: {
        "& .MuiTableCell-head": {
          fontWeight: 700,
        },
      },
    };
  });
  const classes = useStyles();
  return (
    <div>
      <List
      className={`${classes.root} ${classes.head}`}
        bulkActionButtons={false}
        {...props}
        filters={<VaultConfigFilter />}
        pagination={<PostPagination />}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="coinId" />
          <NumberField source="minAmount" />
          <NumberField source="maxAmount" />
          <NumberField source="decimalVault" />
        </Datagrid>
      </List>
    </div>
  );
};

export default VaultConfigList;
