
import Chip from '@material-ui/core/Chip';
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
const useStyles = makeStyles({
    green: { backgroundColor: "green", borderRadius: "5px", color: "white", fontWeight: "bold" },
    red: { backgroundColor: "red", borderRadius: "5px", color: "white", fontWeight: "bold" },
    yellow: { backgroundColor: "yellow", borderRadius: "5px", color: "black", fontWeight: "bold" },
    one: { backgroundColor: "#20391e", borderRadius: "5px", color: "white", fontWeight: "bold" },
    two: { backgroundColor: "#417c3a", borderRadius: "5px", color: "white", fontWeight: "bold" },
    three: { backgroundColor: "#cb0e00", borderRadius: "5px", color: "white", fontWeight: "bold" },
    four: { backgroundColor: "#3f5e3f", borderRadius: "5px", color: "white", fontWeight: "bold" },
    five: { backgroundColor: "#609f1c", borderRadius: "5px", color: "white", fontWeight: "bold" },
    six: { backgroundColor: "#004677", borderRadius: "5px", color: "white", fontWeight: "bold" },
    seven: { backgroundColor: "#4c1130", borderRadius: "5px", color: "white", fontWeight: "bold" },
    eight: { backgroundColor: "#402717", borderRadius: "5px", color: "white", fontWeight: "bold" },
    nine: { backgroundColor: "#ff4136", borderRadius: "5px", color: "white", fontWeight: "bold" },
    zero: { backgroundColor: "#ff6347", borderRadius: "5px", color: "white", fontWeight: "bold" },

});


export const AuditColorChip = (props) => {
    const classes = useStyles();

    return (
        <Chip
            className={classnames({
                [classes.green]: props.type === "BID" || props.type === "CREDIT" || props.type === "REVERT",
                [classes.red]: props.type === "DEBIT" || props.type === "FREE",
                [classes.yellow]: props.type === "LOCK",
                [classes.one]: props.type?.endsWith("1"),
                [classes.two]: props.type?.endsWith("2"),
                [classes.three]: props.type?.endsWith("3"),
                [classes.four]: props.type?.endsWith("4"),
                [classes.five]: props.type?.endsWith("5"),
                [classes.six]: props.type?.endsWith("6"),
                [classes.seven]: props.type?.endsWith("7"),
                [classes.eight]: props.type?.endsWith("8"),
                [classes.nine]: props.type?.endsWith("9"),
                [classes.zero]: props.type?.endsWith("0")

            })}
            {...props}
        />
    );
};
