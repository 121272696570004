/* eslint-disable no-unused-vars */
import * as React from "react";
import {
  ArrayInput,
  Edit,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";

const FeeConfigEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="tier" />
      <NumberInput source="baseMakerFee" />
      <NumberInput source="baseTakerFee" />
      <ArrayInput source="feeTierList">
        <SimpleFormIterator>
          <TextInput source="tradeVolume30d" label="Trade Volume 30d" />
          <TextInput source="zeltaReserve" label="Zelta Reserve" />
          <TextInput source="baseMakerFee" label="Base Maker Fee" />
          <NumberInput source="baseTakerFee" label="Base Taker Fee" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export default FeeConfigEdit;
