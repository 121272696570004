/* eslint-disable no-unused-vars */
import * as React from "react";
import { NumberField, Show, SimpleShowLayout, TextField } from "react-admin";

const VaultConfigShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="coinId" />
      <NumberField source="minAmount" />
      <NumberField source="maxAmount" />
      <NumberField source="decimalVault" />
    </SimpleShowLayout>
  </Show>
);

export default VaultConfigShow;
