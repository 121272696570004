import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import { TextInput } from "react-admin";

const HistoryMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchCoin, setSearchCoin] = React.useState("");
  const open = Boolean(anchorEl);

  const handleStatusClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleStatusClose = (event) => {
    const { myValue } = event.currentTarget.dataset;
    props.setSelected(myValue || props.filterVal);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        style={{ minWidth: "150px" }}
        variant="outlined"
        color="primary"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleStatusClick}
      >
        {props.filterVal}
        <ExpandMoreIcon style={{ marginLeft: "auto" }} />
      </Button>
      <Menu
        style={{ maxHeight: "400px" }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleStatusClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          style: {
            padding: 0,
          },
        }}
      >
        {props.filter !== "status" && (
          <MenuItem
            style={{ padding: 0, position: "fixed", zIndex: 20 }}
            onKeyDown={(e) => e.stopPropagation()}
          >
            <input
              placeholder="Search"
              onChange={(e) => {
                setSearchCoin(e.target.value);
              }}
              style={{ height: "25px", width: "110px" }}
              type="text"
            />
          </MenuItem>
        )}
        {props?.menuArray
          ?.filter((item) => {
            if (props.filter == "status") {
              return true;
            } else {
             return item?.currencyShortName
                ?.toLowerCase()
                ?.includes(searchCoin.toLowerCase());
            }
          })
          .map((item, i) => {
            if (props.filter == "status") {
              return (
                <MenuItem
                  key={i}
                  data-my-value={item}
                  onClick={handleStatusClose}
                >
                  {item?.toUpperCase()}
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  key={i}
                  data-my-value={item.currencyShortName}
                  onClick={handleStatusClose}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    minWidth: "120px",
                    paddingTop:i==0?'35px':"5px"
                  }}
                >
                  {item.activeIconUrl?.length ? (
                    <img src={item.activeIconUrl} width={20} height={20} />
                  ) : (
                    <></>
                  )}{" "}
                  {item?.currencyShortName?.toUpperCase()}
                </MenuItem>
              );
            }
          })}
      </Menu>
    </div>
  );
};

export default HistoryMenu;
