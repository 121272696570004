/* eslint-disable no-unused-vars */
import * as React from "react";
import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

const RewardConfigShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="bonusFor" />
      <TextField source="bonusCode" />

      <TextField source="recordType" />
      <ArrayField source="directBonuses">
        <Datagrid>
          <ChipField source="baseCoinId" />
          <ChipField source="quoteCoinId" />
          <ChipField source="value" />
          <ChipField source="redemptionTradeValue" />
        </Datagrid>
      </ArrayField>

      <ArrayField source="referrerBonuses">
        <Datagrid>
          <ChipField source="baseCoinId" />
          <ChipField source="quoteCoinId" />
          <ChipField source="value" />
          <ChipField source="redemptionTradeValue" />
        </Datagrid>
      </ArrayField>

      <TextField source="tradeCoinId" />

      <TextField source="active" />

      <DateField source="startDate" showTime width="100%" />
      <DateField source="endDate" showTime width="100%" />
    </SimpleShowLayout>
  </Show>
);

export default RewardConfigShow;
