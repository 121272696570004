
import Grid from "@material-ui/core/Grid";
/* eslint-disable no-unused-vars */
import * as React from "react";
import {
  FilterList,
  FilterListItem,
} from "react-admin";





export const TradeStatusFilter = () => (
    <FilterList label="Trade Status" source="transferStatus">
        <Grid container spacing={1}>
            <Grid item>
                <FilterListItem label="ACTIVE" value={{ tradeStatus: "ACTIVE" }} />
            </Grid>
            <Grid item>
                <FilterListItem label="INACTIVE" value={{ tradeStatus: "INACTIVE" }} />
            </Grid>
            <Grid item>
                <FilterListItem label="PAUSED" value={{ tradeStatus: "PAUSED" }} />
            </Grid>
        </Grid>
    </FilterList>
);