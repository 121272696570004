import * as React from "react";
import {
    DateField,
    NumberField,
    Show,
    SimpleShowLayout,
    TextField,
} from "react-admin";

export const OrderShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout className="w-full md:flex flex-col overflow-auto flex-wrap gap-10 h-screen">
            <TextField source="status" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
            <TextField source="symbolId" />
            <TextField source="symbol" />
            <TextField source="action" />
            <TextField source="price" />
            <TextField source="userDetails.email" />
            <TextField source="id" />
            <TextField source="orderId" />
            <TextField source="originalQty" />
            <TextField source="executedQty" />
            <TextField source="orderId" />
            <TextField source="externalOrderId" />
            <TextField source="userId" />
            <TextField source="stopPrice" />
            <TextField source="stopLimitPrice" />
            <TextField source="lotSize" />
            <TextField source="baseScale" />
            <TextField source="quoteScale" />
            <TextField source="stepSize" />
            <TextField source="clientOrderId" />
            <TextField source="type" />
            <TextField source="icebergQty" />
            <NumberField source="orderRequest.price" />
            <TextField source="externalOrderStatus" />
            <TextField source="externalExchange" />
            <NumberField source="uid" />
            <TextField source="commissionDetails" />
            <TextField className="max-w-[300px] md:max-w-[400px] break-words block" source="tradeEvents" />
        </SimpleShowLayout>
    </Show>
);
