import LocalOffer from "@material-ui/icons/LocalOffer";
import RewardConfigCreate from "./rewardConfigCreate";
import RewardConfigEdit from "./rewardConfigEdit";
import RewardConfigList from "./rewardConfigList";
import RewardConfigShow from "./rewardConfigShow";

export default {
  list: RewardConfigList,
  edit: RewardConfigEdit,
  show: RewardConfigShow,
  create: RewardConfigCreate,
  icon: LocalOffer,
};
