/* eslint-disable no-unused-vars */
import { Box, Drawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@mui/material";
import Pagination1 from "@mui/material/Pagination";
import * as React from "react";
import {
    BooleanField,
    Datagrid,
    DateField,
    EmailField,
    ExportButton,
    FilterLiveSearch,
    List,
    Pagination,
    TextField,
    TopToolbar,
    useDataProvider
} from "react-admin";
import usermore from "../../../assets/usermore.svg";
import { localeDate } from "../../../helpers/localeDate";
import useResizer from "../../../utils/useResizer";
const ListActions = () => (
    <Box>
        <TopToolbar>
            <ExportButton />
        </TopToolbar>{" "}
    </Box>
);
const FilterSidebar = (props) => (
    <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: 200, marginRight: 20 }}>
            {" "}
            <FilterLiveSearch
                style={{ width: "200px" }}
                source="email"
                label="Search by Email"
            />{" "}
        </div>
        <div style={{ width: 200, marginRight: 20 }}>
            {" "}
            <FilterLiveSearch
                style={{ width: "200px" }}
                source="walletAddress"
                label="Search by Wallet Address"
            />{" "}
        </div>
        <div style={{ width: 200, marginRight: 20, fontSize: 12 }}>
            {" "}
            <FilterLiveSearch
                style={{ width: "200px" }}
                source="referralCode"
                label="Search by Referral Code"
            />{" "}
        </div>
        <div style={{ width: 200, marginRight: 20 }}>
            {" "}
            <FilterLiveSearch
                style={{ width: "200px" }}
                source="affiliateCode"
                label="Search by Affiliate Code"
            />{" "}
        </div>
        {/* <KycFilter source="status" /> */}
    </div>
);
const UserListMob = ({ props }) => {
    const dataProvider = useDataProvider();
    const [userData, setUserData] = React.useState();
    const [isOpen, setIsOpen] = React.useState(false);
    const [searchEmail, setSearchEmail] = React.useState("");
    const [searchCode, setSearchCode] = React.useState("");
    const [searchAffiliate, setSearchAffiliate] = React.useState("");
    const [searchAddress, setSearchAddress] = React.useState("");
    const [drawerData, setDrawerData] = React.useState({});
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [selectedPage, setSelectedPage] = React.useState(1);
    React.useEffect(async () => {
        fetchData(
            searchAddress,
            searchEmail,
            searchCode,
            searchAffiliate,
            selectedPage,
            rowsPerPage
        );
    }, [
        searchAddress,
        searchEmail,
        searchCode,
        searchAffiliate,
        selectedPage,
        rowsPerPage,
    ]);
    React.useEffect(() => {
        setSelectedPage(1);
    }, [searchAddress, searchEmail, searchCode, searchAffiliate]);
    async function fetchData(address, email, code, affiliate, page, rows) {
        let filterObj = {
            email,
            walletAddress: address,
            referralCode: code,
            affiliateCode: affiliate,
        };
        if (!address.length) {
            delete filterObj["walletAddress"];
        }
        if (!email.length) {
            delete filterObj["email"];
        }
        if (!code.length) {
            delete filterObj["referralCode"];
        }
        if (!affiliate.length) {
            delete filterObj["affiliateCode"];
        }
        try {
            let data = await dataProvider.getList("v1/admin/users", {
                filter: filterObj,
                pagination: { page: page, perPage: rows },
                sort: { field: "id", order: "ASC" },
                sort: { field: "id", order: "DESC" },
            });
            setUserData({ ...data });
        } catch (e) {
            console.log(e);
        }
    }
    const rowArray = [25, 50, 100, 200];
    console.log(userData, selectedPage);
    return (
        <>
            <div className="px-4 w-full">
                <div className="flex flex-wrap gap-3 my-4 justify-center">
                    <input
                        value={searchEmail}
                        onChange={(e) => {
                            setSearchEmail(e.target.value);
                        }}
                        type="text"
                        className="text-xs h-[30px] placeholder:text-black text-black placeholder:text-[10px] w-[45%] pl-2 border"
                        placeholder="Search by email"
                    />
                    <input
                        value={searchAddress}
                        type="text"
                        onChange={(e) => {
                            setSearchAddress(e.target.value);
                        }}
                        className="text-xs h-[30px] placeholder:text-black placeholder:text-[10px] w-[45%] pl-2 border"
                        placeholder="Search by wallet address"
                    />
                    <input
                        value={searchCode}
                        type="text"
                        onChange={(e) => {
                            setSearchCode(e.target.value);
                        }}
                        className="text-xs h-[30px] placeholder:text-black placeholder:text-[10px] w-[45%] pl-2 border"
                        placeholder="Search by referral code"
                    />
                    <input
                        value={searchAffiliate}
                        type="text"
                        onChange={(e) => {
                            setSearchAffiliate(e.target.value);
                        }}
                        className="text-xs h-[30px] placeholder:text-black placeholder:text-[10px] w-[45%] pl-2 border"
                        placeholder="Search by affiliate code"
                    />
                </div>
                <div>
                    {userData?.data?.map((item, i) => {
                        console.log(item);
                        return (
                            <div className="flex my-4 w-full pb-2 border-b border-b-[#ccc] justify-between">
                                <div className="w-full">
                                    <Link
                                        className="text-black no-underline"
                                        href={`/#/users/${item.id}/show`}
                                    >
                                        <div className="flex items-center w-full justify-between">
                                            <div className="w-1/2 break-words">
                                                <div className="mb-3">
                                                    <p className="text-xs">ID</p>
                                                    <p className="text-sm">{item.id}</p>
                                                </div>
                                                <div>
                                                    <p className="text-xs">Email</p>
                                                    <p className="text-sm">{item.email}</p>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-3">
                                                    <p className="text-xs">Created At</p>
                                                    <p className="text-sm">
                                                        {item.createdAt
                                                            ? localeDate(item.createdAt, "dateFormat")
                                                            : "-"}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-xs">Last Login</p>
                                                    <p className="text-sm">
                                                        {item.lastLogin
                                                            ? localeDate(item.lastLogin, "dateFormat")
                                                            : "-"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <p
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsOpen(true);
                                            setDrawerData(item);
                                        }}
                                        className="text-center opacity-70 gap-x-1 flex justify-center w-full items-center  mt-3 text-xs font-bold"
                                    >
                                        View More
                                        <img
                                            className="mt-[2px] opacity-70"
                                            alt="image"
                                            src={usermore}
                                        />
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div
                    className="mb-4"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 6,
                        alignItems: "center",
                        justifyContent: "start",
                    }}
                >
                    <Pagination1
                        style={{ paddingTop: "5px", paddingBottom: "5px" }}
                        count={Math.ceil(userData?.total / rowsPerPage)}
                        page={selectedPage}
                        onChange={(e, val) => {
                            setSelectedPage(val);
                        }}
                    />
                    <p className="text-sm">Rows per page</p>
                    <div
                        style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            gap: 4,
                        }}
                    >
                        {rowArray.map((item, i) => {
                            return (
                                <div
                                    onClick={() => {
                                        setRowsPerPage(item);
                                        setSelectedPage(1);
                                    }}
                                    style={{
                                        background:
                                            item == rowsPerPage ? "rgba(0, 0, 0, 0.08)" : "",
                                        width: "max-content",
                                        paddingRight: "5px",
                                        paddingLeft: "5px",
                                        borderRadius: "3px",
                                    }}
                                    key={i}
                                >
                                    {item}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <Drawer
                anchor={"bottom"}
                onClose={() => {
                    setIsOpen(false);
                }}
                open={isOpen}
            >
                <div
                    onClick={() => {
                        setIsOpen(false);
                    }}
                    className="w-full flex justify-center "
                >
                    <div className="bg-[#ccc] w-[30px] mt-2 h-[30px] flex justify-center items-center rounded-[50%]">
                        X
                    </div>
                </div>
                <div className="px-4 flex flex-col gap-y-3 my-4">
                    <div className="flex justify-between">
                        <p className="text-sm font-normal">Email verified:</p>
                        <p className="text-sm font-medium">
                            {drawerData?.emailVerified ? "Yes" : "No" || ""}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className="text-sm font-normal">Role:</p>
                        <p className="text-sm font-medium">{drawerData?.role || ""}</p>
                    </div>
                    <div className="flex justify-between">
                        <p className="text-sm font-normal">Referred by:</p>
                        <p className="text-sm font-medium">
                            {drawerData?.referredBy || ""}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className="text-sm font-normal">Referral code:</p>
                        <p className="text-sm font-medium">
                            {drawerData?.referralCode || ""}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className="text-sm font-normal">Active:</p>
                        <p className="text-sm font-medium">
                            {drawerData?.active ? "Yes" : "No" || ""}
                        </p>
                    </div>
                    <div className="flex justify-between">
                        <p className="text-sm font-normal">Flagged:</p>
                        <p className="text-sm font-medium">{drawerData?.flagged || ""}</p>
                    </div>
                </div>
            </Drawer>
        </>
    );
};
export const UserList = (props) => {
    const { width, height } = useResizer();
    const useStyles = makeStyles((theme) => {
        return {
            root: {
                "& .MuiFormLabel-root": {
                    fontSize: "12px",
                },
                "& .MuiToolbar-root": {
                    // flexDirection: "column-reverse",
                    width: "100%",
                },
                "& .MuiPaper-root": {
                    width: "350px",
                    overflow: "auto",
                    height: `${height - 165}px`,
                    [theme.breakpoints.down("768")]: {
                        height: "auto",
                    },
                    position: "static",
                },
                "& .MuiTablePagination-root": {
                    position: "absolute",
                    zIndex: 10,
                    bottom: 0,
                    [theme.breakpoints.down("768")]: {
                        bottom: "-50px",
                    },
                    right: 0,
                    left: 0,
                    display: "flex",
                },
            },
            head: {
                "& .MuiTableCell-head": {
                    fontWeight: 700,
                },
            },
        };
    });
    const classes = useStyles();
    return (
        <>
            <List
                {...props}
                className={`hidden ${classes.root} ${classes.head} md:block`}
                actions={<ListActions />}
                filters={<FilterSidebar />}
                bulkActionButtons={false}
                exporter={false}
                perPage={25}
                sort={{ field: "createdAt", order: "DESC" }}
                pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
            >
                <Datagrid rowClick="show">
                    <DateField source="createdAt" showTime />
                    <EmailField source="email" />
                    <TextField source="id" />
                    <BooleanField source="emailVerified" />
                    <TextField source="referredBy" />
                    <BooleanField source="active" />
                    <BooleanField source="flagged" />
                    <DateField source="lastLogin" showTime />
                </Datagrid>
            </List>
            <div className="md:hidden box-border">
                <UserListMob props={props} />
            </div>
        </>
    );
};