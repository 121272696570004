/* eslint-disable no-unused-vars */
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
import { localeDate } from "../../../../helpers/localeDate";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ActivityDetails = (props) => {
  const { activityDetails } = props;

  return (
    <TableContainer component={Paper}>
      <div className="h-[calc(100vh-170px)] block">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead  >
          <TableRow>
            <TableCell className="sticky top-0 bg-white "> Sl No</TableCell>
            <TableCell  className="sticky top-0 bg-white" > Login Time</TableCell>
            <TableCell  className="sticky top-0 bg-white"> Ip Address</TableCell>
            <TableCell  className="sticky top-0 bg-white"> Location</TableCell>
            <TableCell  className="sticky top-0 bg-white"> User agent</TableCell>
            <TableCell className="sticky top-0 bg-white"> Two fa type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activityDetails
            ? activityDetails.slice().map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell> {index + 1}</TableCell>
                  <TableCell>
                    {" "}
                    {row.loginTime
                      ? localeDate(row.loginTime, "dateFormat")
                      : "-"}
                  </TableCell>
                  <TableCell> {row.ipAddress}</TableCell>
                  <TableCell> {row.location}</TableCell>
                  <TableCell> {row.userAgent}</TableCell>
                  <TableCell> {row.twofaType}</TableCell>
                </TableRow>
              ))
            : "Loading"}
        </TableBody>
      </Table>
      </div>
    </TableContainer>
  );
};
