import {
  DateField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import useMediaQuery from "@mui/material/useMediaQuery";
export const WithdrawShow = (props) => {
  const isSmall = useMediaQuery("(max-width:768px)");
  return (
    <Show className="w-full" {...props}>
      <SimpleShowLayout className="md:flex flex-col  flex-wrap  overflow-auto gap-10 h-screen">
        <DateField showTime source="createdAt" />
        <TextField source="id" className="" />
        <TextField source="user.email" />
        <TextField source="currencyShortName" />
        <NumberField source="amount" />
        <TextField source="fee" />
        <TextField source="address" />
        <TextField source="addressTag" />
        <DateField showTime source="updatedAt" />
        <TextField source="transferType" />
        <TextField source="transferStatus" />
        <NumberField source="transferId" />
        <TextField source="feeCurrency" />
        <TextField source="txnId" />
        <TextField source="network" />
        <TextField source="tokenType" />
        <NumberField source="confirmation" />
        <NumberField source="requiredConfirmation" />
        <TextField source="rawData" />
        <TextField source="note" />
        <TextField source="internalNote" />
        <TextField source="gateway" />
      </SimpleShowLayout>
    </Show>
  );
};
