import { DateField, NumberField, ReferenceField, Show, SimpleShowLayout, TextField } from "react-admin";

export const AffiliateShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
             <TextField source="id"/>
             <TextField source="affiliateCode" />
            <ReferenceField source="userId" reference="users" link="show"><TextField source="email" /></ReferenceField>
            <TextField source="referralCommissionPercent" />
            <TextField source="vaultReferralCommissionPercent" />
        </SimpleShowLayout>
    </Show>
);