/* eslint-disable no-unused-vars */
import * as React from "react";
import { ArrayInput, BooleanInput, Create, NumberInput, SimpleForm, SimpleFormIterator, TextInput } from "react-admin";

const TaskReportCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="tierName" />
            <TextInput source="tierDescription" />
            <NumberInput source="level" />
            <TextInput source="levelName" />
            <BooleanInput source="kycComplete" />
            <ArrayInput source="taskIds">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>

            <NumberInput source="makerFee" />
            <NumberInput source="takerFee" />
            <NumberInput source="referralCommissionPercent" />
            <NumberInput source="vaultReferralCommissionPercent" />
            <NumberInput source="diamondsRequired" />
            <NumberInput source="zltRequired" />
        </SimpleForm>
    </Create>
);

export default TaskReportCreate;
