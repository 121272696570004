/* eslint-disable no-unused-vars */
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@mui/material/Pagination";
import * as React from "react";
import { useDataProvider } from "react-admin";

import {
    Create,
    NumberInput,
    SimpleForm,
    TextInput,
    useRefresh
} from "react-admin";
import DiamondHistoryMenu from "../../../../components/Menu/DiamondHistory";
import { localeDate } from "../../../../helpers/localeDate";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}));
const redirect = (basePath, id, data) => `/users/${data.userId}`;

const CompensationCreate = (props) => {
    return (
        <Create
            title="Create a Diamond Credit Record"
            {...props}
            resource="tier/diamonds"
            redirect={false}
        >
            <SimpleForm redirect={redirect}>
                <TextInput
                    source="userId"
                    variant="outlined"
                    placeholder="UserId"
                    defaultValue={props?.userId}
                />
                <NumberInput
                    source="diamonds"
                    variant="outlined"
                    placeholder="Diamonds"
                />
                <TextInput
                    source="note"
                    variant="outlined"
                    placeholder="Note"
                    defaultValue=""
                />
            </SimpleForm>
        </Create>
    );
};

function CompensationCreateFormDialog(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const refresh = useRefresh();

    const handleClose = () => {
        setOpen(false);
        // refresh();
    };
    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                disabled={props.flagged}
            >
                Crediit
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title"> Credit Diamonds</DialogTitle>
                <DialogContent>
                    <CompensationCreate {...props} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export const DiamondHistoric = (props) => {
    const dataProvider = useDataProvider();
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sideFilterVal, setSideFilterVal] = React.useState("All");
    const [noteFilterVal, setNoteFilterVal] = React.useState("All");
    const [credits, setCredits] = React.useState();
    const rowArray = [10, 20, 30];
    const sideArray = ["All", "Deposit", "Withdraw"];
    const noteArray = [
        "All",
        "Trade",
        "Streak",
        "Vault",
        "Task",
        "Trade_Commission",
        "Vault_Commission",
        "NFT_Transfer",
    ];
    console.log(sideFilterVal, noteFilterVal);
    React.useEffect(() => {
        getDiamonds(sideFilterVal, noteFilterVal, selectedPage, rowsPerPage);
    }, [selectedPage, rowsPerPage, sideFilterVal, noteFilterVal]);

    React.useEffect(() => {
        console.log("called use");
        setSelectedPage(1);
    }, [sideFilterVal, noteFilterVal]);
    function getDiamonds(side, note, page, rows) {
        dataProvider
            .getList("v1/admin/tier/diamondHistory", {
                filter: {
                    searchByUser: props.id,
                    note: note == "All" ? "" : note,
                    transferSide: side == "All" ? "" : side,
                },
                pagination: { page: page, perPage: rows },
                sort: { field: "createdAt", order: "DESC" },
            })
            .then((res) => {
                setCredits({ ...res });
                props.setLoading(false);
            })
            .catch(() => {
                props.setLoading(false);
            });
    }
    return (
        <TableContainer className="overflow-hidden" component={Paper}>
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <CompensationCreateFormDialog
                    basePath="/"
                    resource="tier/diamonds"
                    userId={props.id}
                />
                <p style={{ fontSize: "14px" }}>Side</p>
                <DiamondHistoryMenu
                    filterVal={sideFilterVal}
                    setSelected={setSideFilterVal}
                    menuArray={sideArray}
                />
                <p style={{ fontSize: "14px" }}>Note</p>
                <DiamondHistoryMenu
                    filterVal={noteFilterVal}
                    setSelected={setNoteFilterVal}
                    menuArray={noteArray}
                />
                <Button
                    style={{ marginLeft: "10px" }}
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        setSideFilterVal("All");
                        setNoteFilterVal("All");
                    }}
                >
                    Clear Filters
                </Button>
            </div>
            <div className="h-[calc(100vh-210px)] block overflow-auto">
                <Table sx={{ minWidth: 960 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="sticky top-0 bg-white "> Id</TableCell>
                            <TableCell className="sticky top-0 bg-white "> Diamonds</TableCell>
                            <TableCell className="sticky top-0 bg-white "> Note</TableCell>
                            <TableCell className="sticky top-0 bg-white "> TransferSide</TableCell>
                            <TableCell className="sticky top-0 bg-white "> Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {credits?.data?.length
                            ? credits.data.slice().map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell> {row.id}</TableCell>
                                    <TableCell> {row.diamonds}</TableCell>
                                    <TableCell> {row.note}</TableCell>
                                    <TableCell> {row.transferSide}</TableCell>
                                    <TableCell>
                                        {" "}
                                        {row?.creditedAt
                                            ? localeDate(row.creditedAt, "dateFormat")
                                            : "-"}
                                    </TableCell>
                                    {/* <TableCell>
                                  
                                </TableCell> */}
                                </TableRow>
                            ))
                            : "Loading"}
                    </TableBody>
                </Table>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                }}
                className="flex-col md:flex-row  md:gap-6"
            >
                <Pagination
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                    count={Math.ceil(credits?.total / rowsPerPage)}
                    page={selectedPage}
                    onChange={(e, val) => {
                        setSelectedPage(val);
                    }}
                />
                <p>Rows per page</p>
                <div
                    style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        gap: 4,
                    }}
                >
                    {rowArray.map((item, i) => {
                        return (
                            <div
                                onClick={() => {
                                    setRowsPerPage(item);

                                    setSelectedPage(1);
                                }}
                                style={{
                                    background: item == rowsPerPage ? "rgba(0, 0, 0, 0.08)" : "",
                                    width: "max-content",
                                    paddingRight: "5px",
                                    paddingLeft: "5px",
                                    borderRadius: "3px",
                                }}
                                key={i}
                            >
                                {item}
                            </div>
                        );
                    })}
                </div>
            </div>
        </TableContainer>
    );
};
