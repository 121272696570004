import RatesConfigCreate from "./ratesConfigCreate";
import RatesConfigEdit from "./ratesConfigEdit";
import RatesConfigList from "./ratesConfigList";
import RatesConfigShow from "./ratesConfigShow";

const resource = {
  list: RatesConfigList,
  edit: RatesConfigEdit,
  show: RatesConfigShow,
  create: RatesConfigCreate,
};

export default resource;
