import { EditGuesser, ShowGuesser } from "react-admin";
import { AggMarketList } from "./list";


const resource = {
    list: AggMarketList,
    show: ShowGuesser,
    edit: EditGuesser
};

export default resource;
