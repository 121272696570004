/* eslint-disable no-unused-vars */
import { Box } from "@material-ui/core";
import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button } from "@material-ui/core";
import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  ExportButton,
  FilterLiveSearch,
  List,
  Pagination,
  ShowButton,
  TextField,
  FunctionField,
  TopToolbar,
  SimpleList,
  TextInput,
} from "react-admin";
import { ColoredChipField } from "../../../helpers/colorChip";
import { StatusFilter, TypeFilter } from "../../filters/transferFilter";
import { makeStyles } from "@material-ui/core/styles";
import useResizer from "../../../utils/useResizer";

const ListActions = () => (
  <Box className="w-full flex justify-end md:block md:w-max">
    <TopToolbar className="md:flex gap-x-2 absolute items-center right-0 min-h-0 md:min-h-auto w-max flex md:flex-row">
      <CreateButton className="min-w-max  p-0" label="" />
      <ExportButton
        className="self-end min-w-max p-0 hidden md:block"
        label=""
      />
    </TopToolbar>
  </Box>
);

const SearchEmail = React.memo(() => {
  return;
});
const FilterSidebar = ({ searchEmail, setSearchEmail,setFilters }) => {
  const useStyles = makeStyles((theme) => {
    return {
      root: {
        "& .MuiFormLabel-root": {
          fontSize: "12px",
        },
        "& .MuiToolbar-root": {
          // flexDirection: "column-reverse",
          width: "100%",
        },
        "& .MuiPaper-root": {
          width: "350px",
          overflow: "auto",
          position: "static",
        },
        "& .MuiTablePagination-root": {
          position: "absolute",
          zIndex: 10,
          bottom: 0,
          right: 0,
          left: 0,
          display: "flex",
        },
      },
    };
  });
  const classes = useStyles();

  const isSmall = useMediaQuery("(max-width:768px)");
  return (
    <div className=" min-w-full  md:min-w-full block">
      <div className="flex m md:w-full md:block items-center flex-wrap">
        <FilterLiveSearch
          className={`w-[45%] md:w-auto ${isSmall && classes.root}`}
          source="_id"
          label="Search by ID"
        />{" "}
        &nbsp;&nbsp;&nbsp;
        <FilterLiveSearch
          className={`w-[45%] md:w-auto ${isSmall && classes.root}`}
          source="currencyShortName"
          label="Currency"
        />
        &nbsp;&nbsp;&nbsp;
        <FilterLiveSearch
          className={`w-[45%] md:w-auto ${isSmall && classes.root}`}
          source="network"
          label="Network"
        />{" "}
        <input
          type="text"
          className=" mr-3 ml-2 pl-2 py-2 mt-3  border border-black"
          placeholder="Search email"
          value={searchEmail}
          onChange={(e) => {
            setSearchEmail(e.target.value);
          }}
        />
        <br />
      </div>
      <StatusFilter />
      <div className="flex flex-col md:flex-row md:items-center w-full justify-between">
        <TypeFilter />
        <Button
          className=" w-max "
          id="clearButton"
          variant="outlined"
          onClick={() => setFilters({})}
        >
          Clear fields
        </Button>
      </div>
    </div>
  );
};

export const DepositList = (props) => {
  const { width, height } = useResizer();
  const [searchEmail, setSearchEmail] = React.useState("");
  const useStyles = makeStyles((theme) => {
    return {
      root: {
        "& .MuiFormLabel-root": {
          fontSize: "12px",
        },
        "& .MuiToolbar-root": {
          // flexDirection: "column-reverse",
          width: "100%",
        },
        "& .MuiPaper-root": {
          width: "350px",
          overflow: "auto",
          height: `${height - 320}px`,
          [theme.breakpoints.down("768")]: {
            height: "auto",
          },
          position: "static",
        },
        "& .MuiTablePagination-root": {
          position: "absolute",
          zIndex: 10,
          bottom: 0,
          [theme.breakpoints.down("768")]: {
            bottom: "-50px",
          },
          right: 0,
          left: 0,
          display: "flex",
        },
      },
      head: {
        "& .MuiTableCell-head": {
          fontWeight: 700,
        },
      },
    };
  });
  const classes = useStyles();
  const isSmall = useMediaQuery("(max-width:768px)");
  return (
    <List
      className={`${classes.root} ${classes.head} overflow-x-hidden`}
      {...props}
      actions={<ListActions />}
      perPage={25}
      bulkActionButtons={false}
      pagination={<Pagination rowsPerPageOptions={[25, 100, 150, 200, 250]} />}
      filters={
        <FilterSidebar
          searchEmail={searchEmail}
          setSearchEmail={setSearchEmail}
        />
      }
      filter={{ "user.email": searchEmail }}
      sort={{ field: "transferId", order: "DESC" }}
    >
      <Datagrid rowClick="show">
        <DateField source="createdAt" showTime />
        <TextField source="currencyShortName" label="Currency" />
        <TextField source="amount" />
        <FunctionField
          onClick={(e) => {
            e.stopPropagation();
          }}
          label="Email"
          render={(record) => {
            return (
              <a
                style={{ color: "#000" }}
                href={"/#/users/" + record.userId + "/show"}
              >
                {" "}
                {record?.user?.email}{" "}
              </a>
            );
          }}
        />

        <TextField source="address" />
        <TextField source="addressTag" />
        <TextField source="transferType" />
        <ColoredChipField source="transferStatus" />
        <BooleanField source="fromDifferentNetwork" />
        <TextField source="transferId" />
      </Datagrid>
    </List>
  );
};
