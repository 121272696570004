/* eslint-disable no-unused-vars */
import * as React from "react";
import { useState } from "react";
import {
    Datagrid,
    Filter,
    List,
    NumberField,
    Pagination,
    SearchInput,
    TextField,
    useDataProvider,
    useRefresh
} from "react-admin";

const TaskConfigFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="searchId" alwaysOn />
    </Filter>
);
const PostPagination = (props) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const TaskConfigList = (props) => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const refresh = useRefresh();

    return (
        <div>
            <List
                bulkActionButtons={false}
                {...props}
                filters={<TaskConfigFilter />}
                pagination={<PostPagination />}
            >
                <Datagrid rowClick="edit">
                    <TextField source="id" />
                    <TextField source="taskType" />
                    <TextField source="taskName" />
                    <NumberField source="diamondReward" />
                    <NumberField source="zeltaReward" />
                    {/* <NumberField source="expiryDate" /> */}
                </Datagrid>
            </List>
        </div>
    );
};

export default TaskConfigList;
