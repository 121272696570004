import * as React from "react";
import {
    DateField, FunctionField, Show,
    SimpleShowLayout,
    TextField
} from "react-admin";
export const AdminauditShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="email" />
            <TextField source="phonenumber" />
            <TextField source="action" />
            <TextField source="module" />
            <FunctionField source="oldState" render={(record) => { return <div><pre>{JSON.stringify(JSON.parse(record.oldState), null, 2)}</pre></div> }} />
            <FunctionField source="newState" render={(record) => { return <div><pre>{JSON.stringify(JSON.parse(record.newState), null, 2)}</pre></div> }} />
            <TextField source="adminId" />
            <TextField source="recordId" />
            <TextField source="affectedUserId" />
            <TextField source="affectedUserEmail" />
            <TextField source="note" />
            <DateField source="createdAt" />
        </SimpleShowLayout>
    </Show>
);