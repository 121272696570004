/* eslint-disable no-unused-vars */
import * as React from "react";
import { DateField, NumberField, RichTextField, Show, SimpleShowLayout, TextField } from "react-admin";

const TaskConfigShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />

            <TextField source="taskName" />
            <RichTextField source="task.taskDetails" />
            <RichTextField source="task.taskTerms" />
            <TextField source="task" />
            <TextField source="taskType" />

            <NumberField source="diamondReward" />
            <TextField source="zeltaReward" />
            <DateField source="expiryDate" />
        </SimpleShowLayout>
    </Show>
);

export default TaskConfigShow;
