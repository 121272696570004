import React, { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { BASE_URL } from "../../../config/env";

export default function PendingActions(props) {
  const dataProvider = useDataProvider();
  const [data, setData] = useState();
  useEffect(async () => {
    let token = localStorage.getItem("token");
    try {
      let data = await fetch(
        `${BASE_URL}/v1/admin/pendingStatus`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      let res = await data.json();
      setData([res]);
    } catch (e) {
      console.log(e);
    }
  }, []);
  console.log(data)
  return (
    <div className="h-[calc(100vh-220px)] block overflow-auto">
      <Table sx={{ minWidth: 1024 }} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell className="sticky top-0 bg-white "> S.No</TableCell>
            <TableCell className="sticky top-0 bg-white "> Pending Tasks Count</TableCell>
            <TableCell className="sticky top-0 bg-white "> Pending Withdraws Count</TableCell>
            <TableCell className="sticky top-0 bg-white ">
            Unresolved Incident Count
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ?data.map((item, index) => (
                <TableRow key={index}>
                    <TableCell> 
                        {index+1}
                    </TableCell>
                  <TableCell> {item.pendingTasksCount}</TableCell>
                  <TableCell> {item.pendingWithdrawsCount}</TableCell>
                  <TableCell> {item.unresolvedIncidentCount}</TableCell>
                </TableRow>
              ))
            : "Loading"}
        </TableBody>
      </Table>
    </div>
  );
}
