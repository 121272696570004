import decodeJwt from "jwt-decode";
import { BASE_URL } from "../config/env";

const authProvider = {
    loginOtp: ({ login, password }) => {
        const request = new Request(BASE_URL + "/v1/loginOtp", {
            method: "POST",
            body: JSON.stringify({ login, password }),
            //   credentials: "include",
            headers: new Headers({ "Content-Type": "application/json" }),
        });
        return fetch(request).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        });
    },

    login: ({ login, password, verificationCode }) => {
        const request = new Request(BASE_URL + "/v1/authenticate", {
            method: "POST",
            body: JSON.stringify({ login, password, verificationCode }),
            //   credentials: "include",
            headers: new Headers({ "Content-Type": "application/json" }),
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ idToken, refreshToken }) => {
                const decodedToken = decodeJwt(idToken);
                localStorage.setItem("token", idToken);
                localStorage.setItem("role", decodedToken.auth);
            });
    },

    logout: () => {
        localStorage.removeItem("token");
        return Promise.resolve();
    },

    checkAuth: () => {
        return localStorage.getItem("token") ? Promise.resolve() : Promise.reject()
    },

    checkError: (error) => {
        const status = error.status;
        if (status === 401) {
            localStorage.removeItem("token");
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        const role = localStorage.getItem("role");
        return role ? Promise.resolve(role.split(",")) : Promise.reject();
    },
};

export default authProvider;
