/* eslint-disable no-unused-vars */
import * as React from "react";
import { Edit, SelectInput, SimpleForm, TextInput } from "react-admin";

const AdminMailConfigEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <SelectInput
        source="provider"
        variant="outlined"
        choices={[
          { id: 0, name: "SENDINBLUE" },
          { id: 1, name: "SES" },
          { id: 2, name: "POSTMARK" },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export default AdminMailConfigEdit;
