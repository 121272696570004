import { Datagrid, List, TextField } from "react-admin";

export const ErrorOrderList = (props) => {
  return (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="zeltaOrderId" label={"Zelta Order Id"} />
      <TextField source="binanceOrderId" label={"Binance Order Id"} />
      <TextField source="errorMessage" label={"Error Message"} />
    </Datagrid>
  </List>
)};
