import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
/* eslint-disable no-unused-vars */
import { Drawer } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";
import {
    ChipField,
    Datagrid,
    DateField,
    DeleteButton,
    FilterLiveSearch,
    FunctionField,
    List,
    NumberField,
    TextField,
} from "react-admin";
import { localeDate } from "../../../helpers/localeDate";
import useResizer from "../../../utils/useResizer";
import { StatusFilter, TenureFilter } from "../../filters/vaultFilter";

const useStyles = makeStyles({
    matured: { backgroundColor: "green", borderRadius: "5px", color: "white" },
    cancelled: { backgroundColor: "red", borderRadius: "5px", color: "white" },
    in_vault: { backgroundColor: "blue", borderRadius: "5px", color: "white" },
    root: {
        "& .MuiFormLabel-root": {
            fontSize: "12px",
        },
        "& .MuiToolbar-root": {
            flexDirection: "column-reverse",
            width: "100%",
        },
        "& .MuiPaper-root": {
            width: "350px",
            overflow: "auto",
        },
    },
});

const FilterSidebar = (props) => {
    const classes = useStyles();
    const isSmall = useMediaQuery("(max-width:768px)");
    return (
        <div>
            {" "}
            <FilterLiveSearch
                className={`w-[45%] md:w-auto ${isSmall && classes.root}`}
                source="coinId"
                label="Search by Coin"
            />{" "}
            &nbsp;&nbsp;&nbsp;
            <FilterLiveSearch
                className={`w-[45%] md:w-auto ${isSmall && classes.root}`}
                source="searchByUser"
                label="Search by User details"
            />{" "}
            <br />

            <br />
            <div className="flex flex-col md:flex-row justify-between items-center">
                <StatusFilter source="status" />
                <TenureFilter source="window" />
                <Button
                    className="my-2"
                    id="clearButton"
                    variant="outlined"
                    onClick={() => props.setFilters({})}
                >
                    Clear fields
                </Button>
            </div>
        </div>
    );
};

const ColoredChipField = (props) => {
    const classes = useStyles();

    const isMatured = (v) => v.toUpperCase() === "MATURED";
    const isInVault = (v) => v.toUpperCase() === "IN_VAULT";
    const isCancelled = (v) => v.toUpperCase() === "CANCELLED";

    return (
        <ChipField
            className={classnames({
                [classes.matured]: isMatured(props.record[props.source]),
                [classes.in_vault]: isInVault(props.record[props.source]),
                [classes.cancelled]: isCancelled(props.record[props.source]),
            })}
            {...props}
        />
    );
};

const VaultList = (props) => {
    const { width, height } = useResizer();
    const useStyles = makeStyles((theme) => {
        return {
            matured: {
                backgroundColor: "green",
                borderRadius: "5px",
                color: "white",
            },
            cancelled: {
                backgroundColor: "red",
                borderRadius: "5px",
                color: "white",
            },
            in_vault: {
                backgroundColor: "blue",
                borderRadius: "5px",
                color: "white",
            },
            root: {
                "& .MuiFormLabel-root": {
                    fontSize: "12px",
                },
                "& .MuiToolbar-root": {
                    flexDirection: "column-reverse",
                    width: "100%",
                },
                "& .MuiPaper-root": {
                    width: "350px",
                    overflow: "auto",
                    height: `${height - 320}px`,
                    [theme.breakpoints.down("768")]: {
                        height: "auto",
                    },
                    position: "static",
                },
                "& .MuiTablePagination-root": {
                    position: "absolute",
                    zIndex: 10,
                    bottom: 0,
                    [theme.breakpoints.down("768")]: {
                        bottom: "-50px",
                    },
                    right: 0,
                    left: 0,
                    display: "flex",
                },
            },
            head: {
                "& .MuiTableCell-head": {
                    fontWeight: 700,
                },
            },
        };
    });
    const classes = useStyles();
    const [isOpen, setIsOpen] = React.useState(false);
    const [drawerData, setDrawerData] = React.useState();
    const isSmall = useMediaQuery("(max-width:768px)");

    return (
        <>
            <List
                className={`${classes.root} ${classes.head} overflow-x-hidden`}
                bulkActionButtons={false}
                {...props}
                perPage={25}
                sort={{ field: "createdAt", order: "DESC" }}
                filters={<FilterSidebar />}

                isInVault
            >
                <Datagrid
                    rowClick={(id, resource, record) => {
                        if (isSmall) {
                            setIsOpen(true);
                            setDrawerData(record);
                        }
                    }}
                >
                    <DateField source="createdAt" showTime />
                    <DateField source="startDate" showTime />
                    <DateField source="maturityDate" showTime />
                    <DateField source="updatedAt" showTime />
                    <FunctionField
                        label="Email"
                        render={(record) => {
                            return (
                                <a
                                    style={{ color: "#000" }}
                                    href={"/#/users/" + record.userId + "/show"}
                                >
                                    {" "}
                                    {record.email}{" "}
                                </a>
                            );
                        }}
                    />
                    <TextField source="coinId" />
                    <NumberField source="rate" options={{ maximumFractionDigits: 2 }} />
                    <NumberField
                        source="principal"
                        options={{ maximumFractionDigits: 6 }}
                    />
                    <TextField source="interestEarned" />
                    <ColoredChipField source="status" label="Status" />
                    <FunctionField source="autoRenew" render={(record) => {
                        return (
                            record.autoRenew.replace("RENEW_", "").replace("_RENEW", "").replace("PRINCIPAL_AND_INTEREST", "PRINCIPAL+INT")
                        );
                    }} label="Auto Renew" />
                    <TextField source="tenure" />
                    <DeleteButton label="Cancel" />

                </Datagrid>
            </List>

            <Drawer
                anchor={"bottom"}
                onClose={() => {
                    setIsOpen(false);
                }}
                open={isOpen}
            >
                <div className="p-3 flex flex-col gap-y-3">
                    <div
                        onClick={() => {
                            setIsOpen(false);
                        }}
                        className="w-full flex justify-center "
                    >
                        <div className="bg-[#ccc] w-[30px] mt-0 h-[30px] flex justify-center items-center rounded-[50%]">
                            X
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Created at:</p>
                        <p className="font-semibold">
                            {localeDate(drawerData?.createdAt, "dateFormat") || ""}
                        </p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Start date:</p>
                        <p className="font-semibold">
                            {localeDate(drawerData?.startDate, "dateFormat") || ""}
                        </p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Maturity date:</p>
                        <p className="font-semibold">
                            {localeDate(drawerData?.maturityDate, "dateFormat") || ""}
                        </p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Email:</p>
                        <p className="font-semibold">{drawerData?.email || ""}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>User name:</p>
                        <p className="font-semibold">
                            {(drawerData?.firstname || "") +
                                " " +
                                (drawerData?.firstname || "")}
                        </p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Coin id:</p>
                        <p className="font-semibold">{drawerData?.coinId || ""}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Rate:</p>
                        <p className="font-semibold">{drawerData?.rate || 0}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Principal:</p>
                        <p className="font-semibold">{drawerData?.principal || 0}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Interest earned:</p>
                        <p className="font-semibold">{drawerData?.interestEarned || 0}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Status:</p>
                        <p className="font-semibold">{drawerData?.status}</p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Tenure:</p>
                        <p className=" w-1/2 break-words font-semibold">
                            {drawerData?.tenure || ""}
                        </p>
                    </div>
                    <div className="flex justify-between items-center">
                        <p>Updated at:</p>
                        <p className=" font-semibold">
                            {localeDate(drawerData?.updatedAt, "dateFormat") || ""}
                        </p>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default VaultList;
