/* eslint-disable no-unused-vars */
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";

import {
  Create,
  DateTimeInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useRefresh,
} from "react-admin";
import { localeDate } from "../../../../helpers/localeDate";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));
const redirect = (basePath, id, data) => `/users/${data.userId}`;

const CompensationCreate = (props) => {
  const [type, setType] = React.useState();

  return (
    <Create
      title="Create a Compensation Record"
      {...props}
      resource="orderbook/compensationOrder"
    >
      <SimpleForm redirect={redirect}>
        <TextInput source="symbol" variant="outlined" placeholder="Symbol" />
        <NumberInput source="price" variant="outlined" placeholder="Price" />
        <NumberInput
          source="quantity"
          variant="outlined"
          placeholder="Quantity"
        />
        <SelectInput
          source="status"
          variant="outlined"
          choices={[
            { id: "FILLED", name: "FILLED" },
            { id: "FAILED", name: "FAILED" },
          ]}
          defaultValue="FILLED"
        />

        <SelectInput
          source="action"
          variant="outlined"
          choices={[
            { id: "ASK", name: "ASK" },
            { id: "BID", name: "BID" },
          ]}
          defaultValue="BID"
        />

        <SelectInput
          source="orderType"
          variant="outlined"
          choices={[
            { id: "GTC", name: "LIMIT" },
            { id: "IOC", name: "MARKET" },
          ]}
          defaultValue="IOC"
        />

        <TextInput
          source="note"
          variant="outlined"
          placeholder="Note"
          defaultValue=""
        />
        <TextInput
          source="userId"
          variant="outlined"
          placeholder="User Id"
          defaultValue={props.userId}
        />
        <DateTimeInput
          source="createdAt"
          defaultValue={null}
          parse={(v) => {
            return v;
          }}
          format={(v) => {
            return v;
          }}
        />
      </SimpleForm>
    </Create>
  );
};

function CompensationCreateFormDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const refresh = useRefresh();

  const handleClose = () => {
    setOpen(false);
    // refresh();
  };

  console.log(props);
  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        disabled={props.flagged}
      >
        Compensate
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"> Compensate</DialogTitle>
        <DialogContent>
          <CompensationCreate {...props} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const OpenOrders = (props) => {
  const { openOrders } = props;

  return (
    <TableContainer component={Paper}>
      <CompensationCreateFormDialog
        basePath="/"
        resource="orderbook/compensationOrder"
        userId={props.id}
      />
      <Table sx={{ minWidth: 1024 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell> Order Id</TableCell>
            <TableCell> Symbol</TableCell>
            <TableCell>Side</TableCell>
            <TableCell> Orignal Qty</TableCell>
            <TableCell> Executed Qty</TableCell>
            <TableCell> Orignal Price</TableCell>
            <TableCell> Status</TableCell>
            <TableCell> Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {openOrders
            ? openOrders.slice().map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell> {row.orderId}</TableCell>
                  <TableCell> {row.symbol}</TableCell>
                  <TableCell>{row.action?.toLowerCase()=="ask"?"Sell":"Buy"}</TableCell>
                  <TableCell> {row.origQty}</TableCell>
                  <TableCell> {row.execQty}</TableCell>
                  <TableCell> {row.origPrice}</TableCell>
                  <TableCell> {row.status}</TableCell>
                  <TableCell>
                    {" "}
                    {row?.updatedAt ? localeDate(row.updatedAt,"dateFormat") : "-"}
                  </TableCell>
                  {/* <TableCell>
                                  
                                </TableCell> */}
                </TableRow>
              ))
            : "Loading"}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
