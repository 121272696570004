import { SvgIcon } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import * as React from "react";

export const icons = (name) => {
  switch (name) {
    case "email":
      return (
        <SvgIcon viewBox="0 0 24 24">
          <rect
            opacity="0.24"
            width="24"
            height="24"
            rx="12"
            fill="black"
            fillOpacity="0.87"
          />
          <g opacity="0.8">
            <path
              d="M13.2146 17H11.5604V9.00879L9.11992 9.84277V8.44141L13.0027 7.0127H13.2146V17Z"
              fill="white"
            />
          </g>
        </SvgIcon>
      );
    case "phone":
      return (
        <SvgIcon viewBox="0 0 24 24">
          <rect
            opacity="0.24"
            width="24"
            height="24"
            rx="12"
            fill="black"
            fillOpacity="0.87"
          />
          <g opacity="0.8">
            <path
              d="M15.409 17H8.7166V15.8584L12.032 12.2422C12.5105 11.709 12.8501 11.2646 13.0506 10.9092C13.2557 10.5492 13.3582 10.1891 13.3582 9.8291C13.3582 9.35514 13.2238 8.97233 12.9549 8.68066C12.6906 8.389 12.3328 8.24316 11.8816 8.24316C11.3439 8.24316 10.9269 8.40723 10.6307 8.73535C10.3344 9.06348 10.1863 9.51237 10.1863 10.082H8.5252C8.5252 9.47591 8.66191 8.93132 8.93535 8.44824C9.21335 7.96061 9.60755 7.58236 10.118 7.31348C10.6329 7.0446 11.2254 6.91016 11.8953 6.91016C12.8615 6.91016 13.6225 7.15397 14.1785 7.6416C14.7391 8.12467 15.0193 8.7946 15.0193 9.65137C15.0193 10.1481 14.8781 10.6699 14.5955 11.2168C14.3175 11.7591 13.8595 12.3766 13.2215 13.0693L10.7879 15.6738H15.409V17Z"
              fill="white"
            />
          </g>
        </SvgIcon>
      );
    case "document":
      return (
        <SvgIcon viewBox="0 0 24 24">
          <rect
            opacity="0.24"
            width="24"
            height="24"
            rx="12"
            fill="black"
            fillOpacity="0.87"
          />
          <g opacity="0.8">
          <path
              d="M15.409 17H8.7166V15.8584L12.032 12.2422C12.5105 11.709 12.8501 11.2646 13.0506 10.9092C13.2557 10.5492 13.3582 10.1891 13.3582 9.8291C13.3582 9.35514 13.2238 8.97233 12.9549 8.68066C12.6906 8.389 12.3328 8.24316 11.8816 8.24316C11.3439 8.24316 10.9269 8.40723 10.6307 8.73535C10.3344 9.06348 10.1863 9.51237 10.1863 10.082H8.5252C8.5252 9.47591 8.66191 8.93132 8.93535 8.44824C9.21335 7.96061 9.60755 7.58236 10.118 7.31348C10.6329 7.0446 11.2254 6.91016 11.8953 6.91016C12.8615 6.91016 13.6225 7.15397 14.1785 7.6416C14.7391 8.12467 15.0193 8.7946 15.0193 9.65137C15.0193 10.1481 14.8781 10.6699 14.5955 11.2168C14.3175 11.7591 13.8595 12.3766 13.2215 13.0693L10.7879 15.6738H15.409V17Z"
              fill="white"
            />
          </g>
        </SvgIcon>
      );
    case "success":
      return (
        <CheckCircle style={{ color: "#00A41A", height: 28, width: 28 }} />
      );
    default:
      return <SvgIcon />;
  }
};
