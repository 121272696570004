import { TransferConfigCreate } from "./create";
import { TransferConfigEdit } from "./edit";
import TransferconfigList from "./list";
const resource = {
    create: TransferConfigCreate,
    list: TransferconfigList,
    edit: TransferConfigEdit,
};

export default resource;
