import { Icon } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import DnsIcon from "@material-ui/icons/Dns";
import GroupIcon from "@material-ui/icons/Group";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import NotificationsIcon from "@material-ui/icons/Notifications";
import TimelineIcon from "@material-ui/icons/Timeline";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import "font-awesome/css/font-awesome.css";
import {
    Admin,
    fetchUtils,
    ListGuesser,
    Resource,
    usePermissions
} from "react-admin";
import "./App.css";
import CustomLoginPage from "./auth/Login";
import { BASE_URL } from "./config/env";
import { themeMain } from "./config/theme";
import MainLayout from "./layouts";
import auditTrail from "./pages/accounting/auditTrial";
import cryptoBalances from "./pages/accounting/cryptoBalances";
import deposits from "./pages/accounting/deposits";
import operations from "./pages/accounting/operations";
import totalBalance from "./pages/accounting/totalBalances";
import transferConfig from "./pages/accounting/transferConfig";
import withdraws from "./pages/accounting/withdrawals";
import aggMarkets from "./pages/aggregated/markets";
import aggOrders from "./pages/aggregated/orders";
import aggTransferRoutes from "./pages/aggregated/transferRoutes";
import aggTransfers from "./pages/aggregated/transfers";
import { BulkOrderList } from "./pages/custom/bulkOrders/markets/list";
import affiliates from "./pages/exchange/affiliates";
import currencies from "./pages/exchange/currencies";
import errorOrders from "./pages/exchange/errorOrders";
import feeConfig from "./pages/exchange/feeConfig";
import mailConfig from "./pages/exchange/mailConfig";
import markets from "./pages/exchange/markets";
import offsets from "./pages/exchange/offset";
import orderBook from "./pages/exchange/orderbook";
import orders from "./pages/exchange/orders";
import rates from "./pages/exchange/ratesConfig";
import siteUpdate from "./pages/exchange/siteUpdate";
import streakLeaderboard from "./pages/exchange/streakLeaderboard";
import taskConfig from "./pages/exchange/taskConfig";
import taskReport from "./pages/exchange/taskReports";
import tierConfig from "./pages/exchange/tierConfig";
import trades from "./pages/exchange/trades";
import tradingView from "./pages/exchange/trading_view";
import vaultConfig from "./pages/exchange/vaultConfig";
import vaults from "./pages/exchange/vaults";
import CommunityDirectory from "./pages/users/community-directory";
import CommunityItem from "./pages/users/communityitem";
import CommunityList from "./pages/users/communitylist";
import incidents from "./pages/users/incidents";
import adminMailConfig from "./pages/users/mailConfig";
import metrics from "./pages/users/metrics";
import { NotitificationCreate } from "./pages/users/notifications/create";
import { NotificationList } from "./pages/users/notifications/list";
import PendingActions from "./pages/users/pending-actions";
import rewardConfig from "./pages/users/rewardConfig";
import users from "./pages/users/users-directory";
import authProvider from "./providers/authProvider";
import jsonRestProvider from "./providers/data-springboot-rest";

const theme = createTheme(themeMain);

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }

    const token = localStorage.getItem("token");
    options.headers.set("Authorization", `Bearer ${token}`);
    options.headers.set("Access-Control-Expose-Headers", "X-Total-Count");
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonRestProvider(BASE_URL, httpClient);

const App = () => {
    const { loading, permissions } = usePermissions();
    return loading ? (
        <div>Waiting for permissions...</div>
    ) : (
        <>
            <Admin
                loginPage={CustomLoginPage}
                authProvider={authProvider}
                theme={theme}
                layout={MainLayout}
                dataProvider={dataProvider}
            >
                {(permissions) => [
                    <Resource
                        name="most-used"
                        options={{ label: "Most used", isMenuParent: true }}
                        icon={GroupIcon}
                        isOpen={true}
                    />,
                    <Resource
                        name="pendingStatus"
                        options={{ label: "Pending Actions", menuParent: "most-used" }}
                        list={PendingActions}
                    />,
                    isMarketing(permissions) ? (
                        <Resource
                            name="v1/admin/users"
                            options={{ label: "Users directory", menuParent: "most-used" }}
                            icon={GroupIcon}
                            {...users}
                        />
                    ) : null,
                    isExecutive(permissions) ? (
                        <Resource
                            name="v1/admin/incidents"
                            options={{ label: "Incident Report", menuParent: "most-used" }}
                            icon={() => <Icon className="fa fa-exclamation-triangle" />}
                            {...incidents}
                        />
                    ) : null,
                    isStaff(permissions) ? (
                        <Resource
                            name="v1/admin/funds/deposit"
                            options={{ label: "Deposits", menuParent: "most-used" }}
                            icon={() => <Icon className="fa fa-download" />}
                            {...deposits}
                        />
                    ) : null,
                    isStaff(permissions) ? (
                        <Resource
                            name="v1/admin/funds/withdraw"
                            options={{ label: "Withdrawals", menuParent: "most-used" }}
                            icon={() => <Icon className="fa fa-upload" />}
                            {...withdraws}
                        />
                    ) : null,
                    isStaff(permissions) ? (
                        <Resource
                            name="v1/admin/orderbook/orders"
                            options={{ label: "Orders", menuParent: "most-used" }}
                            icon={DnsIcon}
                            {...orders}
                        />
                    ) : null,
                    isStaff(permissions) ? (
                        <Resource
                            name="v1/admin/orderbook/trades"
                            options={{ label: "Trades", menuParent: "most-used" }}
                            icon={ClearAllIcon}
                            list={trades.list}
                        // show={ShowGuesser}
                        />
                    ) : null,
                    isStaff(permissions) ? (
                        <Resource
                            name="v1/admin/vaults/list"
                            options={{ label: "Vaults List", menuParent: "most-used" }}
                            icon={() => <Icon className="fa fa-list-alt" />}
                            {...vaults}
                        />
                    ) : null,
                    isMarketing(permissions) ? (
                        <Resource
                            name="v1/admin/tier/taskReviews"
                            options={{ label: "Task Reviews", menuParent: "most-used" }}
                            icon={() => <Icon className="fa fa-sliders" />}
                            {...taskReport}
                        />
                    ) : null,
                    isExecutive(permissions) ? (
                        <Resource
                            name="v1/admin/orderbook/cumulativeOrderBook"
                            options={{ label: "Order Book", menuParent: "most-used" }}
                            icon={DnsIcon}
                            {...orderBook}
                        />
                    ) : null,
                    isExecutive(permissions) ? (
                        <Resource
                            name="v1/admin/auditTrail"
                            options={{
                                label: "Global audit trail",
                                menuParent: "most-used",
                            }}
                            icon={() => <Icon className="fa fa-tty" />}
                            {...auditTrail}
                        />
                    ) : null,

                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/bulkOrders"
                            style={{ backgroundColor: "red" }}
                            options={{ label: "Bulk Orders", menuParent: "most-used" }}
                            list={BulkOrderList}
                        />
                    ) : null,


                    <Resource
                        name="user-block"
                        options={{ label: "Users", isMenuParent: true }}
                        icon={GroupIcon}
                    // isOpen={false}
                    />,

                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/metrics"
                            options={{ label: "Metrics", menuParent: "user-block" }}
                            icon={GroupIcon}
                            {...metrics}
                        />
                    ) : null,

                    <Resource
                        name="v1/admin/community"
                        options={{ label: "Community", menuParent: "user-block" }}
                        // icon={GroupIcon}
                        list={CommunityDirectory}
                    // {...CommunityDirectory}
                    />,
                    <Resource
                        name="v1/admin/communitylist"
                        options={{ label: "Community List", menuParent: "user-block" }}
                        list={CommunityList}
                    />,
                    <Resource
                        name="v1/admin/communityitem"
                        options={{ label: "Community Item" }}
                        // icon={GroupIcon}
                        list={CommunityItem}
                    // {...CommunityDirectory}
                    />,

                    isAdmin(permissions) ? (
                        <Resource
                            name="v1/admin/promoRewards"
                            options={{ label: "Bonus Config", menuParent: "user-block" }}
                            {...rewardConfig}
                        />
                    ) : null,

                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/operators"
                            options={{ label: "Operators ", menuParent: "user-block" }}
                            icon={VerifiedUserIcon}
                            list={ListGuesser}
                        />
                    ) : null,

                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/notifications/global"
                            options={{ label: "Notifications", menuParent: "user-block" }}
                            icon={NotificationsIcon}
                            list={NotificationList}
                            create={NotitificationCreate}
                        />
                    ) : null,

                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/notifications/adminMailConfig"
                            options={{ label: "Mail Config", menuParent: "user-block" }}
                            icon={NotificationsIcon}
                            {...adminMailConfig}
                        />
                    ) : null,

                    <Resource
                        name="aggregated"
                        options={{ label: "Aggregated", isMenuParent: true }}
                        icon={GroupIcon}
                        isOpen={true}
                    />,
                    isExecutive(permissions) ? (
                        <Resource
                            name="v2/admin/aob/aggMarkets"
                            options={{ label: "Aggregated Markets", menuParent: "aggregated" }}
                            icon={() => <Icon className="fa fa-files-o" />}
                            {...aggMarkets}
                        />
                    ) : null,
                    isExecutive(permissions) ? (
                        <Resource
                            name="v2/admin/aob/aggOrders"
                            options={{ label: "Aggregated Orders", menuParent: "aggregated" }}
                            icon={() => <Icon className="fa fa-file-text" />}
                            {...aggOrders}
                        />
                    ) : null,

                    isExecutive(permissions) ? (
                        <Resource
                            name="v2/admin/aob/transferRoutes"
                            options={{ label: "Transfer Routes", menuParent: "aggregated" }}
                            icon={() => <Icon className="fa fa-fighter-jet" />}
                            {...aggTransferRoutes}
                        />
                    ) : null,

                    isExecutive(permissions) ? (
                        <Resource
                            name="v2/admin/aob/iet"
                            options={{ label: "Transfers", menuParent: "aggregated" }}
                            icon={() => <Icon className="fa fa-arrows-h" />}
                            {...aggTransfers}
                        />
                    ) : null,

                    <Resource
                        name="exchange"
                        options={{ label: "Exchange", isMenuParent: true }}
                        icon={AssessmentIcon}
                    />,

                    isExecutive(permissions) ? (
                        <Resource
                            name="tradingView"
                            options={{ label: "Trading View", menuParent: "exchange" }}
                            icon={() => <Icon className="fa fa-bar-chart" />}
                            {...tradingView}
                        />
                    ) : null,

                    isStaff(permissions) ? (
                        <Resource
                            name="v1/admin/orderbook/currency"
                            options={{ label: "Currencies", menuParent: "exchange" }}
                            icon={MonetizationOnIcon}
                            {...currencies}
                        />
                    ) : null,

                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/orderbook/externalExchangeOffset"
                            options={{ label: "External Offset", menuParent: "exchange" }}
                            icon={MonetizationOnIcon}
                            {...offsets}
                        />
                    ) : null,

                    isStaff(permissions) ? (
                        <Resource
                            name="v1/admin/orderbook/symbol"
                            options={{ label: "Markets", menuParent: "exchange" }}
                            icon={TimelineIcon}
                            {...markets}
                        />
                    ) : null,

                    isStaff(permissions) ? (
                        <Resource
                            name="v1/admin/orderbook/errorOrders"
                            options={{ label: "Error Orders", menuParent: "exchange" }}
                            icon={DnsIcon}
                            {...errorOrders}
                        />
                    ) : null,

                    isMarketing(permissions) ? (
                        <Resource
                            name="v1/admin/tier/affiliates"
                            options={{ label: "Affiliates", menuParent: "exchange" }}
                            icon={() => <Icon className="fa fa-money" />}
                            {...affiliates}
                        />
                    ) : null,

                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/orderbook/feeConfig"
                            options={{ label: "Fees", menuParent: "exchange" }}
                            icon={() => <Icon className="fa fa-money" />}
                            {...feeConfig}
                        // show={ShowGuesser}
                        />
                    ) : null,

                    isMarketing(permissions) ? (
                        <Resource
                            name="v1/admin/tier/tasks"
                            options={{ label: "Task Config", menuParent: "exchange" }}
                            icon={() => <Icon className="fa fa-sliders" />}
                            {...taskConfig}
                        />
                    ) : null,

                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/tier/tiers"
                            options={{ label: "Tier Config", menuParent: "exchange" }}
                            icon={() => <Icon className="fa fa-sliders" />}
                            {...tierConfig}
                        />
                    ) : null,

                    isStaff(permissions) ? (
                        <Resource
                            name="v1/admin/tier/streakLeaderBoard"
                            options={{ label: "Streak Leaderboard", menuParent: "exchange" }}
                            icon={MonetizationOnIcon}
                            {...streakLeaderboard}
                        />
                    ) : null,
                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/mailConfig"
                            options={{ label: "Mail Config", menuParent: "exchange" }}
                            icon={() => <Icon className="fa fa-sliders" />}
                            {...mailConfig}
                        />
                    ) : null,
                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/notifications/siteUpdate"
                            options={{
                                label: "Site update notification",
                                menuParent: "exchange",
                            }}
                            icon={() => <Icon className="fa fa-sliders" />}
                            {...siteUpdate}
                        />
                    ) : null,

                    <Resource
                        name="vaults-block"
                        options={{ label: "Vaults", isMenuParent: true }}
                        icon={() => <Icon className="fa fa-lock" />}
                    />,

                    isStaff(permissions) ? (
                        <Resource
                            name="v1/admin/vaults/ratesConfig"
                            options={{ label: "Rates", menuParent: "vaults-block" }}
                            icon={() => <Icon className="fa fa-cogs" />}
                            {...rates}
                        />
                    ) : null,

                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/vaults/vaultConfig"
                            options={{ label: "Vault Config", menuParent: "vaults-block" }}
                            icon={() => <Icon className="fa fa-sliders" />}
                            {...vaultConfig}
                        />
                    ) : null,

                    <Resource
                        name="accounting"
                        options={{ label: "Accounting", isMenuParent: true }}
                        icon={() => <Icon className="fa fa-calculator" />}
                    />,

                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/totalBalances"
                            options={{ label: "Total Balances", menuParent: "accounting" }}
                            icon={() => <Icon className="fa fa-tty" />}
                            {...totalBalance}
                        />
                    ) : null,

                    isStaff(permissions) ? (
                        <Resource
                            name="v1/admin/funds/transferConfig"
                            options={{ label: "TranferConfig", menuParent: "accounting" }}
                            icon={() => <Icon className="fa fa-download" />}
                            {...transferConfig}
                        />
                    ) : null,

                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/funds/cryptoBalances"
                            options={{ label: "Crypto Balances", menuParent: "accounting" }}
                            {...cryptoBalances}
                        />
                    ) : null,

                    isExecutive(permissions) ? (
                        <Resource
                            name="v1/admin/funds/pendingWithdrawals"
                            options={{
                                label: "Pending withdrawals",
                                menuParent: "accounting",
                            }}
                            icon={() => <Icon className="fa fa-pause" />}
                            list={ListGuesser}
                        />
                    ) : null,

                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/funds/adjustments"
                            options={{ label: "Adjustments", menuParent: "accounting" }}
                            icon={() => <Icon className="fa fa-calculator" />}
                            list={ListGuesser}
                        />
                    ) : null,

                    isSuperadmin(permissions) ? (
                        <Resource
                            name="v1/admin/adminAudit"
                            options={{ label: "Operations", menuParent: "accounting" }}
                            icon={() => <Icon className="fa fa-tty" />}
                            {...operations}
                        />
                    ) : null,
                ]}
            </Admin>
        </>
    );
};

function isSuperadmin(permissions) {
    return permissions.includes("SUPERADMIN");
}

function isAdmin(permissions) {
    return permissions.includes("ADMIN") || isSuperadmin(permissions);
}

function isExecutive(permissions) {
    return permissions.includes("EXECUTIVE") || isAdmin(permissions);
}

function isStaff(permissions) {
    return permissions.includes("STAFF") || isExecutive(permissions);
}

function isMarketing(permissions) {
    return permissions.includes("MARKETING") || isStaff(permissions);
}

export default App;
