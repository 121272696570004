import {
    BooleanField,
    Datagrid,
    List, NumberField, TextField
} from "react-admin";
import { ColoredChipField } from "../../../helpers/colorChip";

export const transferRoutesList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <NumberField source="fee" />
            <NumberField source="minAmount" />
            <ColoredChipField source="exchange" />
            <TextField source="network" />
            <TextField source="networkName" />
            <TextField source="tokenType" />
            <TextField source="currencyName" />
            <TextField source="currencyShortName" />
            <TextField source="avgTime" />
            <TextField source="confirmation" />
            <NumberField source="avgTimeInSeconds" />
            <BooleanField source="withdrawEnabled" />
            <BooleanField source="depositEnabled" />
            <TextField source="note" />
        </Datagrid>
    </List>
);