import React, { useState, useEffect } from "react";

const useResizer = (props) => {
  const [dimension, setDimension] = useState({});
  function handleResize() {
    let tempObj = {};
    tempObj.width = window.innerWidth;
    tempObj.height = window.innerHeight;
    setDimension({ ...tempObj });
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return dimension;
};

export default useResizer;
