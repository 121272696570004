import Grid from "@material-ui/core/Grid";
/* eslint-disable no-unused-vars */
import { FilterList, FilterListItem } from "react-admin";

export const StatusFilter = () => (
  <FilterList label="Status" source="status">
    <Grid container spacing={1}>
      <Grid item>
        <FilterListItem label="STARTED" value={{ status: "STARTED" }} />
      </Grid>
      <Grid item>
        <FilterListItem label="COMPLETED" value={{ status: "COMPLETED" }} />
      </Grid>
      <Grid item>
        <FilterListItem label="AWAIT_ADMIN_ACTION" value={{ status: "AWAIT_ADMIN_ACTION" }} />
      </Grid>
      <Grid item>
        <FilterListItem label="FAILED" value={{ status: "FAILED" }} />
      </Grid>
      <Grid item>
        <FilterListItem label="NOT_STARTED" value={{ status: "NOT_STARTED" }} />
      </Grid>
    </Grid>
  </FilterList>
);
