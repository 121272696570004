import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const GridView = (props) => {
    return (
        <Grid container className="relative left-8">
            <Grid item xs={4}>
                {" "}
                <h4
                    className="text-xs md:text-base"
                    style={props.color ? { color: props.color } : {}}
                >
                    {props.label}{" "}
                </h4>
            </Grid>
            <Grid className="text-xs md:text-base" item xs={4}>
                {props.highlight ? <h2>{props.value}</h2> : <h4>{props.value}</h4>}
            </Grid>
            <Grid item xs={4}>
                <h6 className="text-xs md:text-base">
                    <a href="#">View all</a>
                </h6>
            </Grid>
        </Grid>
    );
};

const AuditPopup = (props) => {
    const [value, setValue] = React.useState(0);
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [auditDetails, setAuditDetails] = useState();

    useEffect(() => {
        dataProvider
            .getOne("v1/admin/audit", { id: props.userId + "/" + props.coin })
            .then(({ data }) => {
                setAuditDetails(data);
                // setLoading(false);
                props.id = data.id;
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    return (
        <Box sx={style} className="w-full md:w-auto">
            <h2 style={{ textAlign: "center" }}>Audit - {auditDetails?.coin} </h2>{" "}
            <br />
            <Grid container spacing={3}>
                <GridView
                    color="green"
                    label="Total Deposit"
                    value={auditDetails?.totalDeposit}
                />
                <GridView
                    color="red"
                    label="Total Withdraw"
                    value={auditDetails?.totalWithdraw}
                />
                <GridView
                    color="green"
                    label="Total Buy"
                    value={auditDetails?.totalBuy}
                />
                <GridView
                    color="red"
                    label="Total Sell"
                    value={auditDetails?.totalSell}
                />
                <GridView
                    color="red"
                    label="Total Vault"
                    value={auditDetails?.totalVault}
                />
                <GridView
                    color="green"
                    label="Total Bonus/Reward"
                    value={auditDetails?.totalBonus}
                />
                <GridView
                    label="Curreny Balance"
                    value={auditDetails?.currentBalance}
                />
                <GridView
                    label="In Order Balance"
                    value={auditDetails?.inOrderBalance}
                />
                <GridView
                    label="Discrepancy"
                    color="red"
                    highlight={true}
                    value={auditDetails?.discrepancy}
                />
            </Grid>
        </Box>
    );
};

export default AuditPopup;
