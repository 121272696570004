import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { COMMUNITY_URL } from "../../../config/env";
import { useNotify } from "react-admin";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function UpdateCmmunityModal({
  openUpdate,
  setOpenUpdate,
  updateData,
  setUpdateData,
  getList,
  page,
  rowsPerPage,
}) {
  const [vote, setVote] = useState();
  const [updateUrl, setUpdateUrl] = useState();
  const [updateMembers, setUpdateMembers] = useState();
  const [name, setName] = useState("");
  const [cardDescription, setCardDescription] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [categories, setCategories] = useState();
  const [tagArray, setTagArray] = useState([]);
  const theme = useTheme();
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (updateData) {
      console.log("called");
      setName(updateData.name);
      setCardDescription(updateData.description);
      setCardImage(updateData.photoUrl);
      updateData.category && setCategories([...updateData.category]);
      updateData?.votes ? setVote(updateData.votes) : setVote(0);
      updateData?.url ? setUpdateUrl(updateData.url) : setUpdateUrl("");
      updateData?.members
        ? setUpdateMembers(updateData.members)
        : setUpdateMembers();
    }
  }, [updateData]);
  function getTags() {
    fetch(`${COMMUNITY_URL}/getTags`)
      .then((res) => res.json())
      .then((res) => {
        setTagArray(res);
      });
  }

  useEffect(() => {
    getTags();
  }, []);
  function handleChange(e) {
    setCategories(e.target.value);
  }
  console.log(categories);
  function handleSubmit() {
    const token = localStorage.getItem("token");
    setIsLoading(true)
    let payload = !updateUrl?.length
      ? !updateMembers
        ? {
            id: updateData?.id,
            name,
            description: cardDescription,
            photoUrl: cardImage,
            category: categories,
            votes: vote,
          }
        : {
            id: updateData?.id,
            name,
            description: cardDescription,
            photoUrl: cardImage,
            category: categories,
            votes: vote,
            members: updateMembers,
          }
      : !updateMembers
      ? {
          id: updateData?.id,
          name,
          description: cardDescription,
          photoUrl: cardImage,
          category: categories,
          votes: vote,
          url: updateUrl,
        }
      : {
          id: updateData?.id,
          name,
          description: cardDescription,
          photoUrl: cardImage,
          category: categories,
          votes: vote,
          members: updateMembers,
          url: updateUrl,
        };
    console.log(payload, "pay");

    fetch(`${COMMUNITY_URL}/updateCommunity`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        accept: "application.json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (res.ok) {
          setIsLoading(false)
          getList(page, rowsPerPage);
          setOpenUpdate(false);
          notify("Updated", { type: "success" });
        }
      })
      .catch((err) => {
        setIsLoading(false)
        notify(err.message, { type: "error" });
      });
  }
  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={openUpdate}
      onClose={() => {
        setOpenUpdate(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        onClick={() => {
          setOpenUpdate(false);
        }}
        className="flex justify-center items-center h-screen"
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="bg-[#FDFDFD] flex justify-center flex-col items-center w-[500px]  "
        >
          <p className="font-2xl mt-3 font-bold">Update Community</p>
          <form
            className="w-full px-4"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="w-full">
              <p>Name</p>
              <input
                type="text"
                className="pl-2 mb-2 py-1 w-full border"
                placeholder="Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <p className="mb-1">Description</p>
              <textarea
                rows="4"
                cols="50"
                className="w-full py-2 mb-2 px-2 border border-gray-500 rounded-md"
                type="text"
                placeholder="Description"
                value={cardDescription}
                onChange={(e) => {
                  setCardDescription(e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <p className="mb-1">Photo Url</p>
              <input
                className="w-full mb-2 py-2 px-2 border border-gray-500 rounded-md"
                type="url"
                placeholder="Photo Url"
                value={cardImage}
                onChange={(e) => {
                  setCardImage(e.target.value);
                }}
              />
            </div>
            <p>Category</p>
            <Select
              labelId="categor3"
              id="category3"
              multiple
              value={categories}
              onChange={handleChange}
              input={<OutlinedInput label="Name" />}
              MenuProps={MenuProps}
              className="w-full"
            >
              {tagArray.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>

            <div className="mb-2">
              <p>Vote</p>
              <input
                required
                type="number"
                className="pl-2 py-1 w-full border"
                placeholder="Votes"
                value={vote}
                onChange={(e) => {
                  setVote(e.target.value);
                }}
              />
            </div>
            <div className="mb-2">
              <p>Url</p>
              <input
                type="url"
                className="pl-2 py-1 w-full border"
                placeholder="Update Url"
                value={updateUrl}
                onChange={(e) => {
                  setUpdateUrl(e.target.value);
                }}
              />
            </div>
            <div className="mb-2">
              <p>Members</p>
              <input
                type="number"
                className="pl-2 py-1 w-full border"
                placeholder="Update members"
                value={updateMembers}
                onChange={(e) => {
                  setUpdateMembers(e.target.value);
                }}
              />
            </div>
            <div className="w-full mb-3  flex gap-x-3 mt-5 justify-center px-3">
              <button
                disabled={isLoading}
                type="submit"
                className={`w-[30%] text-white py-2 rounded-md bg-[#0047AB] ${
                  isLoading ? "opacity-60" : "opacity-100"
                }`}
              >
                {isLoading ? "Loading.." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
