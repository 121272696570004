import { Box, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
/* eslint-disable no-unused-vars */
import * as React from "react";
import {
  FilterList,
  FilterListItem,
  SelectInput,
  TextInput,
  useListContext,
} from "react-admin";
import { Form } from "react-final-form";

const TransferFilter = (props) => {
  return props.context === "button" ? null : <TransferFilterForm {...props} />;
};

const TransferFilterForm = ({ open }) => {
  const { displayedFilters, filterValues, setFilters, showFilter } =
    useListContext();

  const onSubmit = (values) => {
    if (Object.keys(values).length > 0) {
      setFilters(values);
    }
  };

  const resetFilter = () => {
    setFilters({ showFilter: "main" }, []);
  };

  return (
    <FilterList>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" alignItems="flex-end" mb={1}>
              <Box component="span" mr={2} mb={-2}>
                <SelectInput
                  variant="outlined"
                  source="side"
                  allowEmpty={true}
                  emptyText="ALL"
                  choices={[
                    { id: "WITHDRAW", name: "WITHDRAW" },
                    { id: "DEPOSIT", name: "DEPOSIT" },
                  ]}
                />
              </Box>

              <Box component="span" mr={2} mb={-2}>
                <SelectInput variant="outlined" source="status" choices={[]} />
              </Box>

              <Box component="span" mr={2} mb={-2}>
                <TextInput
                  variant="outlined"
                  source="searchUserId"
                  label="Search by User ID"
                />
              </Box>

              <Box component="span" mr={2} mb={-2}>
                <TextInput
                  variant="outlined"
                  source="searchId"
                  label="Search by id"
                />
              </Box>

              <Box component="span" mr={2} mb={1.5}>
                <Button variant="outlined" color="secondary" type="submit">
                  Filter
                </Button>
              </Box>

              <Box component="span" mb={1.5}>
                <Button variant="outlined" onClick={resetFilter}>
                  Reset
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </FilterList>
  );
};

export const StatusFilter = () => (
  <FilterList label="Status" source="transferStatus">
    <Grid container spacing={1}>
      <Grid item>
        <FilterListItem
          label="EMAIL SENT"
          value={{ transferStatus: "EMAIL_SENT" }}
        />
      </Grid>
      <Grid item>
        <FilterListItem
          label="AWAITING_APPROVAL"
          value={{ transferStatus: "AWAITING_APPROVAL" }}
        />
      </Grid>
      <Grid item>
        <FilterListItem label="ON_HOLD" value={{ transferStatus: "ON_HOLD" }} />
      </Grid>
      <Grid item>
        <FilterListItem
          label="PROCESSING"
          value={{ transferStatus: "PROCESSING" }}
        />
      </Grid>
      <Grid item>
        <FilterListItem label="FAILED" value={{ transferStatus: "FAILED" }} />
      </Grid>
      <Grid item>
        <FilterListItem
          label="COMPLETED"
          value={{ transferStatus: "COMPLETED" }}
        />
      </Grid>
    </Grid>
  </FilterList>
);

export const TypeFilter = () => {
  return (
    <div>
      <FilterList label="TransferType" source="transferType">
        <Grid container spacing={1}>
          <Grid item>
            <FilterListItem
              label="INTERNAL"
              value={{ transferType: "INTERNAL" }}
            />
          </Grid>
          <Grid item>
            <FilterListItem
              label="EXTERNAL"
              value={{ transferType: "EXTERNAL" }}
            />
          </Grid>
          <Grid item>
            <FilterListItem label="VAULT" value={{ transferType: "VAULT" }} />
          </Grid>
        </Grid>
      </FilterList>
    </div>
  );
};
