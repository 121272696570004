import React, { useState, usEffect, useEffect } from "react";
import { useDataProvider, useNotify } from "react-admin";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { COMMUNITY_URL } from "../../../config/env";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const CommunityDirectory = (props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [cardDescription, setCardDescription] = useState("");
  const [isCategoryDisabled, setIsCategoryDisabled] = useState(false);
  const [isCommunityDisabled, setIsCommunityDisabled] = useState(false);
  const [url, setUrl] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [categories, setCategories] = useState("nft");
  const [categoriesCom, setCategoriesCom] = useState(["nft"]);
  const [title, setTitle] = useState("");
  const dataProvider = useDataProvider();
  const [communityList, setCommunityList] = useState();
  const [selectedCommunitylist, setSelectedCommunityList] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [communityListError, setCommunityListError] = useState("");
  const [tagArray, setTagArray] = useState([]);
  const token = localStorage.getItem("token");
  const [tag, setTag] = useState("");
  const [tagMsg, setTagMsg] = useState();
  const [uploadImage, setUploadImage] = useState();
  const [img1Message, setImg1Message] = useState("");
  const [uploadImage1, setUploadImage1] = useState();
  const [uploadLogo, setUploadLogo] = useState();
  const [img3Message, setImg3Message] = useState("");
  const [logo, setLogo] = useState("");
  const [img2Message, setImg2Message] = useState("");
  const [vote, setVote] = useState();
  const [members, setmembers] = useState();
  const [redirectUrl, setRedirectUrl] = useState();
  const [isTagLoading, setIsTagLoading] = useState(false);
  const [isCatImgLoading, setIsCatImgLoading] = useState(false);
  const [isComImgLoading, setIsComImgLoading] = useState(false);
  const [isLogoLoading, setIsLogoLoading] = useState(false);
  const notify = useNotify();
  const theme = useTheme();
  function handleSubmit() {
    setIsCommunityDisabled(true);
    if (!categoriesCom.length) {
      setCategoryError("This field is required");
    } else if (!selectedCommunitylist.length) {
      setCommunityListError("This field is required");
    } else {
      fetch(`${COMMUNITY_URL}/addCommunity`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
          accept: "application.json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          description,
          photoUrl: url,
          category: categoriesCom,
          listId: selectedCommunitylist,
          url: redirectUrl,
          votes: Number(vote),
          members,
        }),
      })
        .then((res) => {
          setIsCommunityDisabled(false);
          const response = res.json();
          if (res.ok) {
            notify("Community added", { type: "success" });
          } else {
            notify(response.message, { type: "error" });
          }
        })
        .catch((err) => {
          setIsCommunityDisabled(false);
          notify("Something went wrong", { type: "error" });
        });
    }
  }
  function handleSubmitCategory() {
    setIsCategoryDisabled(true);
    fetch(`${COMMUNITY_URL}/createListCard`, {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        card_title: title,
        card_description: cardDescription,
        card_image: cardImage,
        category: categories,
        logo,
      }),
    })
      .then((res) => {
        setIsCategoryDisabled(false);
        const response = res.json();
        if (res.ok) {
          notify("Community added", { type: "success" });
        } else {
          notify(response.message, { type: "error" });
        }
      })
      .catch((err) => {
        setIsCategoryDisabled(false);
        notify("Something went wrong", { type: "error" });
      });
  }
  function handleChange(e) {
    setCategoryError("");
    setCategoriesCom(e.target.value);
  }

  function getListCards() {
    fetch(`${COMMUNITY_URL}/getListCards?page=1&limit=100000`, {
      method: "GET",
      headers: { authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((res) => {
        setCommunityList(res);
      });
  }
  useEffect(() => {
    getListCards();
  }, []);

  function getTags() {
    fetch(`${COMMUNITY_URL}/getTags`)
      .then((res) => res.json())
      .then((res) => {
        setTagArray(res);
      });
  }

  useEffect(() => {
    getTags();
  }, []);
  function submitTag() {
    setIsTagLoading(true);
    fetch(`${COMMUNITY_URL}/addTag`, {
      method: "POST",
      headers: {
        accept: "application.json",
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name: tag }),
    })
      .then((res) => {
        if (res.ok) {
          setIsTagLoading(false);
          getTags();
          notify("Tag added", { type: "success" });
        }
      })
      .catch((err) => {
        setIsTagLoading(false);
        notify(err.message, { type: "error" });
      });
  }

  function submitImage(img, section) {
    console.log(img);
    const data = new FormData();
    data.append("fileToUpload", img);
    console.log(data);
    fetch(`${COMMUNITY_URL}/uploadFile`, {
      method: "post",
      body: data,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      //   authorization: `Bearer ${token}`,
      // },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        console.log(res);

        if (section == "img1") {
          setCardImage(res.url);
          setIsCatImgLoading(false);
        } else if (section == "img2") {
          setIsComImgLoading(false)
          setUrl(res.url);
        } else {
          setIsLogoLoading(false);
          setLogo(res.url);
        }
        notify("Success", { type: "success" });
      })
      .catch((err) => {
        setIsCatImgLoading(false);
        setIsLogoLoading(false);
        setIsComImgLoading(false)
        notify(err.message, { type: "error" });
      });
  }

  return (
    <div className="w-full ">
      <div className="w-full px-2 md:w-[40%]  flex flex-col pt-20">
        <h1 className="mb-4 font-bold text-xl">Add Community List</h1>
        <form
          id="category"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitCategory();
          }}
          className="flex flex-col gap-y-5 justify-center items-center"
        >
          <div className="w-full">
            <p className="mb-1">Title</p>
            <input
              form="category"
              required
              className="w-full py-2 px-2 border border-gray-500 rounded-md"
              type="text"
              placeholder="Name"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
          <div className="w-full">
            <p className="mb-1">Description</p>
            <textarea
              form="category"
              required
              rows="4"
              cols="50"
              className="w-full py-2 px-2 border border-gray-500 rounded-md"
              type="text"
              placeholder="Description"
              value={cardDescription}
              onChange={(e) => {
                setCardDescription(e.target.value);
              }}
            />
          </div>

          <div className="w-full">
            <p className="mb-1">Add New Tag</p>
            <div className="flex items-center gap-x-2">
              <input
                type="text"
                placeholder="Add new Tag"
                form="tag"
                value={tag}
                required
                className="w-[80%] border rounded-md h-[32px] pl-2"
                onChange={(e) => {
                  setTag(e.target.value);
                }}
              />

              <button
                disabled={isTagLoading}
                onClick={(e) => {
                  tag.length
                    ? submitTag(tag)
                    : setTagMsg("This field is required");
                }}
                type="submit"
                form="tag"
                className={`bg-[#0047AB] ${
                  isTagLoading ? "opacity-60" : "opacity-100"
                } rounded-md hover:opacity-90 w-[100px] flex items-center justify-center h-[30px] cursor-pointer text-white `}
              >
                {isTagLoading ? "Loading..." : "Add Tag"}
              </button>
            </div>
            <p className="text-xs font-normal text-rose-500">{tagMsg}</p>
          </div>
          <div className="w-full">
            <p className="mb-1">Upload Image</p>
            <div className="flex items-center gap-x-2">
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                placeholder="Upload Image"
                // value={uploadImage}
                className="w-[80%] border rounded-md h-[32px] pl-2"
                onChange={(e) => {
                  setImg1Message("");
                  const file = e.target.files[0];
                  setUploadImage(file);
                }}
              />
              <button
                disabled={isCatImgLoading}
                onClick={(e) => {
                  if (uploadImage) {
                    setIsCatImgLoading(true);
                    submitImage(uploadImage, "img1");
                  } else {
                    setImg1Message("This field is required");
                  }
                }}
                className={`${
                  isCatImgLoading ? "opacity-60" : "opacity-100"
                } bg-[#0047AB] rounded-md hover:opacity-90 w-[110px] flex items-center justify-center h-[30px] cursor-pointer text-white `}
              >
                {isCatImgLoading ? "Loading.." : "Save Image"}
              </button>
            </div>
            <p className="text-xs font-normal text-rose-500">{img1Message}</p>
          </div>
          <div className="w-full">
            <p className="mb-1">Upload Platform Logo</p>
            <div className="flex items-center gap-x-2">
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                placeholder="Upload platform logo"
                // value={uploadImage}
                className="w-[80%] border rounded-md h-[32px] pl-2"
                onChange={(e) => {
                  setImg3Message("");
                  const file = e.target.files[0];
                  setUploadLogo(file);
                }}
              />
              <button
                disabled={isLogoLoading}
                onClick={(e) => {
                  if (uploadLogo) {
                    setIsLogoLoading(true);
                    submitImage(uploadLogo, "img3");
                  } else {
                    setImg3Message("This field is required");
                  }
                }}
                className={`bg-[#0047AB] rounded-md hover:opacity-90 w-[110px] flex items-center justify-center h-[30px] cursor-pointer text-white ${
                  isLogoLoading ? "opacity-60" : "opacity-100"
                }`}
              >
                {isLogoLoading ? "Loading.." : "Save Logo"}
              </button>
            </div>
            <p className="text-xs font-normal text-rose-500">{img1Message}</p>
          </div>

          <div className="w-full">
            <p className="mb-1">Photo Url</p>
            <input
              form="category"
              required
              className="w-full py-2 px-2 border border-gray-500 rounded-md"
              type="url"
              placeholder="Photo Url"
              value={cardImage}
              onChange={(e) => {
                setCardImage(e.target.value);
              }}
            />
          </div>
          <div className="w-full">
            <p className="mb-1">Logo Url</p>
            <input
              form="category"
              required
              className="w-full py-2 px-2 border border-gray-500 rounded-md"
              type="url"
              placeholder="Photo Url"
              value={logo}
              onChange={(e) => {
                setLogo(e.target.value);
              }}
            />
          </div>
          <label className="text-left w-full" for="category">
            Select a category:
          </label>
          <select
            form="category"
            onChange={(e) => {
              setCategories(e.target.value);
            }}
            className="w-full border border-black rounded-md py-2"
            id="category"
            name="category"
          >
            {tagArray.map((item, i) => {
              return (
                <option key={i} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </select>

          <button
            disabled={isCategoryDisabled}
            form="category"
            className={`bg-[#0047AB] rounded-md hover:opacity-90 w-[200px] h-[45px] cursor-pointer text-white  ${
              isCategoryDisabled ? "opacity-60" : "opacity-100"
            }`}
          >
            {isCategoryDisabled ? "Loading..." : "Submit"}
          </button>
        </form>
      </div>
      <div className="w-full px-2 md:w-[40%]  flex flex-col pt-20">
        <h1 className="mb-4 font-bold text-xl">Add Community Items</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="flex flex-col gap-y-5 justify-center items-center"
        >
          <div className="w-full">
            <p className="mb-1">Name</p>
            <input
              required
              className="w-full py-2 px-2 border border-gray-500 rounded-md"
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="w-full">
            <p className="mb-1">Description</p>
            <textarea
              required
              rows="4"
              cols="50"
              className="w-full py-2 px-2 border border-gray-500 rounded-md"
              type="text"
              placeholder="Description"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>

          <div className="w-full">
            <p className="mb-1">Upload Image</p>
            <div className="flex items-center gap-x-2">
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                placeholder="Upload Image"
                // value={uploadImage}
                className="w-[80%] border rounded-md h-[32px] pl-2"
                onChange={(e) => {
                  setImg2Message("");
                  const file = e.target.files[0];
                  setUploadImage1(file);
                }}
              />
              <button
                disabled={isComImgLoading}
                onClick={(e) => {
                  if (uploadImage1) {
                    setIsComImgLoading(true)
                    submitImage(uploadImage1, "img2");
                  } else {
                    setImg2Message("This field is required");
                  }
                }}
                className={`bg-[#0047AB] rounded-md hover:opacity-90 w-[110px] flex items-center justify-center h-[30px] cursor-pointer text-white ${
                  isComImgLoading ? "opacity-60" : "opacity-100"
                }`}
              >
                {isComImgLoading?"Loading..": "Save Image"}
              </button>
            </div>
            <p className="text-xs font-normal text-rose-500">{img2Message}</p>
          </div>

          <div className="w-full">
            <p className="mb-1">Photo Url</p>
            <input
              required
              className="w-full py-2 px-2 border border-gray-500 rounded-md"
              type="url"
              placeholder="Photo Url"
              value={url}
              onChange={(e) => {
                setUrl(e.target.value);
              }}
            />
          </div>
          <label className="text-left w-full" for="category1">
            Select a category:
          </label>
          <Select
            labelId="category1"
            id="category1"
            multiple
            value={categoriesCom}
            onChange={handleChange}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
            className="w-full"
          >
            {tagArray.map((item) => (
              <MenuItem
                key={item.name}
                value={item.name}
                style={getStyles(item.name, categoriesCom, theme)}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <p className="text-left w-full text-rose-700 text-sm forn-nromal ">
            {categoryError}
          </p>
          <label className="text-left w-full" for="communitylist">
            Select a community list name:
          </label>
          <Select
            labelId="communitylist"
            id="demo-multiple-name"
            value={selectedCommunitylist}
            onChange={(e) => {
              setSelectedCommunityList(e.target.value);
              setCommunityListError("");
            }}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
            className="w-full"
          >
            {communityList?.data?.map((name) => (
              <MenuItem key={name.card_title} value={name.id}>
                {name.card_title}
              </MenuItem>
            ))}
          </Select>
          <p className="text-rose-700 text-left w-full text-sm forn-nromal ">
            {communityListError}
          </p>
          <div className="mb-2 w-full">
            <p>Vote</p>
            <input
              required
              type="number"
              className="pl-2 py-1 w-full border"
              placeholder="Votes"
              value={vote}
              onChange={(e) => {
                setVote(e.target.value);
              }}
            />
          </div>
          <div className="mb-2 w-full">
            <p>Url</p>
            <input
              required
              type="url"
              className="pl-2 py-1 w-full border"
              placeholder="Add redirect Url"
              value={redirectUrl}
              onChange={(e) => {
                setRedirectUrl(e.target.value);
              }}
            />
          </div>
          <div className="mb-2 w-full">
            <p>Members</p>
            <input
              required
              type="number"
              className="pl-2 py-1 w-full border"
              placeholder="Add members"
              value={members}
              onChange={(e) => {
                setmembers(e.target.value);
              }}
            />
          </div>
          <button
            type="submit"
            disabled={isCommunityDisabled}
            className={`bg-[#0047AB] rounded-md hover:opacity-90 w-[200px] h-[45px] cursor-pointer text-white ${
              isCommunityDisabled ? "opacity-60" : "opacity-100"
            }`}
          >
            {isCommunityDisabled ? "Loading..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CommunityDirectory;
