import { BooleanInput, Create, NumberInput, SelectInput, SimpleForm, TextInput } from "react-admin";


export const TransferConfigCreate = props => (
    <Create {...props}>
        <SimpleForm>

            <TextInput source="network" />
            <TextInput source="tokenType" />
            <TextInput source="parentCoinId" />
            <TextInput source="coinId" />
            <SelectInput
                source="transferSide"
                emptyText="DEPOSIT"
                choices={[
                    { id: "WITHDRAW", name: "WITHDRAW" },
                    { id: "DEPOSIT", name: "DEPOSIT" },
                ]}
            />
            <NumberInput source="minAmount" />
            <NumberInput source="fee" />
            <TextInput source="status" />
            <BooleanInput source="tagOrMemo" />
            <TextInput source="memoType" />
            <TextInput source="contract" />
            <NumberInput source="confirmations" />
        </SimpleForm>
    </Create>
);