import {
  Datagrid, List, TextField, FunctionField,
  FilterLiveSearch, ExportButton 
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useResizer from "../../../utils/useResizer"

const FilterSidebar = () => (
  <div>
    <FilterLiveSearch source="symbol" label="Search by Pair Symbol" />
  </div>
);

export const OrderBookList = (props) => {
  const {width,height}=useResizer()
  const useStyles = makeStyles((theme) => {
    return (
    {
    root: {
      "& .MuiFormLabel-root": {
        fontSize: "12px",
      },
      "& .MuiToolbar-root": {
        // flexDirection: "column-reverse",
        width: "100%",
      },
      "& .MuiPaper-root": {
        width: "350px",
        overflow: "auto",
        height:`${height-150}px`,
        [theme.breakpoints.down('768')]:{
          height:'auto'
        },
        position:'static'
      },
      "& .MuiTablePagination-root":{
        position:"absolute",
        zIndex:10,
        bottom:0,
        [theme.breakpoints.down('768')]:{
         bottom:'-50px'
        },
        right:0,
        left:0,
        display:'flex'
      }
    },
    head:{
      "& .MuiTableCell-head":{
        fontWeight:700
      }
    }
  })});
  const classes = useStyles();
  return(
  <List className={`${classes.root} ${classes.head}`} {...props} pagination={false} filters={<FilterSidebar />} actions={<ExportButton maxResults={25000} />} bulkActionButtons={false}>
    <Datagrid>
      <TextField source="symbolId" label={"ID"} />
      <TextField source="symbol" label={"Pair Symbol"} />
      <FunctionField
        label="Total Ask Volume"
        render={record => `${record.baseCurrency} ${record.totalBaseAsk}`}
      />
      <FunctionField
        label="Total Ask Price"
        render={record => `${record.quoteCurrency} ${record.totalQuoteAsk}`}
      />
      <FunctionField
        label="Total Bid Volume"
        render={record => `${record.baseCurrency} ${record.totalBaseBid}`}
      />
      <FunctionField
        label="Total Bid Price"
        render={record => `${record.quoteCurrency} ${record.totalQuoteBid}`}
      />
    </Datagrid>
  </List>
)}
