/* eslint-disable no-unused-vars */
import * as React from "react";
import {
  ArrayField,
  BooleanField,
  Datagrid,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const CurrencyShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="currencyId" />
      <BooleanField source="active" />
      <TextField source="currencyName" />
      <TextField source="currencyShortName" />
      <NumberField source="baseScale" />
      <NumberField source="decimalTrade" />
      <NumberField source="decimalWithdraw" />
      <NumberField source="decimalLend" />
      <NumberField source="minAmountWithdraw" />
      <NumberField source="minAmountDeposit" />
      <NumberField source="withdrawFee" />
      <NumberField source="confirmations" />
      <TextField source="withdrawStatus" />
      <TextField source="depositStatus" />
      <TextField source="tradeStatus" />
      <BooleanField source="tagOrMemo" />
      <TextField source="memoType" />
      <TextField source="contract" />
      <TextField source="activeIconUrl" />
      <TextField source="inactiveIconUrl" />
      <NumberField source="marketCap" />
      <ArrayField source="depositOptions">
        <Datagrid>
          <TextField source="network" />
          <TextField source="tokenType" />
          <TextField source="coinId" />
          <NumberField source="minDeposit" />
        </Datagrid>
      </ArrayField>
      <BooleanField source="inInnovationZone" />
    </SimpleShowLayout>
  </Show>
);
