/* eslint-disable no-unused-vars */
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import useResizer from "../../../utils/useResizer";
import { ActivityDetails } from "./activities/activity";
import { UserActions } from "./activities/userActions";
import { AuditPro } from "./audit/auditPro";
import { AuditTrail } from "./audit/auditTrail";
import { BalanceShow } from "./balance/balance";
import { DiamondHistoric } from "./history/diamondHistory";
import { HistoricOrders } from "./history/history";
import { IncidentReports } from "./incidents/incidentReports";
import { OpenOrders } from "./openOrders/openOrders";
import { Commissions } from "./referrals/commissions";
import { Referrals } from "./referrals/referrals";
import { UserShowDetails } from "./summary/summary";
import { TrackShow } from "./track/track";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}));

export const UserShow = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState();
    const [balanceDetails, setBalanceDetails] = useState();
    const [openOrders, setOpenOrders] = useState();
    const { width, height } = useResizer();
    const [trackDetails, setTrackDetails] = useState();
    const [activityDetails, setActivityDetails] = useState();
    const [auditDetails, setAuditDetails] = useState();
    const [historicCredits, setHistoricCredits] = useState();
    const [auditTrail, setAuditTrail] = useState();
    const [incidents, setIncidents] = useState();
    const [commissions, setCommissions] = useState();
    const [currency, setCurrency] = useState();

    useEffect(() => {
        dataProvider
            .getOne("v1/admin/users", { id: props.id })
            .then(({ data }) => {
                setUserDetails(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });

        dataProvider
            .getList("v1/admin/userActivity", {
                filter: { userId: props.id },
                pagination: { page: 1, perPage: 100 },
                sort: { field: "loginTime", order: "DESC" },
            })
            .then(({ data }) => {
                setActivityDetails(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });

        dataProvider
            .getList("v1/admin/orderbook/currency", {
                filter: { userId: props.id },
                pagination: { page: 1, perPage: 100 },
                sort: { field: "loginTime", order: "DESC" },
            })
            .then(({ data }) => {
                setCurrency(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });

        dataProvider
            .getList("v1/admin/commissions/" + props.id, {
                filter: { status: "OPEN,PARTIALLY_FILLED", userId: props.id },
                pagination: { page: 1, perPage: 100 },
                sort: { field: "createdAt", order: "DESC" },
            })
            .then(({ data }) => {
                setCommissions(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });

        dataProvider
            .getOne(`v1/admin/tier/track`, { id: props.id })
            .then(({ data }) => {
                setTrackDetails(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });

        dataProvider
            .getList("v1/admin/tier/diamondHistory", {
                filter: { searchByUser: props.id },
                pagination: { page: 1, perPage: 100 },
                sort: { field: "createdAt", order: "DESC" },
            })
            .then(({ data }) => {
                setHistoricCredits(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, [dataProvider, props]);

    const getBalances = () => {
        dataProvider
            .getOne("v1/admin/funds/balances", { id: props.id })
            .then(({ data }) => {
                setBalanceDetails(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };
    const getOpenOrders = () => {
        dataProvider
            .getList("v1/admin/orderbook/orders", {
                filter: { status: "OPEN,PARTIALLY_FILLED", userId: props.id },
                pagination: { page: 1, perPage: 100 },
                sort: { field: "createdAt", order: "DESC" },
            })
            .then(({ data }) => {
                setOpenOrders(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const getIncidents = () => {
        dataProvider
            .getList("v1/admin/userIncidents/" + props.id, {
                filter: { userId: props.id },
                pagination: { page: 1, perPage: 100 },
                sort: { field: "updatedAt", order: "DESC" },
            })
            .then(({ data }) => {
                setIncidents(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const getAuditTrail = () => {
        dataProvider
            .getList("v1/admin/auditTrail/" + props.id, {
                filter: { userId: props.id },
                pagination: { page: 1, perPage: 100 },
                sort: { field: "timestamp", order: "DESC" },
            })

            .then(({ data }) => {
                setAuditTrail(data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const getAuditPro = () => {
        dataProvider
            .getOne("v1/admin/audit", { id: props.id })
            .then(({ data }) => {
                console.log(data);
                setAuditDetails(Object.entries(data?.auditMap));
            })
            .catch((e) => {
                console.log(e);
            });
    };
    const tabVal = Number(sessionStorage.getItem("tabVal")) || 0;
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    React.useEffect(() => {
        sessionStorage.setItem("tabVal", value);
        if (value == 1 || value == 6) {
            getBalances();
        } else if (value == 5) {
            getOpenOrders();
        } else if (value == 3) {
            getBalances();
            getAuditTrail();
        } else if (value == 2) {
            getBalances();
            getAuditPro();
        } else if (value == 13) {
            getIncidents()
        }
    }, [value]);

    React.useEffect(() => {
        handleChange("", tabVal);
    }, []);

    if (loading) return "Loading ... ";
    return (
        <div>
            <div
                style={{
                    width: width < 768 ? `${width}px` : `${width - 240}px`,
                    overflow: "hidden",
                }}
            >
                <Tabs
                    enablePersist
                    action={(e) => {
                        console.log(e, "action");
                    }}
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    wrapped
                    variant="scrollable"
                >
                    <Tab className="px-0 min-w-[80px]" label="Info" {...a11yProps(0)} />
                    <Tab
                        className="px-0 min-w-[110px]"
                        label="Balances"
                        {...a11yProps(1)}
                    />
                    <Tab
                        className="px-0 min-w-[110px]"
                        label="Audit Pro"
                        {...a11yProps(2)}
                    />
                    <Tab
                        className="px-0 min-w-[110px]"
                        label="Audit Trail"
                        {...a11yProps(3)}
                    />
                    <Tab
                        className="px-0 min-w-[130px]"
                        label="User Actions"
                        {...a11yProps(4)}
                    />
                    <Tab
                        className="px-0 min-w-[140px]"
                        label="Open orders"
                        {...a11yProps(5)}
                    />
                    <Tab
                        className="px-0 min-w-[90px]"
                        label="History"
                        {...a11yProps(6)}
                    />
                    <Tab
                        className="px-0 min-w-[100px]"
                        label="Referrals"
                        {...a11yProps(7)}
                    />
                    <Tab
                        className="px-0 min-w-[120px]"
                        label="Commissions"
                        {...a11yProps(8)}
                    />
                    <Tab className="px-0 min-w-[80px]" label="Track" {...a11yProps(9)} />
                    <Tab
                        className="px-0 min-w-[110px]"
                        label="Diamond History"
                        {...a11yProps(10)}
                    />
                    <Tab
                        className="px-0 min-w-[140px]"
                        label="Login Activity"
                        {...a11yProps(11)}
                    />
                    <Tab
                        className="px-0 min-w-[110px]"
                        label="Documents"
                        {...a11yProps(12)}
                    />
                    <Tab
                        label="User Incidents"
                        {...a11yProps(13)}
                    />
                </Tabs>
            </div>

            <TabPanel value={value} index={0}>
                {userDetails ? <UserShowDetails userDetails={userDetails} /> : null}
            </TabPanel>
            <TabPanel value={value} index={1}>
                <BalanceShow balanceDetails={balanceDetails} userId={props.id} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <AuditPro auditDetails={auditDetails} balanceDetails={balanceDetails} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <AuditTrail
                    auditTrail={auditTrail}
                    id={props.id}
                    balanceDetails={balanceDetails}
                    currency={currency}
                />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <UserActions id={props.id} />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <OpenOrders openOrders={openOrders} />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <HistoricOrders id={props.id} setLoading={setLoading} />
            </TabPanel>
            <TabPanel value={value} index={7}>
                <Referrals userDetails={userDetails} />
            </TabPanel>
            <TabPanel value={value} index={8}>
                <Commissions commissions={commissions} />
            </TabPanel>
            <TabPanel value={value} index={9}>
                <TrackShow trackDetails={trackDetails} />
            </TabPanel>
            <TabPanel value={value} index={10}>
                <DiamondHistoric id={props.id} credits={historicCredits} />
            </TabPanel>
            <TabPanel value={value} index={11}>
                <ActivityDetails activityDetails={activityDetails} />
            </TabPanel>
            <TabPanel value={value} index={12}>
                Documents
            </TabPanel>
            <TabPanel value={value} index={13}>
                <IncidentReports
                    id={props.id}
                    incidents={incidents}
                    getIncidents={getIncidents}
                />
            </TabPanel>
        </div>
    );
};
