import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";

const AuditTrailMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleStatusClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleStatusClose = (event) => {
    const { myValue } = event.currentTarget.dataset;
    props.setSelected(myValue || props.filterVal);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        style={{ minWidth: "150px" }}
        variant="outlined"
        color="primary"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleStatusClick}
      >
        {props.filterVal}
        <ExpandMoreIcon style={{ marginLeft: "auto" }} />
      </Button>
      <Menu
        style={{ maxHeight: "400px" }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleStatusClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props?.menuArray?.map((item, i) => {
          return (
            <MenuItem key={i} data-my-value={item} onClick={handleStatusClose}>
              {item?.toUpperCase()}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default AuditTrailMenu;
