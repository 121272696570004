/* eslint-disable no-unused-vars */
import RichTextInput from 'ra-input-rich-text';
import * as React from "react";
import { ArrayInput, BooleanInput, Edit, NumberInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput, useDataProvider } from "react-admin";

const CustomTask = (props) => {
    switch (props.type) {

        case "TRADE_TASK":
            return (
                <>
                    <TextInput source="task.tradeBaseCurrency" label="Trade Currency" />
                    <NumberInput source="task.tradeValue" label="Trade Value" />

                    <SelectInput
                        source="task.tradeValueCurrency" label="Trade Value Currency"
                        variant="outlined"
                        choices={[
                            { id: "USD", name: "USD" },
                        ]}
                    />
                </>)
        case "VAULT_TASK":
            return (<>
                <TextInput source="task.currency" label="Vault Coin Short Name" />
                <NumberInput source="task.amount" label="Principle amount" />
                <NumberInput source="task.noOfDays" label="No of days" />
                <SelectInput
                    source="task.tenure"
                    variant="outlined"
                    choices={[
                        { id: "FLEXIBLE", name: "FLEXIBLE" },
                        { id: "ONE_WEEK", name: "ONE_WEEK" },
                        { id: "ONE_MONTH", name: "ONE_MONTH" },
                        { id: "THREE_MONTHS", name: "THREE_MONTHS" },
                        { id: "SIX_MONTHS", name: "SIX_MONTHS" },
                        { id: "ONE_YEAR", name: "ONE_YEAR" }
                    ]}
                />
            </>)
        case "USER_TASK":
            return (<>
                <ArrayInput source="task.fields">
                    <SimpleFormIterator>
                        <TextInput source="adfName" label='adf' helperText={false} />
                        <BooleanInput source="adfRequired" label='adfRequired' />
                        <SelectInput
                            helperText={false}
                            source="adfType"
                            label='adfType'
                            variant="outlined"
                            choices={[
                                { id: "TEXT", name: "TEXT" },
                                { id: "NUMBER", name: "NUMBER" },
                                { id: "FILE", name: "FILE" },
                            ]}
                        />
                    </SimpleFormIterator>
                </ArrayInput>

                <TextInput source="task.redirectionUrl" />
                <TextInput source="task.redirectionText" />
            </>)

        case "TRANSFER_TASK":
            return <>
                <SelectInput
                    source="task.transferSide"
                    variant="outlined"
                    choices={[
                        { id: "DEPOSIT", name: "DEPOSIT" },
                        { id: "WITHDRAW", name: "WITHDRAW" },

                    ]}
                />

                <SelectInput
                    source="task.transferType"
                    variant="outlined"
                    choices={[
                        { id: "INTERNAL", name: "INTERNAL" },
                        { id: "EXTERNAL", name: "EXTERNAL" },

                    ]}
                />


                <TextInput source="task.transferCurrency" label="Transfer Coin Id" />
                <NumberInput source="task.amount" label="Transfer amount" />

            </>
        default:
            return null;
    }
}

const TaskConfigEdit = (props) => {

    const [taskType, setTaskType] = React.useState(null);
    const dataProvider = useDataProvider();
    React.useEffect(() => {
        dataProvider
            .getOne(`v1/tier/tasks`, { id: props.id })
            .then(({ data }) => {
                setTaskType(data.taskType);
            })
            .catch(() => {

            });
    }, [])

    return <Edit {...props}>
        <SimpleForm>
            <TextInput source="taskName" />
            <SelectInput
                source="taskType"
                variant="outlined"
                choices={[
                    { id: "TRADE_TASK", name: "TRADE_TASK" },
                    { id: "USER_TASK", name: "USER_TASK" },
                    { id: "VAULT_TASK", name: "VAULT_TASK" },
                    { id: "TRANSFER_TASK", name: "TRANSFER_TASK" },
                    { id: "SOCIAL_TASK", name: "SOCIAL_TASK" },
                ]}
                onChange={(e) => setTaskType(e.target.value)}
            />

            <RichTextInput source="task.taskDetails" label="Task Details" />
            <RichTextInput source="task.taskTerms" label="Task Terms" />

            <CustomTask type={taskType} />
            <NumberInput source="diamondReward" />
            <NumberInput source="zeltaReward" />

        </SimpleForm>
    </Edit>

}


export default TaskConfigEdit;
