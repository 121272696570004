import ExternalOffsetEdit from "./edit";
import { ExternalOffsetList } from "./list";


const resource = {
    list: ExternalOffsetList,
    edit: ExternalOffsetEdit
};

export default resource;
