import FeeConfigCreate from "./feeConfigCreate";
import FeeConfigEdit from "./feeConfigEdit";
import FeeConfigList from "./feeConfigList";
import FeeConfigShow from "./feeConfigShow";

const resource = {
  list: FeeConfigList,
  edit: FeeConfigEdit,
  show: FeeConfigShow,
  create: FeeConfigCreate,
};

export default resource;
