/* eslint-disable no-unused-vars */
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@mui/material/Pagination";
import * as React from "react";
import { useDataProvider } from "react-admin";
import { AuditColorChip } from "../../../../helpers/autiColorChip";
import { localeDate } from "../../../../helpers/localeDate";

export const AuditTrail = (props) => {
    const [currencies, setCurrencies] = React.useState({});
    const [auditTrail, setAuditTrail] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [searchType, setSearchType] = React.useState("");
    const [searchAction, setSearchAction] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [isScrollLoad, setIsScrollLoad] = React.useState(false);
    const dataProvider = useDataProvider();
    const rowArray = [10, 25, 30];
    const getAuditTrail = (search) => { };

    React.useEffect(() => {
        dataProvider
            .getList("v1/admin/orderbook/currency", {
                filter: { userId: props.id },
                pagination: { page: 1, perPage: 500 },
                sort: { field: "currencyShortName", order: "DESC" },
            })
            .then(({ data }) => {
                data.forEach((element) => {
                    currencies[element.currencyId] = element.currencyShortName;
                    setCurrencies({ ...currencies });
                });
                console.log(currencies);
            })
            .catch(() => { });
    }, []);

    function fetchAudit(search, searchType, searchAction, page, rowsPerPage) {
        dataProvider
            .getList("v1/admin/auditTrail/" + props.id, {
                filter: {
                    userId: props.id,
                    currencyId: search,
                    adjustmentType: searchType,
                    action: searchAction,
                },
                pagination: { page: page, perPage: rowsPerPage },
                sort: { field: "timestamp", order: "DESC" },
            })
            .then((res) => {
                setAuditTrail(res);

            })
            .catch(() => {
            });
    }
    React.useEffect(() => {
        setPage(0);
    }, [search, searchAction, searchType]);
    React.useEffect(() => {
        fetchAudit(search, searchType, searchAction, page, rowsPerPage)
    }, [search, searchAction, searchType, page, rowsPerPage])

    return (
        <>
            <TableContainer className="overflow-hidden" component={Paper}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                        padding: "6px",
                    }}
                >
                    <input
                        style={{ minWidth: "150px", height: "30px" }}
                        type="text"
                        placeholder="Search by coin"
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                    />
                    Type :{" "}
                    <select
                        name="Search by type"
                        id="type"
                        onChange={(e) => {
                            setSearchType(e.target.value);
                        }}
                        style={{ minWidth: "150px", height: "35px" }}
                        value={searchType}
                    >
                        <option value="">All</option>
                        <option value="CREDIT">CREDIT</option>
                        <option value="DEBIT">DEBIT</option>
                        <option value="FREE">FREE</option>
                        <option value="LOCK">LOCK</option>
                        <option value="REVERT">REVERT</option>
                        <option value="LOCK_VAULT">LOCK_VAULT</option>
                        <option value="UNLOCK_VAULT">UNLOCK_VAULT</option>
                    </select>
                    Action :{" "}
                    <select
                        name="Search by type"
                        id="type"
                        onChange={(e) => {
                            setSearchAction(e.target.value);
                        }}
                        style={{ minWidth: "150px", height: "35px" }}
                        value={searchAction}
                    >
                        <option value="">All</option>

                        <option value="BUY">Buy</option>
                        <option value="SELL">Sell</option>
                        <option value="CANCEL">Cancel</option>
                        <option value="DEPOSIT">All Deposits</option>
                        <option value="DEPOSIT_INTERNAL">Internal deposit</option>
                        <option value="DEPOSIT_EXTERNAL">External Deposit</option>
                        <option value="DEPOSIT">All Withdraw</option>
                        <option value="WITHDRAW_INTERNAL">Internal Withdraw</option>
                        <option value="WITHDRAW_EXTERNAL">External Withdraw</option>

                        <option value="PLACE_ORDER">Place order</option>
                        <option value="PLACE_WITHDRAW">Place withdraw</option>
                        <option value="CANCEL_WITHDRAW">Cancel withdraw</option>
                        <option value="OVERLOCKED">OVERLOCKED</option>

                        <option value="COMMISSION">Commission</option>
                        <option value="FEE">Fee</option>
                        <option value="VAULT">Vault</option>
                        <option value="VAULT_MATURE">VAULT_MATURE</option>
                        <option value="VAULT_LOCK">VAULT_LOCK</option>
                        <option value="VAULT_INT">VAULT_INT</option>
                    </select>
                </div>
                <div className="h-[calc(100vh-190px)] block overflow-auto">
                    <Table width={600} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="sticky top-0 bg-white " width={300}>
                                    {" "}
                                    Id{" "}
                                </TableCell>
                                <TableCell className="sticky top-0 bg-white " width={100}>
                                    {" "}
                                    Currency
                                </TableCell>

                                <TableCell className="sticky top-0 bg-white " width={300}>
                                    {" "}
                                    Type
                                </TableCell>
                                <TableCell className="sticky top-0 bg-white " width={300}>
                                    {" "}
                                    Timestamp
                                </TableCell>
                                <TableCell className="sticky top-0 bg-white " width={300}>
                                    {" "}
                                    Action
                                </TableCell>
                                <TableCell className="sticky top-0 bg-white " width={300}>
                                    {" "}
                                    Amount
                                </TableCell>
                                <TableCell className="sticky top-0 bg-white "> Note</TableCell>
                                <TableCell className="sticky top-0 bg-white " width={300}>
                                    {" "}
                                    Latest Balance
                                </TableCell>
                                <TableCell className="sticky top-0 bg-white " width={300}>
                                    {" "}
                                    Latest Locked
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {auditTrail
                                ? auditTrail.data?.slice().map((row, i, arr) =>
                                    row.amount != 0 ? (
                                        <TableRow
                                            key={row.id}
                                        >
                                            <TableCell> {row.id}</TableCell>
                                            <TableCell> {currencies[row.currencyId]}</TableCell>
                                            <TableCell>
                                                {" "}
                                                <AuditColorChip
                                                    label={row.adjustmentType}
                                                    type={row.adjustmentType}
                                                />
                                            </TableCell>
                                            <TableCell className="min-w-[180px]">
                                                {localeDate(row.timestamp, "fullDate")}
                                            </TableCell>
                                            <TableCell> {row.action}</TableCell>
                                            <TableCell> {row.amount}</TableCell>
                                            <TableCell>
                                                {" "}
                                                <AuditColorChip label={row.note} type={row.note} />
                                            </TableCell>
                                            <TableCell> {row?.balanceSnapshot?.balance}</TableCell>
                                            <TableCell>
                                                {" "}
                                                {row?.balanceSnapshot?.inOrderBalance}
                                            </TableCell>
                                        </TableRow>
                                    ) : null
                                )
                                : "Loading"}
                        </TableBody>
                    </Table>
                </div>
                {isScrollLoad ? <p className="w-full text-center">Loading...</p> : <></>}
            </TableContainer>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                }}
                className="flex-col md:flex-row  md:gap-6"
            >
                <Pagination
                    style={{ paddingTop: "5px", paddingBottom: "5px" }}
                    count={Math.ceil(auditTrail?.total / rowsPerPage)}
                    page={page}
                    onChange={(e, val) => {
                        setPage(val);
                    }}
                />
                <p>Rows per page</p>
                <div
                    style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        gap: 4,
                    }}
                >
                    {rowArray.map((item, i) => {
                        return (
                            <div
                                onClick={() => {
                                    setRowsPerPage(item);

                                    setPage(1);
                                }}
                                style={{
                                    background: item == rowsPerPage ? "rgba(0, 0, 0, 0.08)" : "",
                                    width: "max-content",
                                    paddingRight: "5px",
                                    paddingLeft: "5px",
                                    borderRadius: "3px",
                                }}
                                key={i}
                            >
                                {item}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
