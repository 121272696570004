import { CurrencyCreate } from "./create";
import { CurrencyEdit } from "./edit";
import { CurrencyList } from "./list";
import { CurrencyShow } from "./show";
const resource = {
  create: CurrencyCreate,
  list: CurrencyList,
  show: CurrencyShow,
  edit: CurrencyEdit,
};

export default resource;
