import { EditGuesser } from "react-admin";
import { AggOrderList } from "./list";

import { AggOrderShow } from "./show";

const resource = {
    list: AggOrderList,
    show: AggOrderShow,
    edit: EditGuesser
};

export default resource;
