import { subscribeOnStream, unsubscribeFromStream } from "./streaming";

const lastBarsCache = new Map();

const configurationData = {
    supported_resolutions: [
        "1",
        "5",
        "15",
        "30",
        "1h",
        "4h",
        "1d",
        "7d",
        "30d",
        "1D",
    ],

    symbolConif: {},
};

const DataFeed = {
    onReady: (callback) => {
        console.log("[onReady]: Method call");
        setTimeout(() => callback(configurationData));
    },

    resolveSymbol: async (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback
    ) => {
        console.log(symbolName);

        const symbolItem = {
            symbol: symbolName,
            full_name: symbolName,
            description: symbolName,
            exchange: "Zelta",
            type: "crypto",
        };

        if (!symbolItem) {
            console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
            onResolveErrorCallback("cannot resolve symbol");
            return;
        }

        const symbolInfo = {
            ticker: symbolItem.full_name,
            name: symbolItem.symbol,
            description: symbolItem.description,
            type: symbolItem.type,
            session: "24x7",
            timezone: "GMT",
            exchange: symbolItem.exchange,
            minmov: 1,
            pricescale: 100,
            has_intraday: true,
            has_no_volume: true,
            has_weekly_and_monthly: false,
            supported_resolutions: configurationData.supported_resolutions,
            volume_precision: 2,
            data_status: "streaming",
        };

        console.log("[resolveSymbol]: Symbol resolved", symbolName);

        onSymbolResolvedCallback(symbolInfo);
    },

    getBars: async (
        symbolInfo,
        resolution,
        periodParams,
        onHistoryCallback,
        onErrorCallback
    ) => {
        const { from, to, firstDataRequest } = periodParams;
        console.log(resolution);
        try {
            const response = await fetch(
                `https://ticker.zelta.io/v1/ticker/historic?symbol=${symbolInfo.name
                }&window=${resolution}m&from=${from * 1000}&to=${to * 1000}`
            );

            const data = await response.json();

            if (
                (data.Response && data.Response === "Error") ||
                data.data.length === 0
            ) {
                // "noData" should be set if there is no data in the requested period.
                onHistoryCallback([], {
                    noData: true,
                });
                return;
            }

            let bars = [];

            data.data.forEach((bar) => {
                // if (bar.at / 1000 >= from && bar.at / 1000 < to) {
                bars = [
                    ...bars,
                    {
                        time: Date.parse(bar.time),
                        low: bar.low,
                        high: bar.high,
                        open: bar.open,
                        close: bar.close,
                    },
                ];
                // }
            });

            bars = bars.sort((a, b) => a.time - b.time);

            if (firstDataRequest) {
                lastBarsCache.set(symbolInfo.full_name, {
                    ...bars[bars.length - 1],
                });
            }

            console.log(`[getBars]: returned ${bars.length} bar(s)`);
            onHistoryCallback(bars, {
                noData: false,
            });
        } catch (error) {
            console.log("[getBars]: Get error", error);
            onErrorCallback(error);
        }
    },

    subscribeBars: (
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        onResetCacheNeededCallback
    ) => {
        console.log(
            "[subscribeBars]: Method call with subscribeUID:",
            subscribeUID
        );
        subscribeOnStream(
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscribeUID,
            onResetCacheNeededCallback,
            lastBarsCache.get(symbolInfo.full_name)
        );
    },

    unsubscribeBars: (subscriberUID) => {
        console.log(
            "[unsubscribeBars]: Method call with subscriberUID:",
            subscriberUID
        );
        unsubscribeFromStream(subscriberUID);
    },
};

export default DataFeed;
