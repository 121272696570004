import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    Datagrid, DateField, ExportButton,
    FilterLiveSearch, List, NumberField, TextField
} from "react-admin";
import { ColoredChipField } from "../../../helpers/colorChip";
import useResizer from "../../../utils/useResizer";
import {
    ActionFilter,
    AggMarketFilter,
    StatusFilter,
} from "../../filters/transactionFilter";
const FilterSidebar = (props) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiFormLabel-root": {
                fontSize: "12px",
            },
            "& .MuiToolbar-root": {
                width: "100%",
            },
            "& .MuiPaper-root": {
                width: "350px",
                overflow: "auto",
            },
        },
    }));
    const classes = useStyles();
    const isSmall = useMediaQuery("(max-width:768px)");
    return (
        <div>
            <div className=" min-w-full mt-2 md:mt-0  md:min-w-max block">
                <div className="flex m md:w-max md:block items-center flex-wrap">
                    <FilterLiveSearch className={`w-[45%] md:w-auto ${isSmall && classes.root}`} source="orderId" label="Search by ID" />{" "}
                    &nbsp;&nbsp;&nbsp;
                    <FilterLiveSearch className={`w-[45%] md:w-auto ${isSmall && classes.root}`} source="userEmail" label="Search by User Email" />{" "}
                    <FilterLiveSearch className={`w-[45%] md:w-auto ${isSmall && classes.root}`} source="symbol" label="Search by market" /> <br />
                </div>
                <StatusFilter source="status" />
                <div className="flex flex-col w-full md:flex-row md:items-center gap-x-20">
                    <AggMarketFilter source="type" />
                    <ActionFilter source="action" />
                    <Button
                        className="w-max"
                        id="clearButton"
                        variant="outlined"
                        onClick={() => props.setFilters({})}
                    >
                        Clear fields
                    </Button>
                </div>
            </div>
        </div>
    )
}

export const AggOrderList = props => {
    const { width, height } = useResizer();
    const useStyles = makeStyles((theme) => {
        return {
            root: {
                "& .MuiFormLabel-root": {
                    fontSize: "12px",
                },
                "& .MuiToolbar-root": {
                    // flexDirection: "column-reverse",
                    width: "100%",
                },
                "& .MuiPaper-root": {
                    width: "350px",
                    overflow: "auto",
                    height: `${height - 320}px`,
                    [theme.breakpoints.down("768")]: {
                        height: "auto",
                    },
                    position: "static",
                },
                "& .MuiTablePagination-root": {
                    position: "absolute",
                    zIndex: 10,
                    bottom: 0,
                    [theme.breakpoints.down("768")]: {
                        bottom: "-50px",
                    },
                    right: 0,
                    left: 0,
                    display: "flex",
                },
            },
            head: {
                "& .MuiTableCell-head": {
                    fontWeight: 700,
                },
            },
        };
    });
    const classes = useStyles();
    return (<List {...props} filters={<FilterSidebar />}
        actions={<ExportButton className="hidden md:block" maxResults={25000} />}
        bulkActionButtons={false} sort={{ field: "updatedAt", order: "DESC" }}>
        <Datagrid rowClick="show" >
            <TextField source="id" />
            <TextField source="externalOrderId" />
            <TextField source="userId" />
            <TextField source="userEmail" />
            <TextField source="symbol" />
            <ColoredChipField source="exchange" />
            <ColoredChipField source="type" />
            <ColoredChipField source="action" />
            <NumberField source="origPrice" />
            <NumberField source="avgPrice" />
            <NumberField source="originalQty" />
            <NumberField source="executedQty" />

            <TextField source="fee" />

            <ColoredChipField source="status" />


            <TextField source="averageUsdValue" />
            <DateField source="createdAt" showTime />
            <DateField source="updatedAt" showTime />

        </Datagrid>
    </List>)
}


