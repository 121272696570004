import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import * as React from "react";
import { useState } from "react";
import { useDataProvider, useRefresh } from "react-admin";
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const ProcessPopup = (props) => {
    const dataProvider = useDataProvider();
    const [record, setRecord] = useState({ id: props.id });
    const refresh = useRefresh();

    const submit = (record, status) => {
        dataProvider
            .update("v1/admin/funds/withdraw", { id: props.status, data: record })
            .then(({ data }) => {
                refresh();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const row = (key, value) => {
        if (key == "updatedAt" || key == "createdAt") {
            value = new Date(value).toISOString();
        }
        if (key == "address") {
            return (
                <>
                    <TextField
                        className="w-full md:w-[600px]"
                        disabled
                        label={key}
                        value={value}
                        style={{ marginLeft: "10px", marginTop: "20px", width: "600px" }}
                    />
                </>
            );
        }
        return (
            <>
                <TextField
                    disabled
                    label={key}
                    value={value}
                    className="w-full md:w-auto"
                    style={{ marginLeft: "10px", marginTop: "20px" }}
                />
            </>
        );
    };

    return (
        <Box
            className="w-full h-[500px] md:h-auto overflow-auto md:w-auto"
            sx={style}
        >
            <h2 style={{ textAlign: "center" }}>
                {props.status} - {props.id}
            </h2>{" "}
            <br />
            {Object.entries(props?.record).map(([key, value], i) =>
                ["txnid", "note", "user", "transferid", "gateway", "rawdata"].includes(
                    key.toLocaleLowerCase()
                )
                    ? null
                    : row(key, value)
            )}
            <hr />
            <TextField
                variant="outlined"
                label="Txn Id"
                onChange={(e) => {
                    setRecord({ ...record, txnId: e.target.value });
                }}
            />
            &nbsp;&nbsp;
            <TextField
                variant="outlined"
                label="User Note"
                onChange={(e) => {
                    setRecord({ ...record, note: e.target.value });
                }}
            />
            <TextField
                variant="outlined"
                label="Internal Note"
                onChange={(e) => {
                    setRecord({ ...record, internalNote: e.target.value });
                }}
            />
            <br />
            <br />
            <Button
                style={{
                    backgroundColor: "#25745d",
                    color: "white",
                    marginRight: "10px",
                }}
                variant="contained"
                onClick={() => {
                    submit(record, props.status);
                }}
            >
                {props.status}
            </Button>
        </Box>
    );
};

export default ProcessPopup;
