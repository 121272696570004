import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import * as React from "react";
import ProcessPopup from "./ProcessPopup";
const  ProcessButtons = (props) => {
    const [open, setOpen] = React.useState(false);
    const [status, setStatus] = React.useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return <>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ProcessPopup id={props.id} status={status} record={props.record}/>
        </Modal>

        <Button
            style={{
                backgroundColor: "#25745d",
                color: "white",
                marginRight: "10px",
            }}
            variant="contained"
            onClick={() => {
                handleOpen()
                setStatus("APPROVE")
            }}
        >
            Approve
        </Button>
        <Button
            style={{
                backgroundColor: "#b20000",
                color: "white",
                marginRight: "10px",
            }}
            variant="contained"
            onClick={() => {
                handleOpen()
                setStatus("REJECT")
            }}
        >
            Reject
        </Button>
    </>
}


export default ProcessButtons;