/* eslint-disable no-unused-vars */
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";
import roundOff from "../../../../utils/roundOff";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}));

const getDiscrepancy = (key, row, props) => {
    const total =
        row.tde +
        row.tdi +
        row.tb +
        row.ti +
        row.tc -
        row.twe -
        row.twi -
        row.ts -
        row.tf;
    if (props.balances[key]) {
        return Math.round(
            props.balances[key]?.balance +
            props.balances[key]?.inOrderBalance +
            props.balances[key]?.lockedBalance -
            total,
            8
        );
    } else {
        return total;
    }
};

function getTable(index, key, props, row) {
    return row.tde +
        row.tdi +
        row.tb +
        row.ti +
        row.tc +
        row.twe +
        row.twi +
        row.ts +
        row.tf !=
        0 ? (
        <TableRow key={index}>
            <TableCell> {key}</TableCell>
            <TableCell style={{ color: "green" }}>
                {" "}
                {roundOff(
                    props.balances[key]
                        ? props.balances[key]?.balance + props.balances[key]?.inOrderBalance + row.tvl
                        : 0
                )}
            </TableCell>
            <TableCell style={{ color: "green" }}>
                {" "}
                {props.balances[key]?.balance ? props.balances[key]?.balance : 0}
            </TableCell>
            <TableCell style={{ color: "red" }}>
                {" "}
                {props.balances[key]?.inOrderBalance
                    ? props.balances[key]?.inOrderBalance
                    : 0}
            </TableCell>
            <TableCell style={{ color: "green" }}> {row.tde}</TableCell>
            <TableCell style={{ color: "green" }}> {row.tdi}</TableCell>
            <TableCell style={{ color: "red" }}> {row.twe}</TableCell>
            <TableCell style={{ color: "red" }}> {row.twi}</TableCell>
            <TableCell style={{ color: "green" }}> {row.tb}</TableCell>
            <TableCell style={{ color: "red" }}> {row.ts}</TableCell>
            <TableCell style={{ color: "green" }}> {row.tvl}</TableCell>
            <TableCell style={{ color: "red" }}> {row.ti}</TableCell>
            <TableCell style={{ color: "red" }}> {row.tc}</TableCell>
            <TableCell style={{ color: "red" }}> {row.tf}</TableCell>
            <TableCell> {getDiscrepancy(key, row, props)}</TableCell>
        </TableRow>
    ) : null;
}

const TableHeaders = (props) => {
    return (
        <TableRow>
            <TableCell> Currency</TableCell>
            <TableCell> Total Balance</TableCell>
            <TableCell> Free Balance</TableCell>
            <TableCell>In order Balance</TableCell>
            <TableCell> Total Deposit External</TableCell>
            <TableCell> Total Deposit Internal</TableCell>
            <TableCell> Total Withdraw External</TableCell>
            <TableCell> Total Withdraw Internal</TableCell>
            <TableCell> Total Buy</TableCell>
            <TableCell> Total Sell</TableCell>
            <TableCell> Total Vault Locked</TableCell>
            <TableCell> Total Vault Interest</TableCell>
            <TableCell> Total Commission</TableCell>
            <TableCell> Total Fee</TableCell>
            <TableCell> Total Discrepancy</TableCell>
        </TableRow>
    );
};

const DataRows = (props) => {
    var search = props.search;
    return search
        ? props.audit
            .filter((x) => x[0]?.includes(search.toUpperCase()))
            .map(([key, row], index) => getTable(index, key, props, row))
        : props.audit.map(([key, row], index) => getTable(index, key, props, row));
};

export const AuditPro = (props) => {
    const { auditDetails, balanceDetails } = props;
    const [search, setSearch] = React.useState();
    const [auditBalancesState, setAuditBalancesState] = React.useState({});
    React.useEffect(() => {
        console.log(balanceDetails);
    }, [balanceDetails]);
    const handleChange = (e) => {
        setSearch(e.target.value);
    };

    React.useEffect(() => {
        if (balanceDetails?.data) {
            var map = {};
            balanceDetails?.data?.forEach((e) => {
                map[e.currencyShortName] = e;
            });
            setAuditBalancesState(map);
            console.log(map);
        }
    }, [balanceDetails]);

    return (
        <TableContainer component={Paper}>
            <input type="text" placeholder="search" onChange={handleChange} />
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableHeaders />
                </TableHead>
                <TableBody>
                    {auditDetails ? (
                        <DataRows
                            audit={auditDetails}
                            balances={auditBalancesState}
                            search={search}
                        />
                    ) : null}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
