/* eslint-disable no-unused-vars */
import * as React from "react";
import { BooleanInput, Create, NumberInput, SelectInput, SimpleForm, TextInput } from "react-admin";

const RatesConfigCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <TextInput source="coinId" />
            <TextInput source="rate" />
            <SelectInput
                source="tenure"
                variant="outlined"
                choices={[
                    { id: "FLEXIBLE", name: "FLEXIBLE" },
                    { id: "ONE_WEEK", name: "ONE_WEEK" },
                    { id: "ONE_MONTH", name: "ONE_MONTH" },
                    { id: "THREE_MONTHS", name: "THREE_MONTHS" },
                    { id: "SIX_MONTHS", name: "SIX_MONTHS" },
                    { id: "ONE_YEAR", name: "ONE_YEAR" }
                ]}
            />
            <NumberInput source="tenureInDays" />
            <BooleanInput source="isVip" />
            <NumberInput source="ratePerDay" disabled />
            <TextInput source="rateType" />
        </SimpleForm>
    </Create>
);

export default RatesConfigCreate;
