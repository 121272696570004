import TierConfigCreate from "./create";
import TierConfigEdit from "./edit";
import TierConfigList from "./list";
import TierConfigShow from "./show";

const resource = {
    list: TierConfigList,
    edit: TierConfigEdit,
    show: TierConfigShow,
    create: TierConfigCreate,
};

export default resource;
