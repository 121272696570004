/* eslint-disable no-unused-vars */
import * as React from "react";
import {useState} from "react";
import {
    Datagrid,
    Filter,
    List,
    NumberField,
    Pagination,
    SearchInput,
    TextField,
    BooleanField,
    useDataProvider,
    useRefresh,
    DateField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import useResizer from "../../../utils/useResizer"

const StreakFilter = (props) => (
    <Filter {...props}>
        <SearchInput source="searchId" alwaysOn/>
    </Filter>
);
const PostPagination = (props) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const StreakLeaderboard = (props) => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const refresh = useRefresh();
    const {width,height}=useResizer()
    const useStyles = makeStyles((theme) => {
      return (
      {
      root: {
        "& .MuiFormLabel-root": {
          fontSize: "12px",
        },
        "& .MuiToolbar-root": {
          // flexDirection: "column-reverse",
          width: "100%",
        },
        "& .MuiPaper-root": {
          width: "350px",
          overflow: "auto",
          height:`${height-165}px`,
          [theme.breakpoints.down('768')]:{
            height:'auto'
          },
          position:'static'
        },
        "& .MuiTablePagination-root":{
          position:"absolute",
          zIndex:10,
          bottom:0,
          [theme.breakpoints.down('768')]:{
           bottom:'-50px'
          },
          right:0,
          left:0,
          display:'flex'
        }
      },
      head:{
        "& .MuiTableCell-head":{
          fontWeight:700
        }
      }
    })});
    const classes = useStyles();
    return (
        <div>
            <List
                className={`${classes.root} ${classes.head}`}
                bulkActionButtons={false}
                {...props}
                sort={{ field: "streak", order: "DESC" }}
                pagination={<PostPagination/>}
            >
                <Datagrid rowClick={null}>
                    <TextField source="id"/>
                    <TextField source="userEmail"/>
                    <TextField source="streak"/>
                    <DateField source="lastCheckIn" />
                </Datagrid>
            </List>
        </div>
    );
};

export default StreakLeaderboard;
