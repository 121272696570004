import {  AffiliateCreate } from "./create";
import { AffiliateEdit } from "./edit";
import {  AffiliateList, NotificationList } from "./list";
import { AffiliateShow } from "./show";
const resource = {
  create: AffiliateCreate,
  list: AffiliateList,
  edit: AffiliateEdit,
  show:AffiliateShow
};

export default resource;
