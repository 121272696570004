/* eslint-disable no-unused-vars */
import {
  Button,
  Card,
  CardContent,
  Container,
  makeStyles,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import React, { useState } from "react";
import { useLogin, useNotify } from "react-admin";
import authProvider from "../providers/authProvider";

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    margin: "15% auto",
    maxWidth: 320,
    padding: "5%",
    backgroundColor: "#0047AB",
    color: "#FFF",
  },
});

const MyLoginPage = ({ props }) => {
  const classes = useStyles();
  const [countryCode, setCountryCode] = useState("+91");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [verificationCode, setVerificationCode] = useState("");
  const [success, setSuccess] = useState("");

  const [showOtpBox, setShowOtpBox] = useState(false);
  const [error, setError] = useState("");

  const login = useLogin();

  const notify = useNotify();

  const getOtp = (e) => {
    authProvider
      .loginOtp({ login: username, password })
      .then((e) => {
        setShowOtpBox(true);
      })
      .catch((error) => setError("Invalid Email/Password"));
  };

  const submit = (e) => {
    e.preventDefault();
    // call the login callback returned by the useLogin hook
    login({ login: username, password, verificationCode }).catch((error) =>
      setError("Invalid Email/Password/Verification Code.")
    );
  };

  return (
    <Container>
      <Card className={classes.root}>
        <CardContent>
          <img src="/optimus.png" width={100} alt="Optimus logo" />
          <h3>Optimus demands credentials</h3>
          <form onSubmit={submit} validate autoComplete="off">
            <FormControl variant="filled" required="true">
              <InputLabel style={{ color: "#FFF" }} htmlFor="component-simple">
                Email
              </InputLabel>
              <Input
                id="username"
                type="text"
                style={{ color: "#FFF" }}
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormControl>
            <br />
            <br />
            <FormControl variant="filled" color="success">
              <InputLabel style={{ color: "#FFF" }} htmlFor="component-simple">
                Password
              </InputLabel>
              <Input
                id="password"
                type="password"
                style={{ color: "#FFF" }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <br />
            <br />

            <Button
              style={{ color: "#FFF" }}
              variant="outlined"
              onClick={getOtp}
            >
              Get OTP
            </Button>
            <p style={{ color: "green" }}>{success}</p>

            <br />
            {showOtpBox ? (
              <>
                <small>
                  Verification code (Enter verification code directly if 2fA
                  enabled).
                </small>
                <br />
                <br />
                <FormControl>
                  <InputLabel
                    style={{ color: "#FFF" }}
                    htmlFor="component-simple"
                  >
                    Verification Code
                  </InputLabel>
                  <Input
                    id="verificationCode"
                    style={{ color: "#FFF" }}
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
                </FormControl>
                <br />
                <br />
                <p style={{ color: "#FF0000" }}>{error}</p>
                <br />
                <br />

                <Button
                  variant="outlined"
                  style={{ color: "#FFF" }}
                  onClick={submit}
                >
                  Login
                </Button>
              </>
            ) : null}
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default MyLoginPage;
