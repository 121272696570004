/* eslint-disable no-unused-vars */
import { Box } from "@material-ui/core";
import * as React from "react";

import { Button } from "@material-ui/core";
import {

    BooleanField,
    CreateButton,
    Datagrid, DateField, DeleteButton, EditButton,
    ExportButton, FilterLiveSearch, List, Pagination, ReferenceField, ShowButton,
    TextField,
    TopToolbar
} from "react-admin";
import { ColoredChipField } from "../../../helpers/colorChip";
import { StatusFilter, TypeFilter } from "../../filters/transferFilter";

export const AffiliateList = (props) => (
    <List {...props} perPage={100} pagination={<Pagination rowsPerPageOptions={[100, 150, 200, 250]} />}  sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="affiliateCode" />
            <ReferenceField source="userId" reference="users" link="show"><TextField source="email" /></ReferenceField>
            <TextField source="referralCommissionPercent" />
            <TextField source="vaultReferralCommissionPercent" />
            <DeleteButton />
        </Datagrid>
    </List>
);
