/* eslint-disable no-unused-vars */
import * as React from "react";
import { Create, NumberInput, SimpleForm, TextInput } from "react-admin";

export const WithdrawCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="currencyShortName" />
            <NumberInput source="toSend" />
            <NumberInput source="fee" />
            <NumberInput source="totalAmount" />
            <TextInput source="userId" />
            <TextInput source="address" />
            <TextInput source="txnId" />
            <TextInput source="memo" />
            <TextInput source="note" />
            <TextInput source="internalNote" />
        </SimpleForm>
    </Create>
);