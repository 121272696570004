/* eslint-disable no-unused-vars */
import * as React from "react";
import { BooleanField, NumberField, Show, SimpleShowLayout, TextField } from "react-admin";

const RatesConfigShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="coinId" />
            <TextField source="rate" />
            <TextField source="tenure" />
            <NumberField source="tenureInDays" />
            <NumberField source="ratePerDay" />
            <BooleanField source="isVip" />
            <TextField source="rateType" />
        </SimpleShowLayout>
    </Show>
);

export default RatesConfigShow;
